<template>
  <div>
    <v-card class="mb-5">
      <v-card-title class="grey lighten-5 black--text">
        <span class="text-h3 pa-2 font-weight-bold">Payment Summary</span>
      </v-card-title>
      <v-row no-gutters>
        <v-col cols="12" sm="4" align="start">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text--disabled text-overline text-h6 mt-0 mb-0">
                Estimated amount
              </div>
              <v-list-item-title
                class="green--text text-h3 mt-0 font-weight-bold"
              >
                ฿ {{ numberWithCommas(estimatedPay + estimatedOther) }}
              </v-list-item-title>
              <v-divider class="mt-1 mb-1"></v-divider>
              <div v-if="itemSlip.length != 0">
                <v-list-item-title class="mt-1 mb-1">
                  <p class="mt-0 mb-1 text--disabled">Tentative pay day</p>
                  <p class="mt-2 mb-2 font-weight-bold">
                    {{ moment(itemSlip[0].payday).format("LL") }}
                  </p>
                </v-list-item-title>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="8" align="end">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="mb-1 font-weight-bold">
                Status:
                <v-chip
                  v-if="paymentStatus !== ''"
                  class="ml-3"
                  dense
                  label
                  :dark="paymentStatus === 'paid'"
                  :color="
                    paymentStatus === 'pending' ||
                    paymentStatus === 'processing'
                      ? 'yellow lighten-2'
                      : paymentStatus === 'paid'
                      ? 'teal darken-1'
                      : 'deep-orange darken-2'
                  "
                >
                  <span class="text-capitalize text-h4 font-weight-bold">
                    {{ paymentStatus }}
                  </span>
                </v-chip>
                <span v-else class="text-capitalize text-h4 font-weight-bold"
                  >N / A</span
                >
              </v-list-item-title>
              <v-list-item-subtitle
                >List of teaching timeslots to be paid
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- print statement -->
          <!-- 
            <v-btn
              color="indigo"
              class="font-weight-bold mt-1 mr-5 mb-1"
              elevation="3"
              dark
            >
              <v-icon class="mr-2"> mdi-printer-check </v-icon> Statement (.pdf)
            </v-btn> 
          -->
        </v-col>
      </v-row>
      <v-card-title class="cyan darken-4">
        <span class="text-h3 pa-1 font-weight-bold white--text"
          >Taught timeslots</span
        >
      </v-card-title>
      <!-- table transaction -->
      <v-data-table
        id="Table-1"
        :headers="headers"
        :items="itemSlip"
        no-data-text="No Taught timeslots."
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.index="{ index }">
          <p class="mt-0 mb-0">{{ index + 1 }}</p>
        </template>
        <template v-slot:item.slot="{ item }">
          <v-list-item class="pa-0" three-line>
            <v-list-item-content>
              <v-list-item-subtitle>
                <small class="font-weight-black">
                  {{
                    `${item.course_detail.course_code} 
                    ${item.course_detail.course_name_en} 
                    ${item.course_detail.course_credit}(${item.course_detail.course_lecture}-${item.course_detail.course_lab}-${item.course_detail.course_other})`
                  }}
                </small>
                <br />
                <small class="font-weight-bold"
                  >sec {{ item.course_detail.section_number }} , Term
                  {{ item.course_detail.term_text }}</small
                >
              </v-list-item-subtitle>
              <v-list-item-title class="font-weight-bold">
                <span class="text-uppercase">
                  {{
                    item.section_detail.day !== null &&
                    item.section_detail.day !== ""
                      ? item.section_detail.day
                      : moment(item.section_detail.date).format("ddd")
                  }}
                </span>
                ,
                {{ moment(item.section_detail.date).format("DD-MM-YYYY") }}
                {{
                  `${item.section_detail.start_time.split(":")[0]}:${
                    item.section_detail.start_time.split(":")[1]
                  }`
                }}
                -
                {{
                  `${item.section_detail.end_time.split(":")[0]}:${
                    item.section_detail.end_time.split(":")[1]
                  }`
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-if="item.user_updated === 'by_auto_taught'">
            <v-chip class="mt-0 mb-1" label text small>
              Auto Taught
              <span class="ml-1 font-weight-bold success--text"
                >in : {{ readRemark("in", item.remark) }}</span
              >
              <span class="ml-1 font-weight-bold error--text"
                >out : {{ readRemark("out", item.remark) }}</span
              >
            </v-chip>
          </div>
        </template>
        <template v-slot:item.schema="{ item }">
          <p class="mt-0 mb-0 text-h5 text-capitalize">{{ item.paid_type }}</p>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            text
            :color="
              item.status === 'requested'
                ? 'yellow lighten-2'
                : item.status === 'confirmed'
                ? 'teal lighten-3'
                : 'deep-orange darken-2'
            "
            ><span class="text-capitalize font-weight-bold text-h5">{{
              item.status
            }}</span></v-chip
          >
        </template>
        <template v-slot:item.Class="{ item }">
          <p class="text-capitalize text-h5 mt-0 mb-0">
            {{ item.section_detail.teaching_type }}
          </p>
          <div
            v-if="
              item.section_detail.teaching_type === 'Midterm' ||
              item.section_detail.teaching_type === 'Final'
            "
            class="font-weight-bold text-capitalize mt-0 mb-0"
          >
            <small
              class="ma-0"
              :class="{
                'success--text': item.section_detail.status_text === 'Taught',
                'red--text': item.section_detail.status_text === 'Cancel',
              }"
              >{{
                item.section_detail.status_text === "Taught"
                  ? "Present"
                  : "Absent"
              }}</small
            >
          </div>
        </template>
        <template v-slot:item.Rate="{ item }">
          <span class="font-weight-bold">
            <!-- {{
              item.section_detail.teaching_type === "Midterm" ||
              item.section_detail.teaching_type === "Final"
                ? item.section_detail.status_text === "Taught"
                  ? numberWithCommas(item.rank_pay)
                  : "-"
                : "-"
            }} -->
            {{ numberWithCommas(item.rank_pay) }}
          </span>
        </template>
        <template v-slot:item.HoursT="{ item }">
          <span class="font-weight-bold">
            {{ item.teaching_cal_min.toFixed(2) }}
          </span>
        </template>
        <template v-slot:item.HoursP="{ item }">
          <span class="font-weight-bold">{{ item.cal_min }}</span>
        </template>
        <template v-slot:item.Amount="{ item }"
          ><span class="font-weight-bold">{{
            numberWithCommas(item.true_cost)
          }}</span></template
        >
        <template slot="body.append">
          <tr class="blue-grey lighten-5">
            <td colspan="7" class="text-right font-weight-bold">Total</td>
            <td class="text-right font-weight-bold">{{ counthours }} Hours</td>
            <td
              width="10%"
              class="text-right green--text font-weight-bold text-h4"
            >
              ฿ {{ numberWithCommas(estimatedPay) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-title class="blue-grey darken-2">
        <span class="text-h3 pa-1 font-weight-bold white--text">Other</span>
      </v-card-title>
      <v-data-table
        id="Table-2"
        :headers="headersAjust"
        :items="itemOtherSlip"
        no-data-text="No Other."
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.id="{ index }">
          <p class="mt-0 mb-0">{{ index + 1 }}</p>
        </template>
        <template v-slot:item.Description="{ item }">
          <small class="text-disable">
            {{
              `${item.course_detail.course_code} 
              ${item.course_detail.course_name_en} 
              ${item.course_detail.course_credit}(${item.course_detail.course_lecture}-${item.course_detail.course_lab}-${item.course_detail.course_other})`
            }}
          </small>
          <p class="mt-0 mb-0 font-weight-bold">{{ item.detail }}</p>
        </template>
        <template v-slot:item.Remark="{ item }">
          <p class="mt-0 mb-0">{{ item.remark }}</p>
        </template>
        <template v-slot:item.Amount="{ item }">
          <p class="mt-0 mb-0 font-weight-bold">
            {{ numberWithCommas(item.true_cost) }}
          </p>
        </template>
        <template slot="body.append">
          <tr class="blue-grey lighten-5">
            <td colspan="3" class="text-right font-weight-bold">Total</td>
            <td class="text-right green--text font-weight-bold text-h4">
              ฿ {{ numberWithCommas(estimatedOther) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    paymentStatus: {
      type: String,
      default: "",
    },
    estimatedPay: {
      type: Number,
      default: 0.0,
    },
    estimatedOther: {
      type: Number,
      default: 0.0,
    },
    counthours: {
      type: Number,
      default: 0.0,
    },
    periods: {
      type: Array,
      default: () => [],
    },
    itemSlip: {
      type: Array,
      default: () => [],
    },
    itemSlipAllStatus: {
      type: Array,
      default: () => [],
    },
    itemOtherSlip: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    headers: [
      {
        text: "No.",
        align: "start",
        value: "index",
        width: "5%",
        class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
      {
        text: "Timeslot",
        value: "slot",
        align: "start",
        width: "35%",
        class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
      {
        text: "Class",
        value: "Class",
        align: "center",
        width: "",
        class:
          "font-weight-bold black--text text-h5 blue-grey lighten-5 text-center",
        sortable: false,
      },
      {
        text: "Scheme",
        align: "left",
        value: "schema",
        width: "center",
        class:
          "font-weight-bold black--text text-h5 blue-grey lighten-5 text-center",
        sortable: false,
      },
      {
        text: "Status",
        align: "center",
        value: "status",
        width: "",
        class:
          "font-weight-bold black--text text-h5 text-center blue-grey lighten-5",
        sortable: false,
      },
      {
        text: "Rate",
        value: "Rate",
        align: "end",
        width: "",
        class:
          "font-weight-bold text-right black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
      {
        text: "Teaching Hours",
        value: "HoursT",
        align: "end",
        width: "",
        class:
          "font-weight-bold text-right black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
      {
        text: "Payable hours",
        value: "HoursP",
        align: "end",
        width: "",
        class:
          "font-weight-bold text-right black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
      {
        text: "Amount",
        value: "Amount",
        align: "end",
        width: "20",
        class:
          "font-weight-bold text-right black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
    ],
    headersAjust: [
      {
        text: "Code",
        value: "id",
        align: "start",
        class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
      {
        text: "Description",
        value: "Description",
        align: "left",
        class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
      {
        text: "Remark",
        value: "Remark",
        align: "left",
        class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
      {
        text: "Amount",
        value: "Amount",
        align: "end",
        class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        sortable: false,
      },
    ],
  }),
  methods: {
    numberWithCommas(x) {
      return x != "" && x !== null
        ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00";
    },
    readRemark(caseRead, dataJson) {
      let inLog = this.moment(JSON.parse(dataJson).in[0].created_at)
        .locale("th")
        .format("LT");
      let outLog = this.moment(JSON.parse(dataJson).out[0].created_at)
        .locale("th")
        .format("LT");
      return caseRead === "in" ? inLog : outLog;
    },
  },
};
</script>