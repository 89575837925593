<template>
  <div>
    <div v-if="loadingProfile">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col
          class="mt-2 mb-0 font-weight-bold text-subtitle-1 text-center"
          cols="12"
        >
          Getting your student :
          <!-- ( {{ this.$route.query.codeid }} ) -->
          ( {{ this.$route.params.codeid }} )
          {{ this.$route.params.stduentName }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="light-blue darken-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <!-- <pre>{{ this.$route.params.viewNext }}</pre> -->
      <!-- {{ $route.params.dataStd.length }} -->
      <div class="mt-2 mb-3">
        <v-btn
          v-if="$route.params.indexData != 0"
          @click="
            prevViewStd(
              $route.params.indexData,
              $route.params.viewPrev,
              $route.params.dataStd
            )
          "
          class="mr-3"
          tile
          small
          color="blue-grey lighten-4"
        >
          <v-icon dense class="mr-2" left> mdi-arrow-left-thin </v-icon
          ><strong>Prev</strong>
        </v-btn>
        <v-btn
          v-if="
            parseInt($route.params.indexData + 1) !=
            $route.params.dataStd.length
          "
          @click="
            nextViewStd(
              $route.params.indexData,
              $route.params.viewNext,
              $route.params.dataStd
            )
          "
          tile
          dark
          small
          color="info"
        >
          <strong>Next</strong
          ><v-icon dark dense class="ml-2" right> mdi-arrow-right-thin </v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col cols="12" sm="12" md="5">
          <v-card class="mb-5 mx-auto">
            <v-card-text v-if="!loadingProfile">
              <div class="mt-1 mb-2">
                <v-icon class="mr-2">mdi-folder-open</v-icon>Student Information
              </div>
              <v-list-item class="pa-0" two-line>
                <div class="mr-3">
                  <!-- <img
                    class=""
                    style="height: 120px"
                    :src="`https://icapp.muic.mahidol.ac.th/source/pic/${this.$route.params.codeid}.jpg`"
                  /> -->
                  <img class="" style="height: 120px" :src="photoPath" />
                </div>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-2">
                    <div class="w-100 d-flex justify-space-between">
                      <p class="text--disabled mt-0 mb-0">Name :</p>
                      <p class="mt-0 mb-0 font-weight-bold">
                        {{ profileData.firstNameEn }}
                        {{ profileData.lastNameEn }}
                      </p>
                    </div>
                  </v-list-item-title>
                  <v-list-item-title class="black--text mt-2">
                    <div class="w-100 d-flex justify-space-between">
                      <p class="text--disabled mt-0 mb-0">GPA :</p>
                      <p class="font-weight-bold mt-0 mb-0">
                        {{
                          profileData.gpa !== null && profileData.gpa !== ""
                            ? parseFloat(profileData.gpa).toFixed(2)
                            : "N/A"
                        }}
                      </p>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle class="black--text mt-2">
                    <div class="w-100 d-flex justify-space-between">
                      <p class="text--disabled mt-0 mb-0">Major :</p>
                      <p class="font-weight-bold mt-0 mb-0">
                        {{ profileData.facultyNameEn }}
                      </p>
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="black--text mt-2">
                    <div class="w-100 d-flex justify-space-between">
                      <p class="text--disabled mt-0 mb-0">Email :</p>
                      <div class="text-right">
                        <p class="font-weight-bold mt-0 mb-1">
                          u{{ this.$route.params.codeid }}@student.mahidol.ac.th
                        </p>
                        <p class="font-weight-bold mt-0 mb-1">
                          {{
                            dataMuicMail !== null && dataMuicMail !== ""
                              ? dataMuicMail
                              : "-"
                          }}
                        </p>
                      </div>
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="black--text mt-1">
                    <div class="w-100 d-flex justify-space-between">
                      <p class="text--disabled mt-0 mb-0">Status :</p>
                      <v-chip
                        class="ma-0"
                        label
                        small
                        :dark="
                          ['graduated', 'dismiss'].includes(profileData.status)
                        "
                        :color="
                          profileData.status === 'graduated'
                            ? 'teal'
                            : profileData.status === 'studying'
                            ? 'primary'
                            : 'red'
                        "
                      >
                        <span class="text-capitalize" style="font-size: 16px">{{
                          profileData.status === "dismiss"
                            ? "Dismissed"
                            : profileData.status
                        }}</span>
                      </v-chip>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="7">
          <!-- <pre>{{ profileData.grades }}</pre> -->
          <!-- {{ panelOpen }} -->
          <v-expansion-panels v-model="panelOpen" focusable accordion>
            <v-expansion-panel
              v-for="(item, i) in profileData.grades"
              :key="'list' + i"
            >
              <v-expansion-panel-header>
                <div>
                  <p class="font-weight-bold mt-0 mb-2">
                    AcademicYear : {{ item.academicYear }} / {{ item.term }}
                  </p>
                  <small class="text--disabled font-weight-bold mt-0 mb-0">
                    Registered : {{ item.courses.length }} Course
                  </small>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  disable-pagination
                  hide-default-footer
                  no-data-text="No information"
                  :headers="headers"
                  :items="sortGradeInList(item.courses)"
                  class="ma-0 mt-3 mb-2"
                >
                  <template #item.code="{ index, item }">
                    <p class="mt-0 mb-0 font-weight-bold">{{ item.code }}</p>
                  </template>
                  <template #item.nameEn="{ index, item }">
                    <p class="mt-0 mb-0 font-weight-bold">{{ item.nameEn }}</p>
                  </template>
                  <template #item.grade="{ index, item }">
                    <p class="mt-0 mb-0 font-weight-bold">{{ item.grade }}</p>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      profileData: {},
      loadingProfile: false,
      headers: [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          text: "Registered Courses",
          align: "start",
          sortable: false,
          value: "nameEn",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          text: "Grade",
          value: "grade",
          align: "center",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
      ],
      gruopData: [],
      dataMuicMail: "",
      panelOpen: 0,
      photoPath: "",
    };
  },
  computed: {},
  mounted() {
    this.getMoreDetail("no");
  },
  methods: {
    sortGradeInList(listDetail) {
      let arrTmp = [];
      arrTmp = listDetail.map((item) => ({
        ...item,
        grade: item.grade,
      }));

      return arrTmp.sort(function (a, b) {
        var nameA = a.grade.toUpperCase();
        var nameB = b.grade.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    async prevViewStd(index, prevdata, listData) {
      let newPrevIndex = parseInt(index - 2);
      this.$router
        .push({
          name: "adviseesMore",
          params: {
            dataStd: listData,
            stduentName: `${prevdata.firstNameEn} ${prevdata.lastNameEn}`,
            codeid: prevdata.code,
            indexData: parseInt(index - 1),
            viewPrev: listData[newPrevIndex],
            viewNext: listData[index],
          },
        })
        .catch((err) => {});
      this.getMoreDetail(prevdata.code);
    },
    async nextViewStd(index, nextdata, listData) {
      let newIndex = parseInt(index + 2);
      this.$router
        .push({
          name: "adviseesMore",
          params: {
            dataStd: listData,
            stduentName: `${nextdata.firstNameEn} ${nextdata.lastNameEn}`,
            codeid: nextdata.code,
            indexData: parseInt(index + 1),
            viewPrev: listData[index],
            viewNext: listData[newIndex],
          },
        })
        .catch((err) => {});
      this.getMoreDetail(nextdata.code);
    },
    async getMoreDetail(pass) {
      let StudentCode = () => {
        return pass !== "no"
          ? pass
          : typeof this.$route.params.codeid === "undefined" ||
            this.$route.params.codeid === ""
          ? "-"
          : this.$route.params.codeid;
      };
      this.loadingProfile = true;
      this.dataMuicMail = "";
      try {
        const resData = await this.$http.get(
          `/uspark/api/${StudentCode()}/grades/`
        );
        if (resData.status === 200) {
          // get image
          const send = new FormData();
          send.append("student", StudentCode());
          try {
            const resPhoto = await this.$http.post(
              "/group_presigned_url/",
              send,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
            if (resPhoto.status === 200) {
              // console.log(resPhoto.data);
              this.photoPath = resPhoto.data[0].img_url;
            } else {
              throw { resPhoto };
            }
          } catch (error) {}
          // get email
          try {
            const sendDataListStd = new FormData();
            sendDataListStd.append("list_std", StudentCode());
            const resMail = await this.$http.post(
              `/keystone/section/std_edu_search/`,
              sendDataListStd,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
            if (resMail.status === 200) {
              this.dataMuicMail = resMail.data[0].edu_mail;
            } else {
              throw { resMail };
            }
          } catch (error) {}
          this.profileData =
            resData.data.code === "200GEN001"
              ? Object.assign({}, resData.data.data)
              : {};
          this.loadingProfile = false;
        } else {
          throw { resData };
        }
      } catch (error) {
        this.loadingProfile = false;
      }
    },
  },
};
</script>