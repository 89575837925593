import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import admin from "./plugins/admin";
import "./plugins/i18n";
import "./plugins/base";
import "./plugins/chartist";
import "./sass/overrides.sass";

// install keycloak
import VueKeycloakJs from "@dsb-norge/vue-keycloak-js";

// https://randomcodetips.com/vue-html-to-paper/
import VueHtmlToPaper from "vue-html-to-paper";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};
Vue.use(VueHtmlToPaper, options)

import XLSX from 'xlsx'
Vue.prototype.XLSX = XLSX

// import VueFriendlyIframe from 'vue-friendly-iframe';
// Vue.use(VueFriendlyIframe);

Vue.config.productionTip = false;

Vue.use(VueKeycloakJs, {
  init: {
    onLoad: "check-sso",
    checkLoginIframe: false,
  },
  config: {
    url: "https://sso.muic.io/auth",
    clientId: "portal-student",
    realm: "IC",
  },
  onReady: () => {
    new Vue({
      router,
      store,
      i18n,
      vuetify,
      admin,
      render: (h) => h(App),
    }).$mount("#app");
  },
})

// new Vue({
//   router,
//   store,
//   i18n,
//   vuetify,
//   admin,
//   render: (h) => h(App),
// }).$mount("#app");
