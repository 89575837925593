<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr v-for="(item, index) in sectiondetail" :key="'sec' + index">
            <td class="font-weight-bold border-right" width="20%">
              {{ item.label }}
            </td>
            <td
              :class="{
                'font-weight-bold':
                  item.label === 'Course Name' || item.label === 'Course Code',
              }"
            >
              <div v-if="item.label === 'Status'">
                <v-chip
                  small
                  :color="
                    item.value === 'Approved'
                      ? 'success'
                      : item.value === 'Waiting'
                      ? 'warning'
                      : 'red'
                  "
                  class="pa-2"
                  label
                  >{{ item.value }}</v-chip
                >
              </div>
              <div v-else-if="item.label === 'Withdrawable'">
                <v-icon
                  :color="item.value ? 'green darken-1' : 'blue-grey lighten-3'"
                >
                  mdi-{{
                    item.value ? "checkbox-marked-outline" : "close-thick"
                  }}
                </v-icon>
              </div>
              <div v-else>{{ item.value }}</div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    sectiondetail: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.border-right {
  border-right: 1px solid #0000001f;
}
</style>