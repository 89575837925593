<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          class="mb-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- <pre>{{ listStudentEmail }}</pre> -->

    <v-data-table
      disable-pagination
      hide-default-footer
      no-data-text="No information"
      loading-text="loading data student"
      :loading="loading"
      :headers="headersMain"
      :items="studentInAdvisees"
      :search="search"
      class="mt-3 mb-5"
    >
      <template #item.no="{ index, item }">
        <p class="mt-0 mb-0 font-weight-bold">{{ index + 1 }}</p>
      </template>
      <template #item.image="{ item }">
        <!-- <img
          class="mt-2 mb-0"
          style="height: 70px"
          :src="
            'https://icapp.muic.mahidol.ac.th/source/pic/' + item.code + '.jpg'
          "
          @error="imageLoadError($event, item.code)"
          :src="'https://ks.muic.io/uploaded/' + item.code + '.jpg'"
        /> -->
        <div v-if="itemListUrlImage.length !== 0">
          <img
            class="mt-2 mb-0"
            style="height: 70px"
            :src="itemListUrlImage[0][item.code]"
            @error="imageLoadError($event, item.code)"
          />
        </div>
      </template>
      <!--  
        <template #item.division="{ index, item }">
          <p class="mt-0 mb-0 font-weight-bold">{{ item.facultyNameEn }}</p>
        </template>
      -->
      <template #item.code="{ index, item }">
        <!-- {{ index }} -->
        <a
          @click="
            getMoreDetail(
              index,
              item.code,
              `${item.firstNameEn} ${item.lastNameEn}`
            )
          "
          ><p class="mt-0 mb-0 font-weight-bold">{{ item.code }}</p></a
        >
      </template>
      <template #item.firstNameEn="{ index, item }">
        <a
          @click="
            getMoreDetail(
              index,
              item.code,
              `${item.firstNameEn} ${item.lastNameEn}`
            )
          "
          ><p class="mt-0 mb-0 font-weight-bold">
            {{ item.firstNameEn }} {{ item.lastNameEn }}
          </p></a
        >
      </template>
      <template #item.emailAc="{ index, item }">
        <p class="mt-0 mb-0 font-weight-bold">
          {{
            !["", null, "null"].includes(item.email)
              ? item.email
              : `u${item.code}@student.mahidol.ac.th`
          }}
        </p>
      </template>
      <template #item.emailEdu="{ index, item }">
        <p
          v-if="listStudentEmail.length != 0"
          class="mt-0 mb-0 font-weight-bold"
        >
          {{
            !["", null, "null"].includes(listStudentEmail[0][item.code].edu)
              ? listStudentEmail[0][item.code].edu
              : ``
          }}
        </p>
      </template>
      <!--  
        <template #item.phoneNumber="{ index, item }">
          <p class="mt-0 mb-0 font-weight-bold">
            {{ item.phoneNumber }}
          </p>
        </template>
      -->
      <template #item.credit="{ index, item }">
        <p class="mt-0 mb-0 font-weight-bold">
          {{ item.credit }}
        </p>
      </template>
      <template #item.gpa="{ index, item }">
        <p class="mt-0 mb-0 font-weight-bold">
          {{
            item.gpa !== null && item.gpa !== "" ? item.gpa.toFixed(2) : "N/A"
          }}
        </p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import defaultImg from "@/assets/default-avatar.png";
export default {
  components: {},
  data() {
    return {
      search: "",
      studentInAdvisees: [],
      loading: false,
      itemListUrlImage: [],
      paramId: [],
      listStudentEmail: [],
    };
  },
  computed: {
    headersMain() {
      return [
        {
          sortable: false,
          text: "",
          value: "no",
          align: "center",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
        },
        {
          sortable: false,
          text: "",
          value: "image",
          align: "center",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
        },
        // {
        //   sortable: false,
        //   text: "Division",
        //   value: "division",
        //   align: "center",
        //   class: "font-weight-bold text-h5 indigo darken-2 white--text",
        // },
        {
          sortable: true,
          text: "Code",
          value: "code",
          align: "center",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
        },
        {
          sortable: true,
          text: "Name",
          value: "firstNameEn",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
        },
        {
          sortable: false,
          text: "Email [ .ac.th ]",
          value: "emailAc",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
        },
        {
          sortable: false,
          text: "Email [ .edu ]",
          value: "emailEdu",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
        },
        // {
        //   sortable: true,
        //   text: "Tel",
        //   value: "phoneNumber",
        //   class: "font-weight-bold text-h5 indigo darken-2 white--text",
        // },
        {
          sortable: true,
          text: "Credit",
          value: "credit",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
        },
        {
          sortable: true,
          text: "GPA",
          value: "gpa",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
        },
      ];
    },
  },
  mounted() {
    this.getStudent();
  },
  methods: {
    imageLoadError(event, id) {
      event.target.src = defaultImg;
    },
    async getStudent() {
      this.loading = true;
      this.studentInAdvisees = [];
      this.paramId = [];
      try {
        const resData = await this.$http.get(`/uspark/api/advisees/`);
        if (resData.status === 200) {
          const send = new FormData();
          const sendA = new FormData();
          for (let l = 0; l < resData.data.data.length; l++) {
            // arr.push(this.itemStudent[0].studentList[l].studentCode);
            this.paramId.push(resData.data.data[l].code);
            send.append("student", resData.data.data[l].code);
          }
          await this.getImageUrl(send);
          sendA.append("list_std", this.paramId);
          await this.getEmail(sendA);

          this.studentInAdvisees = resData.data.data.map((item) => ({
            ...item,
          }));
          this.studentInAdvisees.sort(function (a, b) {
            var nameA = a.code.toUpperCase();
            var nameB = b.code.toUpperCase();
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
          this.loading = false;
        } else {
          throw { resData };
        }
      } catch (error) {
        this.loading = false;
        this.studentInAdvisees = [];
      }
    },
    async getImageUrl(idStd) {
      this.itemListUrlImage = [];
      try {
        const resPhoto = await this.$http.post("/group_presigned_url/", idStd, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (resPhoto.status === 200) {
          var jsonData = {};
          resPhoto.data.forEach(function (column) {
            var columnName = column.student;
            jsonData[columnName] = column.img_url;
          });
          this.itemListUrlImage.push(jsonData);
        } else {
          throw resPhoto;
        }
      } catch (error) {}
    },
    async getEmail(sendDataListStd) {
      this.listStudentEmail = [];
      try {
        const resMail = await this.$http.post(
          `/keystone/section/std_edu_search/`,
          sendDataListStd,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (resMail.status === 200) {
          // console.log(resMail.data);
          var jsonDataMail = {};
          resMail.data.forEach(function (column) {
            var columnName = column.student;
            jsonDataMail[columnName] = {
              edu: column.edu_mail,
              acthM: column.acth_mail,
            };
          });
          this.listStudentEmail.push(jsonDataMail);
        } else {
          throw resMail;
        }
      } catch (error) {}
    },
    getMoreDetail(index, id, nameX) {
      this.$router
        .replace({
          name: "adviseesMore",
          params: {
            dataStd: this.studentInAdvisees,
            stduentName: nameX,
            codeid: id,
            indexData: index,
            viewPrev:
              index - 1 <= 0
                ? this.studentInAdvisees[0]
                : this.studentInAdvisees[index - 1],
            viewNext: this.studentInAdvisees[index + 1],
          },
        })
        .catch((err) => {});
    },
  },
};
</script>