/* eslint-disable no-unused-vars */

export default (i18n, admin, role) => {
  // console.log(role)
  var navTemp = [
    // { heading: i18n.t("Instructor") },
    // i18n.t("menu.dashboard")
    {
      icon: "mdi-view-dashboard",
      text: 'Overview',
      link: "/overview",
    },
    {
      icon: "mdi-book-account",
      text: "My Advisees",
      link: "/advisees",
    },
    {
      icon: "mdi-badge-account",
      text: "My Profile",
      link: "/profile",
    },
    {
      icon: "mdi-currency-usd",
      text: "Payment",
      link: "/payment",
    },
    {
      icon: "mdi-book-check-outline",
      text: "Teaching Evaluation",
      link: "https://ks.muic.io/QuestionnaireByInstructor",
    },
    {
      icon: "mdi-book-edit-outline",
      text: "Grade Input",
      link: "https://ks.muic.io/ScoreByInstructor",
    },
    {
      icon: "mdi-school",
      text: "Open Section",
      link: "https://os.muic.io/",
    },
    {
      icon: "mdi-border-all",
      text: "Teaching record",
      link: "https://newmy.muic.io/teaching",
    },
    {
      icon: "mdi-alert-rhombus-outline",
      text: "IC Service",
      link: "https://newmy.muic.io/service",
    },
    // { heading: i18n.t("Transaction teaching timeslots") },
    // { divider: true },
  ];

  var arr = [];

  for (let set = 0; set < 10; set++) {
    if (set == 4) {
      if (role == true) {
        arr.push({
          icon: "mdi-ballot-outline",
          text: "Section",
          link: "/section",
        }, {
          icon: "mdi-account-search-outline",
          text: "View Student",
          link: "/searchstudent",
        },)
      } else {
        arr.push({
          icon: "mdi-ballot-outline",
          text: "Section",
          link: "/section",
        },)
      }
    } else {
      if (typeof navTemp[set] !== "undefined") {
        arr.push(navTemp[set])
      }
    }
  }
  // console.log(arr);
  return arr;
};
