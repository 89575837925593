<template>
  <div>
    <!-- <pre>{{ dataProfile }}</pre> -->
    <v-card class="mx-auto mb-5" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Profile Overview</div>
          <v-list-item-title class="mb-2 font-weight-bold"
            >ID : {{ dataProfile.code }}</v-list-item-title
          >
          <v-list-item-title class="text-h5 mb-1">
            {{ dataProfile.title !== null ? dataProfile.title.name_eng : "" }}
            {{ dataProfile.name }}
            {{ dataProfile.surname }}
          </v-list-item-title>
          <v-list-item-subtitle>{{
            dataProfile.department !== null
              ? dataProfile.department.name_eng
              : ""
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar tile size="80">
          <img
            :src="
              'https://icapp.muic.mahidol.ac.th/source/pic/' +
              dataProfile.id +
              '.jpg'
            "
            @error="imageLoadError($event, dataProfile.id)"
          />
        </v-list-item-avatar>
      </v-list-item>
      <v-card-text class="mt-5">
        <h4 class="mt-5 mb-0">
          Status :<v-chip class="ml-2" label small outlined>{{
            dataProfile.status != null ? dataProfile.status.name_eng : ""
          }}</v-chip>
        </h4>
        <h4 class="mt-2 mb-0">
          HR Contract :<span class="ml-2">{{
            dataProfile.contract != null ? dataProfile.contract.name_eng : ""
          }}</span>
        </h4>
      </v-card-text>
      <!-- list - a -->
      <v-card-text class="mt-2">
        <div v-for="(itemC, index) in contract" :key="'contract' + index">
          <div v-if="itemC.status === 'active'">
            <h4 class="mt-2 mb-0">
              Contract :<v-chip
                text-color="white"
                color="green"
                class="ml-2"
                label
                small
                >{{ itemC.status }}</v-chip
              >
            </h4>
            <h5 class="mt-2 mb-0">
              Type :<span class="ml-2">{{ itemC.contract_type }}</span>
            </h5>
            <h5 class="mt-2 mb-0">
              Track :<span class="ml-2">{{
                itemC.track_detail !== null ? itemC.track_detail.track_name : ""
              }}</span>
            </h5>
          </div>
        </div>
      </v-card-text>
      <!-- list - b -->
      <v-card-text class="mt-2">
        <div v-for="(itemR, index) in rank" :key="'rank' + index">
          <div v-if="itemR.status === 'active'">
            <h4 class="mt-2 mb-0">
              Academic Rank :<v-chip
                text-color="white"
                color="green"
                class="ml-2"
                label
                small
                >{{ itemR.status }}</v-chip
              >
            </h4>
            <h5 class="mt-2 mb-0">
              Rank name :<span class="ml-2">{{
                itemR.rank_detail !== null ? itemR.rank_detail.rank_name : ""
              }}</span>
            </h5>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="ml-2">
        <small class="mt-0 mb-3"
          >Update at : {{ moment(dataProfile.updated_at).format("LLL") }}</small
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import defaultImg from "@/assets/default-avatar.png";
export default {
  props: {
    dataProfile: {
      type: Object,
      default: () => {},
    },
    contract: {
      type: Array,
      default: () => [],
    },
    rank: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    numberWithCommas(x) {
      return x != "" && x !== null
        ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
    },
    imageLoadError(event, id) {
      event.target.src = defaultImg;
    },
  },
};
</script>

<style scoped>
.f-20 {
  font-size: 20px;
  font-weight: 500;
}
</style>