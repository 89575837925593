<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="3"
        v-for="(item, index) in overviewIcon"
        :key="'Overview' + index"
      >
        <cardmenus :cardData="item" />
        <!-- {{ $store.state.role.RulesUser.RolePdPCChair }} -->
        <!-- {{ item.toppic }} -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import cardmenus from "./dashboard/cardsmenu.vue";
export default {
  components: {
    cardmenus,
  },
  data() {
    return {
      overviewIcon: [
        {
          icon: "mdi-ballot-outline",
          toppic: "Section",
          detail: "Manage your sections and time slots.",
          url: "/section",
          sortA: 1,
        },
        {
          icon: "mdi-currency-usd",
          toppic: "Payment",
          detail: "View and track your extra teaching payment.",
          url: "/payment",
          sortA: 2,
        },
        {
          icon: "mdi-book-account",
          toppic: "My Advisees",
          detail: "Academic results and enrollment information of students",
          url: "/advisees",
          sortA: 3,
        },
        {
          icon: "mdi-book-edit-outline",
          toppic: "Grade Input",
          detail: "Enter students' scores and grades.",
          url: "https://ks.muic.io/ScoreByInstructor",
          sortA: 5,
        },
        {
          icon: "mdi-border-all",
          toppic: "Teaching record",
          detail:
            "(Extra & Part-time) You are only required to upload the log record for courses under extra teaching payment or if you are a part-time instructor",
          url: "https://newmy.muic.io/teaching",
          sortA: 6,
        },
        {
          icon: "mdi-book-check-outline",
          toppic: "Teaching Evaluation",
          detail: "View your teaching evaluation scores",
          url: "https://ks.muic.io/QuestionnaireByInstructor",
          sortA: 7,
        },
        {
          icon: "mdi-alert-rhombus-outline",
          toppic: "IC SERVICE",
          detail: "please click the create ticket button to let us know how we can help you.",
          url: "https://newmy.muic.io/service",
          sortA: 8,
        },
        {
          icon: "mdi-school",
          toppic: "Open Section",
          detail: "List of open sections",
          url: "/",
          sortA: 8,
        },
      ],
    };
  },
  mounted() {
    this.setMenu();
  },
  methods: {
    setMenu() {
      if (this.$store.state.role.RulesUser.RolePdPCChair) {
        this.overviewIcon.push({
          icon: "mdi-account-search-outline",
          toppic: "View Student",
          detail: "Find student information",
          url: "/searchstudent",
          sortA: 4,
        });
      }
      this.overviewIcon.sort((a, b) =>{ return a.sortA - b.sortA; });
    },
  },
};
</script>
