import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";

import "vuetify-admin/src/loader";

import { } from "vuetify-admin/src/providers";
import { en } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import config from "@/config.js";

// Load Admin UI components
Vue.use(VuetifyAdmin);
// Axios
Vue.use(VueAxios, axios);
// Moment
Vue.prototype.moment = moment;

function tokenInterceptor() {
  axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
    return config;
  }, error => {
    return Promise.reject(error);
  })
}

tokenInterceptor();

// Axios instance
axios.defaults.baseURL = config.apiUrl;

// const baseURL = process.env.VUE_APP_API_URL || "http://localhost:3000";
// const http = axios.create({
//   baseURL,
//   headers: { "X-Requested-With": "XMLHttpRequest" },
// });

// Init admin
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "SKY+ Instructor",
  routes,
  locales: {
    en,
  },
  translations: ["en"],
  resources,
  options: {
    dateFormat: "long",
  },
  // http,
});
