<template>
  <div>
    <!-- btn year -->
    <!-- {{ selectYear }} {{ selectMonth }} -->
    <v-row>
      <v-col cols="12" md="2">
        <v-select class="mt-0 mb-0" outlined label="Year" dense small v-model="selectYear"
          :items="itemsYear"></v-select>
      </v-col>
    </v-row>
    <!-- btn month -->
    <div align="start">
      <v-sheet class="mx-auto mb-5">
        <v-slide-group show-arrows v-model="selectMonth">
          <v-slide-item v-for="m in itemsMonth" :key="m.id" v-slot="{ active, toggle }">
            <v-btn class="mx-2" depressed :color="selectMonth == m.id ? 'blue darken-4' : ''"
              :class="selectMonth == m.id ? 'white--text' : ''" @click="selectMonth = m.id">
              {{ m.text }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
    <!-- component bills -->
    <div v-if="!loading">
      <Transaction :paymentStatus="paymentStatus" :estimatedPay="estimated" :estimatedOther="estimatedOther"
        :counthours="counthours" :periods="periods" :itemSlip="itemSlip" :itemSlipAllStatus="itemSlipAllStatus"
        :itemOtherSlip="itemOtherSlip" />
      <!-- <pre>{{ itemSlip }}</pre> -->
    </div>
    <div v-else>
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="mt-2 mb-0 font-weight-bold text-subtitle-1 text-center" cols="12">
          Getting your transaction payment
        </v-col>
        <v-col cols="6">
          <v-progress-linear color="light-blue darken-4" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <!-- error main page -->
    <v-snackbar absolute top color="red darken-4" v-model="snackbarError" v-if="errorMsgCallData !== null">
      <strong>{{ errorMsgCallData }}</strong>
    </v-snackbar>
  </div>
</template>

<script>
import Transaction from "@/views/payment/Transaction";
export default {
  components: {
    Transaction,
  },
  data: () => ({
    itemsYear: [],
    itemsMonth: [
      { id: 1, text: "January" },
      { id: 2, text: "February" },
      { id: 3, text: "March" },
      { id: 4, text: "April" },
      { id: 5, text: "May" },
      { id: 6, text: "June" },
      { id: 7, text: "July" },
      { id: 8, text: "August" },
      { id: 9, text: "September" },
      { id: 10, text: "October" },
      { id: 11, text: "November" },
      { id: 12, text: "December" },
    ],
    selectYear: null,
    selectMonth: null,
    viewComponent: null,
    // api data value
    snackbarError: false,
    loading: false,
    errorMsgCallData: null,
    itemSlip: [],
    itemSlipAllStatus: [],
    itemOtherSlip: [],
    periods: [],
    estimated: 0,
    estimatedOther: 0,
    paymentStatus: "",
    counthours: 0,
  }),
  computed: {
    changeView() {
      const { selectYear, selectMonth } = this;
      return {
        selectYear,
        selectMonth,
      };
    },
  },
  watch: {
    changeView: {
      handler: function (val) {
        // console.log(val)
        this.$router.push({
          path: "/payment",
          query: { month: val.selectMonth, year: val.selectYear },
        }).catch(err => { });
        this.getDetailsetting();
        this.getSlip();
      },
      deep: true,
    },
  },
  mounted() {
    this.$vuetify.goTo(0);
    this.setOptionYear();
  },
  methods: {
    setOptionYear() {
      let year = this.moment().format("YYYY");
      for (let nextI = 1; nextI <= 2; nextI++) {
        let v = parseInt(this.moment().format("YYYY")) + nextI;
        this.itemsYear.push({ value: v, text: v });
      }
      for (let i = year; i > year - 5; i--) {
        this.itemsYear.push({
          value: i,
          text: `${i}`,
        });
      }
      this.itemsYear.sort((a, b) => { return b.value - a.value; });
      // set value
      if (typeof (this.$route.query.month) === "undefined" || this.$route.query.month === "" || typeof (this.$route.query.year) === "undefined" || this.$route.query.year === "") {
        this.selectYear = this.moment().format("YYYY");
        this.selectMonth = parseInt(this.moment().format("M"));
      } else {
        this.selectYear = this.$route.query.year;
        this.selectMonth = this.$route.query.month;
      }
    },
    async getDetailsetting() {
      this.periods = []
      let queryType = "";
      try {
        const resContract = await this.$http.get(
          `/teaching/contract/me/`
        );
        if (resContract.status === 200) {
          queryType = resContract.data.rank_detail.rank_name === "Part Time" ? 'parttime' : 'fulltime'
          try {
            const resSetting = await this.$http.get(
              `/teaching/set-slip/get_payday/?paid_year=${this.selectYear}&paid_month=${this.selectMonth}&teaching_type=${queryType}`
            );
            if (resSetting.status === 200) {
              this.periods = resSetting.data.map((item) => ({ ...item }));
            }
            else {
              throw { resSetting }
            }
          } catch (error) { }
        }
        else {
          throw { resContract }
        }
      } catch (error) { }
    },
    async getSlip() {
      this.loading = true;
      this.snackbarError = false;
      this.itemSlip = [];
      this.itemSlipAllStatus = [];
      this.itemOtherSlip = [];
      this.paymentStatus = "";
      this.estimated = 0;
      this.estimatedOther = 0;
      this.counthours = 0;
      try {
        const resSlip = await this.$http.get(
          `teaching/payment-list/me/?year=${this.selectYear}&month=${this.selectMonth}`
        );
        if (resSlip.status === 200) {
          for (let s = 0; s < resSlip.data.length; s++) {
            this.paymentStatus = resSlip.data[s].payment_status;
            // Taught
            for (
              let ss = 0;
              ss < resSlip.data[s].payment_item_detail.length;
              ss++
            ) {
              this.estimated += resSlip.data[s].payment_item_detail[ss].true_cost;
              this.counthours += resSlip.data[s].payment_item_detail[ss].cal_min;
              this.itemSlip.push(resSlip.data[s].payment_item_detail[ss]);
              this.itemSlipAllStatus.push(resSlip.data[s].payment_item_detail[ss].status)
            }
            // other
            for (
              let other = 0;
              other < resSlip.data[s].payment_item_other_detail.length;
              other++
            ) {
              this.estimatedOther += resSlip.data[s].payment_item_other_detail[other].true_cost;
              this.itemOtherSlip.push(resSlip.data[s].payment_item_other_detail[other]);
            }
          }
          this.loading = false;
        } else {
          throw { resSlip };
        }
      } catch (error) {
        this.snackbarError = true;
        this.loading = false;
        this.itemSlip = [];
        this.itemOtherSlip = [];
        this.errorMsgCallData = `Caught problem api  process. Pleses refresh the page and try again.`;
      }
    },
  },
};
</script>

<style scoped>
.f-16px {
  font-size: 2rem;
}
</style>