<template>
  <div>
    <!-- contract -->
    <!-- <pre>{{ contract }}</pre> -->
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr class="deep-purple darken-3">
            <th width="20%" class="white--text text-left text-h5">
              Contract Type
            </th>
            <th width="20%" class="white--text text-left text-h5">Track</th>
            <th width="15%" class="white--text text-center text-h5">Start</th>
            <th width="15%" class="white--text text-center text-h5">End</th>
            <th width="10%" class="white--text text-center text-h5">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(contractLine, index) in contract"
            :key="'contract' + index"
          >
            <td>
              <p
                :class="{ 'text--disabled': contractLine.status !== 'active' }"
                class="mt-0 mb-0 font-weight-bold text-capitalize"
              >
                {{ contractLine.contract_type }}
              </p>
            </td>
            <td>
              <p
                :class="{ 'text--disabled': contractLine.status !== 'active' }"
                class="mt-0 mb-0 font-weight-bold"
              >
                {{ contractLine.track_detail.track_name }}
              </p>
            </td>
            <td class="text-center">
              <p
                :class="{ 'text--disabled': contractLine.status !== 'active' }"
                class="mt-0 mb-0 font-weight-bold"
              >
                {{ moment(contractLine.hr_start_date).format("DD/MM/YYYY") }}
              </p>
            </td>
            <td class="text-center">
              <p
                v-if="
                  moment(contractLine.hr_end_date)
                    .format('DD/MM/YYYY')
                    .split('/')[2] === '3000' ||
                  moment(contractLine.hr_end_date)
                    .format('DD/MM/YYYY')
                    .split('/')[2] === '9999'
                "
                class="mt-0 mb-0 font-weight-bold"
                :class="{ 'text--disabled': contractLine.status !== 'active' }"
              >
                Present
              </p>
              <p
                v-else
                class="mt-0 mb-0 font-weight-bold"
                :class="{ 'text--disabled': contractLine.status !== 'active' }"
              >
                {{ moment(contractLine.hr_end_date).format("DD/MM/YYYY") }}
              </p>
            </td>
            <td class="text-center">
              <v-chip
                class="text-center ma-2"
                label
                small
                :color="contractLine.status === 'active' ? 'green' : ''"
                :text-color="contractLine.status === 'active' ? 'white' : ''"
              >
                {{ contractLine.status }}
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- rank -->
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr class="deep-purple darken-1">
            <th width="50%" class="white--text text-left text-h5">Academic Rank</th>
            <th width="15%" class="white--text text-center text-h5">Start</th>
            <th width="15%" class="white--text text-center text-h5">End</th>
            <th width="10%" class="white--text text-center text-h5">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rankLine, index) in rank" :key="'rank' + index">
            <td>
              <p
                :class="{ 'text--disabled': rankLine.status !== 'active' }"
                class="mt-2 mb-0 font-weight-bold text-capitalize"
              >
                {{ rankLine.rank_detail.rank_name }}
              </p>
            </td>
            <td class="text-center">
              <p
                :class="{ 'text--disabled': rankLine.status !== 'active' }"
                class="mt-0 mb-0 font-weight-bold"
              >
                {{ moment(rankLine.hr_start_date).format("DD/MM/YYYY") }}
              </p>
            </td>
            <td class="text-center">
              <p
                v-if="
                  moment(rankLine.hr_end_date)
                    .format('DD/MM/YYYY')
                    .split('/')[2] === '3000' ||
                  moment(rankLine.hr_end_date)
                    .format('DD/MM/YYYY')
                    .split('/')[2] === '9999'
                "
                class="mt-0 mb-0 font-weight-bold"
                :class="{ 'text--disabled': rankLine.status !== 'active' }"
              >
                Present
              </p>
              <p
                v-else
                :class="{ 'text--disabled': rankLine.status !== 'active' }"
                class="mt-0 mb-0 font-weight-bold"
              >
                {{ moment(rankLine.hr_end_date).format("DD/MM/YYYY") }}
              </p>
            </td>
            <td class="text-center">
              <v-chip
                class="text-center ma-2"
                label
                small
                :color="rankLine.status === 'active' ? 'green' : ''"
                :text-color="rankLine.status === 'active' ? 'white' : ''"
              >
                {{ rankLine.status }}
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- Auto Taugth -->
    <!--  
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr class="blue-grey lighten-4">
              <th width="80%" class="text-left text-h5 font-weight-bold">
                Attribute
              </th>
              <th width="20%" class="text-left text-h5 font-weight-bold">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="text-h5 mt-2 mb-0 font-weight-bold">Auto Taught</div>
                <div class="text-h6 mt-0 mb-0">
                  <v-chip color="success" small label dark> ON </v-chip>
                  <span class="ml-2"
                    >:: ระบบจะดำเนินการ Taught ให้ท่านอัตโนมัติตามวันเวลาสอน
                    และข้อมูลการเข้าสแกนบัตรเข้าสถานที่</span
                  >
                </div>
                <div class="text-h6 mt-0 mb-2">
                  <v-chip color="red" small label dark> OFF </v-chip>
                  <span class="ml-2"
                    >:: ระบบจะไม่ดำเนินการ Taught ให้ท่านอัตโนมัติ</span
                  >
                </div>
              </td>
              <td>
                <v-switch
                  v-model="setT"
                  value="on"
                  :label="`${setT !== null ? setT : 'off'}`"
                  @change="setAutoTaught"
                ></v-switch>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    -->
  </div>
</template>

<script>
export default {
  props: {
    contract: {
      type: Array,
      default: () => [],
    },
    rank: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      setT: "",
      errorMsgCallData: null,
    };
  },
  mounted() {
    // this.getCheckAutoTaugt();
  },
  methods: {
    numberWithCommas(x) {
      return x != "" && x !== null
        ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
    },
    async getCheckAutoTaugt() {
      this.errorMsgCallData = null;
      this.setT = "";
      try {
        const resAutoTaught = await this.$http.get(
          `/teaching/auto-taught/check/`
        );
        if (resAutoTaught.status === 200) {
          if (resAutoTaught.data.muic_account === "") {
            this.setT = "off";
          } else {
            this.setT = "on";
          }
        } else {
          throw { resAutoTaught };
        }
      } catch (error) {
        this.setT = "off";
        this.errorMsgCallData = `Caught problem api /teaching/auto-taught/check/ process. Pleses refresh the page and try again.`;
      }
    },
    async setAutoTaught(actionSend) {
      let setUrlAutoTaught =
        actionSend === "on"
          ? `/teaching/auto-taught/addlist/`
          : `/teaching/auto-taught/removelist/`;
      try {
        const resSetAutoTaught = await this.$http.get(setUrlAutoTaught);
        if (resSetAutoTaught.status === 200) {
          this.setT = actionSend;
        } else {
          throw { resSetAutoTaught };
        }
      } catch (error) {
        this.errorMsgCallData = `Caught problem api ${setUrlAutoTaught} process. Pleses refresh the page and try again.`;
      }
    },
  },
};
</script>