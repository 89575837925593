var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.sectiondetail),function(item,index){return _c('tr',{key:'sec' + index},[_c('td',{staticClass:"font-weight-bold border-right",attrs:{"width":"20%"}},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('td',{class:{
              'font-weight-bold':
                item.label === 'Course Name' || item.label === 'Course Code',
            }},[(item.label === 'Status')?_c('div',[_c('v-chip',{staticClass:"pa-2",attrs:{"small":"","color":item.value === 'Approved'
                    ? 'success'
                    : item.value === 'Waiting'
                    ? 'warning'
                    : 'red',"label":""}},[_vm._v(_vm._s(item.value))])],1):(item.label === 'Withdrawable')?_c('div',[_c('v-icon',{attrs:{"color":item.value ? 'green darken-1' : 'blue-grey lighten-3'}},[_vm._v(" mdi-"+_vm._s(item.value ? "checkbox-marked-outline" : "close-thick")+" ")])],1):_c('div',[_vm._v(_vm._s(item.value))])])])}),0)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }