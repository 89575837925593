import Vue from "vue";
import Vuex from "vuex";
import role from './role';

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: {
    role: role,
  }
});
