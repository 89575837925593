<template>
  <div>
    <div v-if="loadingTeaching">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col
          class="mt-2 mb-0 font-weight-bold text-subtitle-1 text-center"
          cols="12"
        >
          Getting your teaching log requests
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="light-blue darken-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <!-- logdata -->
      <!-- id : {{ sectionLogId }} -->
      <!-- <pre>{{ listTeachingRequest }}</pre> -->
      <!-- <div v-if="dataTeachingLog.length !== 0">
        <pre>{{ dataTeachingLog[0].sectionNumber }}</pre>
        <pre>{{ dataTeachingLog[0].termText }}</pre>
        <pre>{{ dataTeachingLog[0].coInstructors }}</pre>
        <pre>{{ dataTeachingLog[0].course }}</pre>
      </div> -->
      <!-- logdata -->
      <v-row class="mb-3" justify="space-between">
        <v-col cols="12" sm="12" md="6"> </v-col>
        <v-col cols="12" sm="12" md="6" class="text-right">
          <p class="mt-0 mb-0">
            <small class="text--disabled"
              >You are only required to upload the log record for courses under
              extra teaching payment or if you are a part-time instructor</small
            >
          </p>
          <v-btn
            @click="openAddTeachingrecords"
            color="light-blue darken-4"
            class="ma-1 white--text font-weight-bold"
          >
            Create Teaching Record
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        item-key="teachinglog"
        no-data-text="No Teaching log list"
        class="elevation-1"
        disable-pagination
        hide-default-footer
        :search="search"
        :headers="headers"
        :items="listTeachingRequest"
        dense
      >
        <template #item.course="{ item }"
          ><p class="f-300 mt-0 mb-0"></p
        ></template>
        <template #item.term="{ item }"
          ><p class="f-300 mt-0 mb-0"></p
        ></template>
        <template #item.section="{ item }"
          ><p class="f-300 mt-0 mb-0"></p
        ></template>
        <template #item.period="{ item }"
          ><p class="f-300 mt-0 mb-0"></p
        ></template>
        <template #item.status="{ item }"></template>
        <template #item.file="{ item }"></template>
        <template #item.action="{ item }"></template>
      </v-data-table>
    </div>
    <!-- dialog teaching record  -->
    <v-dialog
      v-model="dialogTeachingRecord"
      :width="550"
      :retain-focus="false"
      persistent
    >
      <v-card>
        <v-card-text
          class="text-left text-h4 mt-0 mb-0 grey lighten-2 font-weight-bold"
        >
          <p class="mt-0 mb-0 font-weight-bold">Teaching records</p>
          <small
            >Select teaching record details and upload the reference
            file.</small
          >
        </v-card-text>
        <v-card-text>
          <v-select
            dense
            label="Teaching period"
            v-model="formUploadTeachingLod.period"
            outlined
          ></v-select>
          <v-file-input
            dense
            counter
            show-size
            outlined
            prepend-icon="mdi-file-pdf-box"
            truncate-length="15"
            accept="application/pdf"
            :rules="rules"
            v-model="formUploadTeachingLod.filemeetting"
          ></v-file-input>
          <p class="mt-0 mb-0">
            <small
              >Teaching record file can be screenshots of meeting logs or
              meeting screens.</small
            >
          </p>
          <p class="mt-0 mb-0">
            <small>File types that can be uploaded are .pdf</small>
          </p>
          <!-- error -->
          <v-alert
            v-if="errorUploadMsg !== null && errorUploadMsg !== ''"
            text
            prominent
            type="error"
            icon="mdi-cloud-alert"
          >
            {{ errorUploadMsg }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="submitTeachingLog"
            class="font-weight-bold mb-1 white--text"
            color="light-blue darken-4"
            dense
            :dark="
              !loadBtnSubmit && formUploadTeachingLod.filemeetting !== null
            "
            :disabled="
              loadBtnSubmit || formUploadTeachingLod.filemeetting === null
            "
          >
            <v-progress-circular
              v-if="loadBtnSubmit"
              :size="20"
              color="white"
              indeterminate
              class="mr-2"
            ></v-progress-circular>
            Submit
          </v-btn>
          <v-btn
            @click="closeTeachingrecords"
            class="font-weight-bold mb-1"
            color=""
            dense
            dark
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const teachinglog = {
  period: "",
  filemeetting: null,
};
export default {
  props: {
    dataTeachingLog: {
      type: Array,
      default: () => [],
    },
    loadingTeaching: {
      type: Boolean,
      default: false,
    },
    listTeachingRequest: {
      type: Array,
      default: () => [],
    },
    sectionLogId: {
      type: Number,
      default: () => 0,
    },
    optionTeachingLogPeriods: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      formUploadTeachingLod: Object.assign({}, teachinglog),
      dialogTeachingRecord: false,
      errorUploadMsg: "",
      file_maxsize: 10,
      rules: [
        (value) =>
          !value || value.size < 10000000 || "size should be less than 10 MB!",
      ],
      loadBtnSubmit: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          sortable: false,
          text: "",
          value: "no",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Course",
          value: "course",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Term",
          value: "term",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Section",
          value: "section",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: true,
          text: "Teaching period",
          value: "period",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Status",
          value: "status",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "File",
          value: "file",
          align: "center",
          class:
            "font-weight-bold black--text text-center text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "",
          value: "action",
          align: "center",
          class:
            "font-weight-bold black--text text-center text-h5 blue-grey lighten-5",
        },
      ];
    },
  },
  methods: {
    openAddTeachingrecords() {
      this.dialogTeachingRecord = true;
    },
    closeTeachingrecords() {
      this.dialogTeachingRecord = false;
      this.errorUploadMsg = null;
      this.formUploadTeachingLod = Object.assign({}, teachinglog);
    },
    getFileExtensionUpload(fileType, fileSize) {
      this.errorUploadMsg = null;
      var sizeFile = (fileSize / (1024 * 1024)).toFixed(2);
      if (fileType === "application/pdf") {
        if (sizeFile <= this.file_maxsize) {
          return true;
        } else {
          this.errorUploadMsg = `Your file size is ${sizeFile} mb (file size limit is 10 mb).`;
          return false;
        }
      } else {
        this.errorUploadMsg = "You uploaded the wrong type of file.";
        return false;
      }
    },
    async submitTeachingLog() {
      //   console.log(this.formUploadTeachingLod.filemeetting.type);
      //   console.log(this.formUploadTeachingLod.filemeetting.size);
      if (
        this.getFileExtensionUpload(
          this.formUploadTeachingLod.filemeetting.type,
          this.formUploadTeachingLod.filemeetting.size
        )
      ) {
        this.loadBtnSubmit = true;
        // keystone_id: this.dataTeachingLog[0].sectionId,
        // code: this.dataTeachingLog[0].course.courseCode,
        // name: this.dataTeachingLog[0].course.courseNameEn,
        // sec: this.dataTeachingLog[0].sectionNumber,
        // term: this.dataTeachingLog[0].termText.split("/")[0],
        // year: this.dataTeachingLog[0].termText.split("/")[1],
        // credit: this.dataTeachingLog[0].course.courseCredit,
        // credit_lec: this.dataTeachingLog[0].course.lecture,
        // credit_lab: this.dataTeachingLog[0].course.lab,
        // credit_self: this.dataTeachingLog[0].course.other,
        const sendData = new FormData();
        sendData.append("term", this.dataTeachingLog[0].termText.split("/")[0]);
        sendData.append("course", this.dataTeachingLog[0].course.courseCode);
        sendData.append("section", this.dataTeachingLog[0].sectionId);
        sendData.append("around", this.formUploadTeachingLod.period);
        sendData.append("upload_file", this.formUploadTeachingLod.filemeetting);
        // try {
        //   const response = await this.$http.post("", sendData, {
        //     headers: { "Content-Type": "multipart/form-data" },
        //   });
        //   if (response.status === 201) {
        //     this.loadBtnSubmit = false;
        //     this.$emit("addTeachingLog", response.data);
        //     this.closeTeachingrecords();
        //   } else {
        //     throw { response };
        //   }
        // } catch (error) {
        //   this.loadBtnSubmit = false;
        // }
      }
    },
  },
};
</script>

<style scoped>
.f-300 {
  font-weight: 500;
}
</style>