<template>
  <div class="d-flex align-center justify-center fill-height">
    <div class="text-center">
      <div class="">
        <v-alert text prominent type="error" icon="mdi-cloud-alert">
          <span class="f-20">
            This service is currently down for maintenance. Please check back
            later.
          </span>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.f-20 {
  font-size: 20px;
}
</style>
