export default {
    state: {
        RulesUser: [],
    },
    mutations: {
        setRoleUser(state, RulesUser) {
            state.RulesUser = { ...RulesUser }
        },
    },
    actions: {
        setRoleUser({ commit }, RulesUser) {
            commit('setRoleUser', RulesUser)
        },
    },
    getters: {
        getRules: state => {
            return state.RulesUser
        },
    }
}