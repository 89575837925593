<template>
  <div>
    <!-- {{ this.$store.state.role.RulesUser.server_status }} -->
    <!-- {{ this.$store.state.role.RulesUser.role[0].name }} -->
    <div v-if="this.$store.state.role.RulesUser.server_status === 'on'">
      <!-- {{ years }} -->
      <!-- {{ selectedYear }} -->
      <v-row justify="space-between">
        <v-col cols="12" xs="4" sm="6" md="2">
          <!-- year -->
          <v-select
            label="Academic Year"
            outlined
            dense
            v-model="selectedYear"
            :items="years"
            @change="changeYearLookNowTerm"
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-switch
            dense
            class="mt-0"
            v-model="switchShow"
            label="hide rejected sections"
            color="blue"
            value="hide"
            hide-details
          ></v-switch>
        </v-col>
      </v-row>
      <!-- notification withdraw -->
      <div class="mt-0 mb-0" v-if="listStdRequestWithDrawNotifi.length != 0">
        <!-- <pre>{{ listStdRequestWithDrawNotifi }}</pre> -->
        <v-alert prominent text color="deep-orange" icon="mdi-bell-ring">
          <p class="black--text mt-0 mb-1">
            You have {{ listStdRequestWithDrawNotifi.length }} pending
            withdrawal requests from the following sections:
          </p>
          <ul
            v-for="(item, index) in listStdRequestWithDrawNotifi"
            :key="'lw' + index"
          >
            <li class="black--text">
              {{ item.Couse
              }}<span class="font-italic ml-2"
                >[{{ item.dataRequest }} requests]</span
              >
            </li>
          </ul>
        </v-alert>
      </div>
      <!-- term -->
      <v-tabs class="mt-0" v-model="selectedTerm">
        <v-tab
          v-for="(item, index) in terms"
          :key="'Term' + index"
          :disabled="!item.disabled"
          >{{ item.text }}
        </v-tab>
      </v-tabs>
      <v-row class="mt-0" v-if="dataPeriods.length != 0">
        <v-col cols="12" class="mb-2">
          <!-- data loads hide-default-header -->
          <v-data-table
            :headers="headers"
            :items="itemsLoad"
            :items-per-page="-1"
            :loading="loading"
            no-data-text="No sections"
            loading-text="loading data section"
            class="elevation-5"
            disable-pagination
            hide-default-footer
          >
            <template #item.detailsColumn="{ index, item }">
              <!-- {{ item.sectionId }} -->
              <div class="text-h6 mt-1 mb-0">Master : {{ item.sectionId }}</div>
              <a
                @click="
                  switchCourseView(index, item.sectionId);
                  $vuetify.goTo('#viewDetail');
                "
                class="font-weight-bold"
                href="#infoSection"
              >
                <p class="mt-0 mb-0">
                  <strong
                    >{{ item.course.courseCode }}
                    {{ item.course.courseCredit }}({{ item.course.lecture }}-{{
                      item.course.lab
                    }}-{{ item.course.other }})
                  </strong>
                </p>
                <p class="mt-0 mb-0">
                  {{ item.course.courseNameEn }}
                </p>
                <p class="mt-0 mb-2">section : {{ item.sectionNumber }}</p>
              </a>
              <div v-if="item.jointSections.length !== 0">
                <div class="text-h6 mt-1 mb-0">Joint Sections</div>
                <div
                  v-for="(lJoint, index) in item.jointSections"
                  :key="'joint' + index"
                >
                  <p class="mt-0 mb-0">
                    {{ lJoint.courseCode }} {{ lJoint.courseCredit }}
                  </p>
                  <p class="mt-0 mb-0">
                    {{ lJoint.courseName }}
                  </p>
                  <p class="mt-0 mb-2">section : {{ lJoint.sectionNumber }}</p>
                </div>
              </div>
            </template>
            <template #item.term="{ index, item }"
              ><p class="mt-0 mb-0 font-weight-bold">{{ item.termText }}</p>
            </template>
            <template #item.Instructors="{ index, item }">
              <!--  
                line.title !== null &&
                item.mainInstructor !== null &&
                item.mainInstructor.instructorCode !== line.instructorCode
              -->
              <!-- main-instructor -->
              <small class="mb-0 font-weight-bold">Main - instructor</small>
              <p v-if="item.mainInstructor !== null" class="mt-0 mb-1">
                {{ item.mainInstructor.title }} {{ item.mainInstructor.name }}
                {{ item.mainInstructor.lastName }}
              </p>
              <!-- co-instructor -->
              <small
                v-if="checkCoInstruc(item.coInstructors, item.mainInstructor)"
                class="mb-0 font-weight-bold"
                >Co - instructor</small
              >
              <span v-if="item.coInstructors.length != 0">
                <div
                  v-for="(line, index) in item.coInstructors"
                  :key="'coinstructor' + index"
                >
                  <p
                    v-if="
                      item.mainInstructor !== null &&
                      item.mainInstructor.instructorCode !== line.instructorCode
                    "
                    class="mt-0 mb-1"
                  >
                    {{ line.title }} {{ line.name }} {{ line.lastName }}
                  </p>
                </div>
              </span>
              <!-- team-instructor -->
              <!-- <pre>{{ item.team_instructor }}</pre> -->
              <small
                class="mb-0 font-weight-bold"
                >Team - instructor &nbsp;&nbsp;&nbsp; 
                <span v-if="!['',null,'null'].includes(item.mainInstructor) &&
                      $store.state.role.RulesUser.username ===
                        item.mainInstructor.instructorCode">
                  ( <a @click="openDrawwerTeamInstructor(item)" class="mt-0 mb-4 font-weight-bold link--text"><v-icon color="primary" dense small left>
                  mdi-pencil
                </v-icon>
                Set</a> )
                </span>
                </small
              >
              <span v-if="item.team_instructor.length != 0">
                <div
                  v-for="(line, index) in item.team_instructor"
                  :key="'coinstructor' + index"
                >
                  <p
                    class="mt-0 mb-1"
                    v-if="
                      item.mainInstructor !== null &&
                      item.mainInstructor.instructorCode !== line.instructor_code
                    "
                  >
                    <span v-if="line.instructor_detail.length != 0">
                      <span v-if="Object.keys(line.instructor_detail[0].title).length != 0">{{ line.instructor_detail[0].title.short_eng }}</span>
                      {{ line.instructor_detail[0].name }} {{ line.instructor_detail[0].surname }} 
                    </span>
                  </p>
                </div>
              </span>
            </template>
            <template #item.room="{ item }">
              <span v-if="item.sectionDetails.length != 0">
                <div
                  v-for="(room, index) in sortDayInList(item.sectionDetails)"
                  :key="'room' + index"
                >
                  <small class="font-weight-bold mt-0 mb-0">
                    <strong class="text-capitalize">{{
                      room.teachingType
                    }}</strong>
                    / {{ room.room }}
                  </small>
                  <p class="mt-0 mb-1">
                    {{ room.day }}
                    {{ room.startTime.split(":")[0] }}:{{
                      room.startTime.split(":")[1]
                    }}
                    - {{ room.endTime.split(":")[0] }}:{{
                      room.endTime.split(":")[1]
                    }}
                  </p>
                </div>
              </span>
              <span v-else
                ><v-chip class="mt-0 mb-0" label small>N / A</v-chip></span
              >
            </template>
            <template #item.loadType="{ item }">
              <span v-if="$store.state.role.RulesUser.permissions == 'fulltime'">
                <div v-if="item.SetCourse.length != 0">
                  <!-- {{ item.SetCourse }} -->
                  <span
                    class="text-h5 text-capitalize font-weight-bold"
                    :class="{
                      'text--disabled':
                        item.SetCourse[0].status === 'pre-setting',
                    }"
                  >
                    <v-icon
                      class="mr-1 pa-0"
                      small
                      :color="
                        item.SetCourse[0].status === 'save-setting'
                          ? 'success'
                          : ''
                      "
                      >{{
                        item.SetCourse[0].status === "pre-setting"
                          ? "mdi-clock-outline"
                          : "mdi-check"
                      }}</v-icon
                    >{{ item.SetCourse[0].course_type }}
                    <p class="text--disabled f-10 mt-0 mb-0">
                      {{
                        item.SetCourse[0].status === "save-setting"
                          ? "approved"
                          : "waiting for approval"
                      }}
                    </p>
                  </span>
                  <!-- btn Edit -->
                  <v-btn
                    v-if="
                      item.SetCourse[0].status === 'pre-setting' &&
                      item.mainInstructor !== null &&
                      $store.state.role.RulesUser.username ===
                        item.mainInstructor.instructorCode
                    "
                    depressed
                    color="warning"
                    dark
                    small
                    class="mt-1 mb-2"
                    @click="
                      openDialogChangeLoad(
                        item.SetCourse[0].id,
                        `${item.course.courseCode} ${item.course.courseNameEn}`,
                        'Change teaching load',
                        item
                      )
                    "
                  >
                    Edit
                  </v-btn>
                  <!-- btn Edit -->
                </div>
                <div v-else>
                  <v-btn-toggle
                    v-if="
                      item.mainInstructor !== null &&
                      $store.state.role.RulesUser.username ===
                        item.mainInstructor.instructorCode
                    "
                    v-model="loadvalue[item.sectionId]"
                    class="text-h6 font-weight-bold"
                    dense
                  >
                    <v-btn
                      color="grey lighten-5"
                      class="back--text"
                      value="contract"
                      elevation="2"
                      small
                      @click="
                        openDialog(
                          item.sectionId,
                          `${item.course.courseCode} ${item.course.courseNameEn}`,
                          'contract',
                          item
                        )
                      "
                    >
                      In Contract
                    </v-btn>
                    <v-btn
                      color="grey lighten-5"
                      class="back--text"
                      value="extra"
                      elevation="2"
                      small
                      @click="
                        openDialog(
                          item.sectionId,
                          `${item.course.courseCode} ${item.course.courseNameEn}`,
                          'extra',
                          item
                        )
                      "
                    >
                      Extra
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </span>
            </template>
            <template #item.paymentType="{ item }">
              <!-- <pre>{{ item.SetPayment }}</pre> -->
              <span v-if="$store.state.role.RulesUser.permissions == 'fulltime'">
                <v-btn-toggle
                  v-if="
                    item.SetCourse.length != 0 &&
                    item.SetCourse[0].course_type === 'extra'
                  "
                  v-model="valuePayment[item.sectionId]"
                  class="text-h6 font-weight-bold"
                  dense
                >
                  <v-btn
                    :color="
                      item.SetPayment[0].payment_scheme === 'month'
                        ? 'light-blue darken-4'
                        : 'grey lighten-5'
                    "
                    :class="
                      item.SetPayment[0].payment_scheme === 'month'
                        ? 'white--text'
                        : 'back--text'
                    "
                    value="month"
                    elevation="2"
                    small
                    @click="
                      openDialog(
                        item.SetPayment[0].id,
                        `${item.course.courseCode} ${item.course.courseNameEn}`,
                        'month',
                        item.course
                      )
                    "
                  >
                    Monthly
                  </v-btn>
                  <v-btn
                    :color="
                      item.SetPayment[0].payment_scheme === 'term'
                        ? 'light-blue darken-4'
                        : 'grey lighten-5'
                    "
                    :class="
                      item.SetPayment[0].payment_scheme === 'term'
                        ? 'white--text'
                        : 'back--text'
                    "
                    value="term"
                    elevation="2"
                    small
                    @click="
                      openDialog(
                        item.SetPayment[0].id,
                        `${item.course.courseCode} ${item.course.courseNameEn}`,
                        'term',
                        item.course
                      )
                    "
                  >
                    Term
                  </v-btn>
                </v-btn-toggle>
              </span>
              <span v-else>Monthly</span>
            </template>
          </v-data-table>
          <div id="viewDetail"></div>
        </v-col>
      </v-row>
      <!-- view detail course -->
      <div style="margin-top: 50px">
        <v-card class="mt-5">
          <v-card-title
            class="pa-2 font-weight-bold indigo darken-2 white--text mb-0"
          >
            Section Detail
          </v-card-title>
          <div v-if="selectedSection !== null">
            <!-- secInfo -->
            <sectiondetail
              :sectiondetail="sectionInfo(sections[selectedSection])"
            />
            <!-- secInfo -->
            <!-- tabInfo @click="$vuetify.goTo('#comView')" -->
            <span id="tabContent">
              <v-tabs
                class="mt-5"
                background-color="indigo darken-2"
                v-model="selectedTab"
                dark
              >
                <v-tabs-slider color="yellow lighten-1"></v-tabs-slider>
                <!-- {{ sections[selectedSection].mainInstructor.instructorCode }} -->
                <v-tab v-for="(item, index) in panes" :key="'Panes' + index">
                  <div v-if="item.component !== 'with-draw'">
                    {{ item.name }}
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        sections[selectedSection].mainInstructor !== null &&
                        sections[selectedSection].mainInstructor
                          .instructorCode ===
                          $store.state.role.RulesUser.username
                      "
                    >
                      <v-badge
                        v-if="checkNotiWithdraw(listStdRequestWithDraw)"
                        color="red"
                        :content="checkNotiWithdraw(listStdRequestWithDraw)"
                      >
                        {{ item.name }}
                      </v-badge>
                      <span v-else>{{ item.name }}</span>
                    </div>
                  </div>
                </v-tab>
              </v-tabs>
              <v-card-text>
                <v-tabs-items class="mt-3" v-model="selectedTab">
                  <v-tab-item
                    v-for="(item, index) in panes"
                    :key="'Panes2' + index"
                  >
                    <div class="mt-1">
                      <p class="mt-0 mb-0">
                        <span class="mr-2 text--disabled">Code:</span
                        >{{ sectionInfo(sections[selectedSection])[1].value }}
                        section :
                        {{ sectionInfo(sections[selectedSection])[4].value }}
                      </p>
                      <p class="mt-1 mb-0">
                        <span class="mr-2 text--disabled">Course:</span
                        >{{ sectionInfo(sections[selectedSection])[2].value }}
                      </p>
                      <p class="mt-1 mb-4">
                        <span class="mr-2 text--disabled">Credit:</span
                        >{{ sectionInfo(sections[selectedSection])[3].value }}
                      </p>
                    </div>
                    <component
                      id="comView"
                      :is="item.component"
                      :loadingAnounment="
                        item.component === 'announcement'
                          ? loadingAnounment
                          : ''
                      "
                      :dataAnounment="
                        item.component === 'announcement' ? itemUsparkData : ''
                      "
                      :dataDetail="
                        item.component === 'announcement' ? itemsTimeSlot : ''
                      "
                      :dataExam="item.component === 'exam' ? itemsTimeSlot : ''"
                      :arrCurrentTime="
                        item.component === 'timeslot' || item.component === 'exam' ? arrCurrentTime : []
                      "
                      :selYear="item.component === 'timeslot' || item.component === 'exam' ? selectedYear : ''"
                      :switchShowTerm="
                        item.component === 'timeslot' || item.component === 'exam' ? selectedTerm : ''
                      "
                      :serverTime="
                        item.component === 'timeslot' ||
                        item.component === 'exam'
                          ? serverTime
                          : ''
                      "
                      :loadValue="
                        item.component === 'timeslot' ? loadvalue : ''
                      "
                      :paymentValue="
                        item.component === 'timeslot' ? valuePayment : ''
                      "
                      :loadingTableTimeSolt="
                        item.component === 'timeslot'
                          ? loadingTableTimeSolt
                          : ''
                      "
                      :timeslots="
                        item.component === 'timeslot' ? itemsTimeSlot : ''
                      "
                      :teachingType="
                        item.component === 'timeslot' ? teachingType : ''
                      "
                      :arrMsgBlock="
                        item.component === 'timeslot' ? arrMsgBlock : ''
                      "
                      :itemDetailForStd="
                        item.component === 'student-list' ? itemsTimeSlot : ''
                      "
                      :itemStudent="
                        item.component === 'student-list' ? itemStudent : ''
                      "
                      :loadingStd="
                        item.component === 'student-list' ? loadingStd : ''
                      "
                      :loadingWithDraw="
                        item.component === 'with-draw' ? loadingWithDraw : ''
                      "
                      :WithDrawSectionId="
                        item.component === 'with-draw' ? WithDrawSectionId : ''
                      "
                      :dataWithdrawl="
                        item.component === 'with-draw' ? itemsTimeSlot : ''
                      "
                      :listStdRequestWithDraw="
                        item.component === 'with-draw'
                          ? listStdRequestWithDraw
                          : ''
                      "
                      :loadingTeaching="
                        item.component === 'teaching-records'
                          ? loadingTeaching
                          : ''
                      "
                      :dataTeachingLog="
                        item.component === 'teaching-records'
                          ? itemsTimeSlot
                          : ''
                      "
                      :optionTeachingLogPeriods="
                        item.component === 'teaching-records'
                          ? optionTeachingLogPeriods
                          : ''
                      "
                      :listTeachingRequest="
                        item.component === 'teaching-records'
                          ? listTeachingRequest
                          : ''
                      "
                      :sectionLogId="
                        item.component === 'teaching-records'
                          ? sectionLogId
                          : ''
                      "
                      @newAnnouncment="newAnnouncment"
                      @updateSlot="updateSlot"
                      @updateSlotAll="updateSlotAll"
                      @updateSlotRemark="updateSlotRemark"
                      @newSlot="newSlot"
                      @updateMakeRequest="updateMakeRequest"
                      @updateRequestWithDraw="updateRequestWithDraw"
                      @updateSlotLecture="updateSlotLecture"
                    >
                    </component>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </span>
            <!-- tabInfo -->
          </div>
          <div v-else class="text-center">
            <v-card-text class="text--disabled">
              Please choose a section from the list above.
            </v-card-text>
          </div>
        </v-card>
      </div>
    </div>
    <div v-else>
      <ErrorStatus />
    </div>

    <!-- dialog confirm -->
    <v-dialog v-model="dialog" width="500" persistent :retain-focus="false">
      <v-card>
        <v-card-title class="text-h3 pa-2 font-weight-bold blue-grey lighten-5">
          Confirm
        </v-card-title>
        <v-card-text>
          <div class="mt-0 mb-2 text-h3">
            <strong
              v-if="
                objConfirm.valueConfirm === 'extra' ||
                objConfirm.valueConfirm === 'contract'
              "
              >LoadType :</strong
            >
            <strong v-else>Payment scheme :</strong>
            <span class="ml-2 font-weight-bold text-capitalize">{{
              objConfirm.valueConfirm === "month"
                ? "Monthly"
                : objConfirm.valueConfirm
            }}</span>
          </div>
          <div class="mt-1 mb-2 text-h5">
            Course : {{ objConfirm.courseName }}
          </div>
          <!-- error log  -->
          <v-alert
            class="font-weight-bold mt-3 mb-0"
            dense
            text
            color="deep-orange darken-2 lighten-4"
            v-if="errorMsg !== null"
            >{{ errorMsg }}</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div
            v-if="
              objConfirm.valueConfirm === 'extra' ||
              objConfirm.valueConfirm === 'contract'
            "
          >
            <v-btn
              color="primary"
              dark
              class="mb-3 mt-0 font-weight-bold"
              @click="
                setType(
                  objConfirm.id,
                  objConfirm.valueConfirm,
                  objConfirm.courseDataDetail
                )
              "
            >
              Submit
            </v-btn>
          </div>
          <div
            v-if="
              objConfirm.valueConfirm === 'month' ||
              objConfirm.valueConfirm === 'term'
            "
          >
            <v-btn
              color="primary"
              dark
              class="mb-3 mt-0 font-weight-bold"
              @click="
                setTimeLogs(
                  objConfirm.id,
                  objConfirm.valueConfirm,
                  objConfirm.courseDataDetail
                )
              "
            >
              Submit
            </v-btn>
          </div>
          <v-btn
            @click="closeDialog"
            class="font-weight-bold mt-0 mb-3 ml-2"
            dense
            dark
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog cancel load -->
    <v-dialog
      v-model="dialogChangeLoad"
      width="500"
      persistent
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="text-h3 pa-2 font-weight-bold blue-grey lighten-5">
          Confirm
        </v-card-title>
        <v-card-text>
          <div class="mt-0 mb-2 text-h3">
            <span
              class="red--text text-lighten-4 font-weight-bold text-capitalize"
              >{{ objConfirm.valueConfirm }}</span
            >
          </div>
          <div class="mt-1 mb-2 text-h5">
            Courese : {{ objConfirm.courseName }}
          </div>
          <v-select
            :items="itemsTL"
            v-model="selectEditLoad"
            label="Teaching Load"
            class="mt-1 mb-1"
            outlined
            dense
            solo
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              setChangeType(
                objConfirm.id,
                objConfirm.valueConfirm,
                objConfirm.courseDataDetail
              )
            "
            color="primary"
            dark
            class="mb-3 mt-0 font-weight-bold"
          >
            Submit
          </v-btn>
          <v-btn
            @click="closeDialog"
            class="font-weight-bold mt-0 mb-3 ml-2"
            dense
            dark
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- snackbar when success -->
    <v-snackbar
      absolute
      bottom
      color="success"
      timeout="2000"
      v-model="snackbar"
    >
      <strong>Process success !!</strong>
    </v-snackbar>
    <!-- error main page -->
    <v-snackbar
      absolute
      top
      color="red darken-4"
      v-model="snackbarError"
      v-if="errorMsgCallData !== null"
    >
      <strong>{{ errorMsgCallData }}</strong>
    </v-snackbar>

    <v-dialog v-model="dialogConfig" width="600" persistent :retain-focus="false">
      <v-card class="pa-5">

        <h4 class="mt-0 mb-5">Set Instructor</h4>
        
        <!-- :search-input.sync="searchInstructor" -->
        <span v-if="!['',null].includes(caseConfig)">
          <h5 class="mt-0 mb-0 font-weight-bold red--text text--darken-4">Remove :: {{ caseConfig }}</h5>
        </span> 
        <span v-else>
          <v-autocomplete
            v-model="modelInstructor"
            :items="itemsNameInstructor"
            :loading="isLoadingSearchInstructor"
            :search-input.sync="searchInstructor"
            dense
            clearable
            color="dark"
            hide-no-data
            hide-selected
            placeholder="Start typing to Search"
            prepend-icon="mdi-database-search"
            filled
          ></v-autocomplete>
          <span v-if="isLoadingSearchInstructor">Find Lecture ...</span>
          <p class="mt-0 mb-2 font-italic">Enter your instructor name :: {{ modelInstructor }}</p>
        </span>
        <div v-if="loadinSubmitTeam" class="mt-4 text-center">
          <h5 class="mt-0 mb-0 teal--text font-italic font-weight-bold">processing ...</h5>
        </div>
        <div v-else>
          <div class="mt-4 text-center">
            <!-- @click="configApiTeamInstructor('remove')" -->
            <v-btn :disabled="['',null,'null'].includes(modelInstructor)" small color="red" class="mb-3 mr-5 white--text font-weight-bold" @click="configApiTeamInstructor('remove')">Remove form team</v-btn>
            <v-btn v-if="['',null].includes(caseConfig)" :disabled="['',null,'null'].includes(modelInstructor)" small color="primary" class="mb-3 white--text font-weight-bold mr-5" @click="configApiTeamInstructor('add')">Add to Team</v-btn>
            <v-btn small color="default" class="mb-3 dark--text font-weight-bold" @click="dialogConfig = false">Close Dialog</v-btn>
          </div>
        </div>
        <p v-if="![null,'null'].includes(errorTeamSubmit)" class="mt-0 mb-3 red--text font-italic">Msg :: {{ errorTeamSubmit }}</p>

      </v-card>
    </v-dialog>
    <!-- drawer team instructor config -->
    
    <div v-if='Object.keys(dataModalTeam).length != 0'>
      <DrawerTeamInstructor :dataModalDrawer='dataModalTeam' @emitCloseDrawer='emitCloseDrawer' @emitConfig='emitConfigAddToTeam' />
    </div>

  </div>
</template>

<script>
import Sectiondetail from "./section/SectionDetail.vue";
import Announcement from "@/views/section/Announcement";
import Exam from "@/views/section/Exam";
import Timeslot from "@/views/section/Timeslot";
import StudentList from "@/views/section/StudentList";
import WithDraw from "@/views/section/withdraw";
import TeachingRecords from "@/views/section/TeachingRecords";
import ErrorStatus from "@/views/ServicePage";
import DrawerTeamInstructor from "./section/DrawerTeamInstructor.vue";

export default {
  components: {
    Sectiondetail,
    Timeslot,
    StudentList,
    Announcement,
    Exam,
    ErrorStatus,
    WithDraw,
    TeachingRecords,
    DrawerTeamInstructor,
  },
  data() {
    return {
      switchShow: "hide",
      checkAutoTaught: "",
      options: {
        duration: 1000,
        offset: -1,
        easing: "easeInOutCubic",
      },
      errorMsg: null,
      errorMsgCallData: null,
      snackbarError: false,
      loading: false,
      teachingType: [],
      dataPeriods: [],
      selectedYear: "",
      years: [],
      selectedTerm: null,
      indexPresentTerm: "",
      indexPresentYear: "",
      terms: [],
      groupTerm: [],
      itemsAll: [],
      itemsLoad: [],
      setCouese: [],
      setPay: [],
      itemsTimeSlot: [],
      loadingTableTimeSolt: false,
      itemStudent: [],
      loadingStd: false,
      headers: [
        {
          text: "Course",
          align: "start",
          value: "detailsColumn",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
          with: "20%",
          sortable: false,
        },
        {
          text: "Term",
          align: "center",
          value: "term",
          class:
            "text-center font-weight-bold text-h5 indigo darken-2 white--text",
          sortable: false,
        },
        {
          text: "Room/Time",
          align: "start",
          value: "room",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
          sortable: false,
        },
        {
          text: "Instructors",
          align: "start",
          value: "Instructors",
          class: "font-weight-bold text-h5 indigo darken-2 white--text",
          sortable: false,
        },
        // {
        //   text: "Team Instructors",
        //   align: "center",
        //   value: "team_Instructors",
        //   class: "font-weight-bold text-center text-h5 indigo darken-2 white--text",
        //   sortable: false,
        // },
        {
          text: "Load Type",
          value: "loadType",
          align: "center",
          class:
            "text-center font-weight-bold text-h5 indigo darken-2 white--text",
          sortable: false,
        },
        {
          text: "Payment scheme",
          value: "paymentType",
          align: "center",
          class:
            "text-center font-weight-bold text-h5 indigo darken-2 white--text",
          sortable: false,
        },
      ],
      loadvalue: [],
      valuePayment: [],
      dialog: false,
      snackbar: false,
      objConfirm: {
        id: null,
        valueConfirm: null,
        courseName: null,
        courseDataDetail: null,
      },
      //
      selectedSection: null,
      selectedTab: null,
      courseIdGetSolt: null,
      LoadCourse: null,
      PayShema: null,
      panes: [
        {
          name: "Announcements",
          component: "announcement",
        },
        {
          name: "Exam",
          component: "exam",
        },
        { name: "Timeslot", component: "timeslot" },
        { name: "Student List", component: "student-list" },
        { name: "Withdrawal Request", component: "with-draw" },
        // { name: "Teaching log ", component: "teaching-records" },
      ],
      sections: [],
      // anountment
      loadingAnounment: false,
      itemUsparkData: [],
      itemPosts: [],
      // get eduMail
      listStdAllmaill: [],
      // Change teaching loads
      dialogChangeLoad: false,
      itemsTL: [
        { value: "contract", text: "Contract" },
        { value: "extra", text: "Extra" },
      ],
      selectEditLoad: "",
      // withdraw request
      loadingWithDraw: false,
      listStdRequestWithDraw: [],
      WithDrawSectionId: "",
      listStdRequestWithDrawNotifi: [],
      // teaching lod
      optionTeachingLogPeriods: [],
      loadingTeaching: false,
      listTeachingRequest: [],
      sectionLogId: "",
      // CurrentTime
      arrCurrentTime: {},
      // Block makeup 
      arrMsgBlock: [],
      // Team Instructor
      dataModalTeam: {
        drawer: false,
        itemInView: []
      },
      dialogConfig: false,
      caseConfig: null,
      // Instructor Direct search
      modelInstructor: null,
      itemsNameInstructor: [],
      isLoadingSearchInstructor: false,
      entriesInstructor: [],
      searchInstructor: null,
      loadinSubmitTeam:false,
      errorTeamSubmit:null
    };
  },
  mounted() {
    // this.getCheckAutoTaugt();
    // this.TypingInstructorSearch()
    this.getBtnMakeupSetting();
    this.getPeriods();
    this.getServerDateTime();
    this.getTeachingType();
  },
  methods: {
    // termData(term) {
    //   if (term !== "Term Summer") {
    //     return this.sections;
    //   }
    //   return [];
    // },
    checkCoInstruc(a, b) {
      let count = 0;
      if (b !== null) {
        for (let i = 0; i < a.length; i++) {
          if (b.instructorCode !== a[i].instructorCode) {
            count++;
          }
        }
      }
      return count != 0 ? true : false;
    },
    // api call json
    async getBtnMakeupSetting(){
      this.arrMsgBlock = []
      try {
        const resApi = await this.$http.get(
          `/teaching/announcement-for-makeup/check/`
        );
        if (resApi.status === 200) {
          this.arrMsgBlock = resApi.data
          this.arrMsgBlock.sort((a, b) => { return b.id - a.id; });
          // console.log(this.arrMsgBlock)
        } else {
          throw { resApi };
        }
      } catch (error) {}
    },
    async getPeriods() {
      this.arrCurrentTime = {};
      this.dataPeriods = [];
      this.years = [];
      let year = this.moment().format("YYYY");
      for (let i = year; i > year - 5; i--) {
        this.dataPeriods.push({
          value: parseInt(i),
          text: `${i}`,
        });
        this.years.push({ value: parseInt(i), text: i });
      }
      // api current academic year & term
      this.indexPresentYear = "";
      this.indexPresentTerm = "";
      try {
        const resCurrent = await this.$http.get(
          `/teaching/set-current-time/get_time/`
        );
        if (resCurrent.status === 200) {
          this.arrCurrentTime = resCurrent.data;
          this.indexPresentTerm = parseInt(resCurrent.data.term - 1);
          this.indexPresentYear = parseInt(resCurrent.data.year);
          this.setUpSelected();
        } else {
          throw { resCurrent };
        }
      } catch (error) {
        this.arrCurrentTime = {};
        this.indexPresentTerm = 0;
        this.indexPresentYear = this.moment().format("YYYY");
        this.errorMsgCallData = `Caught problem api /teaching/set-current-time/get_time/ process. Pleses refresh the page and try again.`;
      }
    },
    setUpSelected() {
      // sort periods
      this.dataPeriods.sort((a, b) => {
        return b.textYear - a.textYear;
      });
      // sort yaer
      this.years.sort((a, b) => {
        return b.value - a.value;
      });
      this.selectedYear = this.indexPresentYear;
    },
    async getCheckAutoTaugt() {
      this.errorMsgCallData = null;
      this.checkAutoTaught = "";
      try {
        const resAutoTaught = await this.$http.get(
          `/teaching/auto-taught/check/`
        );
        if (resAutoTaught.status === 200) {
          if (resAutoTaught.data.muic_account === "") {
            this.checkAutoTaught = "off";
          } else {
            this.checkAutoTaught = "on";
          }
        } else {
          throw { resAutoTaught };
        }
      } catch (error) {
        this.checkAutoTaught = "off";
        this.errorMsgCallData = `Caught problem api /teaching/auto-taught/check/ process. Pleses refresh the page and try again.`;
      }
    },
    async getServerDateTime() {
      this.serverTime = "";
      try {
        const restime = await this.$http.get(`/keystone/section/get_datetime/`);
        if (restime.status === 200) {
          this.serverTime = restime.data.now;
        } else {
          throw { restime };
        }
      } catch (error) {
        this.serverTime = "";
        this.errorMsgCallData = `Caught problem api /keystone/section/get_datetime/ process. Pleses refresh the page and try again.`;
      }
    },
    async getTeachingType() {
      this.teachingType = [];
      try {
        const resTeachingType = await this.$http.get(
          `/keystone/section/get_teaching_types/`
        );
        if (resTeachingType.status === 200) {
          this.teachingType = resTeachingType.data.data
            .filter(
              (text) =>
                text.nameEn === "lecture" ||
                text.nameEn === "lab" ||
                text.nameEn === "faa_lab" ||
                text.nameEn === "field_trip"
            )
            .map((item) => {
              return {
                text: item.nameEn,
                value: item.teachingTypeId,
              };
            });
        } else {
          throw { resTeachingType };
        }
      } catch (error) {
        this.errorMsgCallData = `Caught problem api /keystone/section/get_teaching_types/ process. Pleses refresh the page and try again.`;
        this.teachingType = [];
      }
    },
    switchCourseView(indexView, secID) {
      this.selectedSection = indexView;
      this.selectedTab = 0;
      this.itemsTimeSolt = [];
      this.courseIdGetSolt = secID;
    },
    sectionInfo(sec) {
      // this.LoadCourse = sec.loadType;
      // this.PayShema = sec.paymentType;
      // console.log(sec)
      // this.checkBlankData(sec.username)
      return [
        {
          label: "Section creator",
          value: "",
          size: 2,
        },
        {
          label: "Course Code",
          value: this.checkBlankData(sec.course.courseCode),
          size: 2,
        },
        {
          label: "Course Name",
          value: this.checkBlankData(sec.course.courseNameEn),
          size: 6,
        },
        {
          label: "Course Credit",
          value: `${this.checkBlankData(
            sec.course.courseCredit
          )}(${this.checkBlankData(sec.course.lecture)}-${this.checkBlankData(
            sec.course.lab
          )}-${this.checkBlankData(sec.course.other)})`,
          size: 2,
        },
        {
          label: "Section Number",
          value: this.checkBlankData(sec.sectionNumber),
          size: 2,
        },
        {
          label: "Status",
          value:
            this.checkBlankData(sec.status) === "a"
              ? "Approved"
              : this.checkBlankData(sec.status) === "w"
              ? "Waiting"
              : "Reject",
          size: 2,
        },
        {
          label: "Min Capacity",
          value: this.checkBlankData(sec.minimumSeat),
          size: 2,
        },
        {
          label: "Max Capacity",
          value: this.checkBlankData(sec.seatLimit),
          size: 2,
        },
        {
          label: "Planned Capacity",
          value: this.checkBlankData(sec.planningSeat),
          size: 2,
        },
        {
          label: "Extra Capacity",
          value: this.checkBlankData(sec.extraSeat),
          size: 2,
        },
        {
          label: "Seats Taken",
          value: this.checkBlankData(sec.totalSeatUsed),
          size: 2,
        },
        {
          label: "Main Lecturer",
          value:
            sec.mainInstructor !== null
              ? this.checkBlankData(sec.mainInstructor.title) +
                " " +
                this.checkBlankData(sec.mainInstructor.name) +
                " " +
                this.checkBlankData(sec.mainInstructor.lastName)
              : "-",
          size: 3,
        },
        {
          label: "Co-instructors",
          value: this.getListCoinstructor(sec.coInstructors),
          size: 3,
        },
        { label: "Withdrawable", value: true, size: 2 },
        { label: "Remark", value: this.checkBlankData(sec.remark), size: 12 },
      ];
    },
    changeYearLookNowTerm() {
      this.selectedTerm = this.groupTerm[0];
    },
    checkBlankData(val) {
      return val !== null && val !== "" && val !== "null" ? val : "";
    },
    getListCoinstructor(val) {
      let strCoinstructor = "";
      if (val.length != 0) {
        for (let list = 0; list < val.length; list++) {
          if (
            val[list].title !== null &&
            val[list].name !== null &&
            val[list].lastName !== null
          ) {
            strCoinstructor += `${val[list].title} ${val[list].name} ${val[list].lastName} ,`;
          }
        }
      } else {
        strCoinstructor = "-";
      }
      return strCoinstructor;
    },
    groupByTerm(data, term) {
      let arrTerm = [];
      for (let d = 0; d < data.length; d++) {
        if (parseInt(data[d].termText.split("/")[0]) == parseInt(term)) {
          arrTerm.push(data[d]);
        }
      }
      return arrTerm;
    },
    filterLoadSet(dataLP, idsec) {
      if (dataLP.length != 0) {
        let arr = [];
        arr = dataLP
          .filter((a) => a.section_id == idsec)
          .map((item) => ({ ...item }));
        return arr.length != 0 ? arr : [];
      } else {
        return [];
      }
    },
    filterPaySet(dataLP, idsec) {
      if (dataLP.length != 0) {
        let arr = [];
        arr = dataLP
          .filter((a) => a.section_id == idsec)
          .map((item) => ({ ...item }));
        return arr.length != 0 ? arr : [];
      } else {
        return [];
      }
    },
    // ks api
    async getCourseInTerm() {
      this.loading = true;
      this.itemsAll = [];
      this.itemsLoad = [];
      this.sections = [];
      this.groupTerm = [];
      this.selectedSection = null;
      this.setCouese = [];
      this.setPay = [];
      this.listStdRequestWithDrawNotifi = [];
      try {
        const resCourse = await this.$http.get(
          // `https://instructor.muic.io/api/keystone/section/get_sections/?academic_year=2023`
          `/keystone/section/get_sections/?academic_year=${this.selectedYear}`
        );
        if (resCourse.status === 200) {
          // set noti withdraw
          if (resCourse.data.data !== null) {
            for (let t = 0; t < resCourse.data.data.length; t++) {
              // set group term
              if (
                this.groupTerm.indexOf(
                  parseInt(resCourse.data.data[t].termText.split("/")[0])
                ) < 0
              ) {
                this.groupTerm.push(
                  parseInt(resCourse.data.data[t].termText.split("/")[0])
                );
              }
              // set load and schema by api query
              try {
                const resL = await this.$http.get(
                  `/teaching/course/me/?section_id=${resCourse.data.data[t].sectionId}`
                );
                if (resL.status === 200) {
                  this.setCouese.push({ ...resL.data[0] });
                } else {
                  throw { resL };
                }
              } catch (error) {
                this.setCouese = [];
              }
              // set payment scheme
              try {
                const resPay = await this.$http.get(
                  `/teaching/course/payment_scheme/?section_id=${resCourse.data.data[t].sectionId}`
                );
                if (resPay.status === 200) {
                  this.setPay.push({ ...resPay.data });
                  // console.log(this.setPay)
                } else {
                  throw { resPay };
                }
              } catch (error) {
                this.setPay = [];
              }
            }
            this.groupTerm.sort((a, b) => {
              return a - b;
            });
            // set data all showin table
            this.itemsAll = resCourse.data.data
              .filter((nojoint) => nojoint.sectionTypes !== "Joint")
              .map((item) => ({
                ...item,
                SetCourse: this.filterLoadSet(this.setCouese, item.sectionId),
                SetPayment: this.filterPaySet(this.setPay, item.sectionId),
              }));
            // set term
            this.selectedTerm = parseInt(this.indexPresentTerm);
            // this.selectedTerm = parseInt(this.groupTerm[0] - 1);
            // set sort
            this.itemsLoad.sort(function (a, b) {
              var nameA = a.termText.toUpperCase();
              var nameB = b.termText.toUpperCase();
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              return 0;
            });
            // get withdraw
            this.getAlertInfoWithDraw(resCourse.data.data);
          }
          // loading
          this.loading = false;
        } else {
          throw { resCourse };
        }
      } catch (error) {
        this.errorMsgCallData = `Caught problem api /keystone/section/get_sections/?academic_year=${this.selectedYear} process. Pleses refresh the page and try again.`;
        this.loading = false;
      }
    },
    // uspark api
    async getClassGroup(ksSectionId) {
      this.loadingAnounment = true;
      this.itemUsparkData = [];
      try {
        const resUsparkApi = await this.$http.get(
          `/uspark/announce/classgroups/?ksSectionId=${ksSectionId}`
        );
        if (resUsparkApi.status === 200 && resUsparkApi.data !== null) {
          this.itemUsparkData = resUsparkApi.data.data.map((item) => ({
            ...item,
          }));
          this.loadingAnounment = false;
        } else {
          throw { resUsparkApi };
        }
      } catch (error) {
        this.errorMsgCallData = `Caught problem api /uspark/announce/classgroups/?ksSectionId=${ksSectionId} process. Pleses refresh the page and try again.`;
        this.loadingAnounment = false;
      }
    },
    newAnnouncment(...args) {
      // console.log(args);
      this.getClassGroup(args[0]);
    },
    openDialog(idCourse, coueseName, valSet, coueseData) {
      this.objConfirm.id = idCourse;
      this.objConfirm.courseName = coueseName;
      this.objConfirm.valueConfirm = valSet;
      this.objConfirm.courseDataDetail = coueseData;
      this.dialog = true;
    },
    closeDialog() {
      this.dialogChangeLoad = false;
      this.dialog = false;
      this.objConfirm.id = null;
      this.objConfirm.courseName = null;
      this.objConfirm.valueConfirm = null;
      this.objConfirm.courseDataDetail = null;
      this.selectEditLoad = "";
    },
    openDialogChangeLoad(idCourse, coueseName, valSet, coueseData) {
      this.objConfirm.id = idCourse;
      this.objConfirm.courseName = coueseName;
      this.objConfirm.valueConfirm = valSet;
      this.objConfirm.courseDataDetail = coueseData;
      this.dialogChangeLoad = true;
    },
    async setType(idApp, typeLecture, courseData) {
      // console.log(courseData)
      // let arrUP = [];
      try {
        const sendSetType = new FormData();
        sendSetType.append("section_id", idApp);
        sendSetType.append("course_type", typeLecture);
        sendSetType.append("status", "pre-setting");
        // data - course
        sendSetType.append("term_text", courseData.termText);
        sendSetType.append("course_other", courseData.course.other);
        sendSetType.append("course_lab", courseData.course.lab);
        sendSetType.append("course_lecture", courseData.course.lecture);
        sendSetType.append("course_credit", courseData.course.courseCredit);
        sendSetType.append("course_name_en", courseData.course.courseNameEn);
        sendSetType.append("course_code", courseData.course.courseCode);
        sendSetType.append("course_id", courseData.course.courseId);
        sendSetType.append("academic_year", this.selectedYear);
        // date exam format
        sendSetType.append("midterm_date", courseData.midtermDate);
        sendSetType.append("midterm_start", courseData.midtermStart);
        sendSetType.append("midterm_end", courseData.midtermEnd);
        sendSetType.append("final_date", courseData.finalDate);
        sendSetType.append("final_start", courseData.finalStart);
        sendSetType.append("final_end	", courseData.finalEnd);
        sendSetType.append(
          "section_amount",
          parseInt(courseData.studentList.length)
        );
        sendSetType.append("section_number", courseData.sectionNumber);
        sendSetType.append(
          "main_instructor",
          courseData.mainInstructor.instructorCode
        );
        // set default load
        const updateType = await this.$http.post(
          `/teaching/course/add/`,
          sendSetType,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (updateType.status === 201) {
          let SetCourse = updateType.data;
          // find -1
          const index = this.itemsLoad.findIndex(
            (list) => list.sectionId == updateType.data.section_id
          );
          this.itemsLoad[index].SetCourse.splice(index, 1, SetCourse);
          this.closeDialog();
        } else {
          throw { updateType };
        }
      } catch (error) {
        this.errorMsg =
          "Caught problem process API : /teaching/course/ . Pleses refresh the page and try again.";
      }
    },
    async setChangeType(idApp, typePay, courseData) {
      try {
        const sendChangeLoad = new FormData();
        sendChangeLoad.append("course_type", this.selectEditLoad);
        const updateTeachingLoad = await this.$http.patch(
          `/teaching/course/${idApp}/set/`,
          sendChangeLoad,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (updateTeachingLoad.status === 200) {
          const index = this.itemsLoad.findIndex(
            (a) => a.sectionId == updateTeachingLoad.data.section_id
          );
          const indexSchema = this.itemsLoad[index].SetCourse.findIndex(
            (b) => b.section_id == updateTeachingLoad.data.section_id
          );
          this.itemsLoad[index].SetCourse[indexSchema].course_type =
            updateTeachingLoad.data.course_type;
          this.closeDialog();
        } else {
          throw { updateTeachingLoad };
        }
      } catch (error) {}
    },
    async setTimeLogs(idApp, typePay, courseData) {
      try {
        const sendSetSchema = new FormData();
        sendSetSchema.append("payment_scheme", typePay);
        const updateSchema = await this.$http.patch(
          `/teaching/course/${idApp}/set_payment_scheme/`,
          sendSetSchema,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (updateSchema.status === 200) {
          // find - 1
          const index = this.itemsLoad.findIndex(
            (a) => a.sectionId == updateSchema.data.section_id
          );
          // find - 2
          const indexSchema = this.itemsLoad[index].SetPayment.findIndex(
            (b) => b.section_id == updateSchema.data.section_id
          );
          this.itemsLoad[index].SetPayment[indexSchema].payment_scheme =
            updateSchema.data.payment_scheme;
          // console.log(this.itemsLoad[index].SetPayment[indexSchema])
          this.closeDialog();
        } else {
          throw { updateSchema };
        }
      } catch (error) {
        this.errorMsg = `Caught problem process API : /teaching/course/${idApp}/ . Pleses refresh the page and try again.`;
      }
    },
    sortSlot(val) {
      for (let i = 0; i < val.length; i++) {
        // sort A
        val[i].sectionSlots.sort(function (a, b) {
          var nameA = `${a.date.toUpperCase()}-${a.endTime.toUpperCase()}`;
          var nameB = `${b.date.toUpperCase()}-${b.endTime.toUpperCase()}`;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }
    },
    async getTimesolt(courseId, viewCase) {
      this.itemsTimeSlot = [];
      this.itemsTimeSlot = this.itemsLoad
        .filter((slotTime) => slotTime.sectionId === courseId)
        .map((item) => ({ ...item }));
      let urlQuery = "";
      // update object
      if (viewCase === "timesolt") {
        this.loadingTableTimeSolt = true;
        for (let u = 0; u < this.itemsTimeSlot.length; u++) {
          for (
            let uslot = 0;
            uslot < this.itemsTimeSlot[u].sectionSlots.length;
            uslot++
          ) {
            if (
              this.itemsTimeSlot[u].sectionSlots[uslot].status === "p" ||
              this.itemsTimeSlot[u].sectionSlots[uslot].status === "c" ||
              this.itemsTimeSlot[u].sectionSlots[uslot].teachingType ===
                "Midterm" ||
              this.itemsTimeSlot[u].sectionSlots[uslot].teachingType === "Final"
            ) {
              urlQuery =
                this.itemsTimeSlot[u].sectionSlots[uslot].teachingType ===
                  "Midterm" ||
                this.itemsTimeSlot[u].sectionSlots[uslot].teachingType ===
                  "Final"
                  ? `${this.itemsTimeSlot[u].sectionSlots[uslot].examinationId}-${this.itemsTimeSlot[u].sectionSlots[uslot].sectionId}`
                  : this.itemsTimeSlot[u].sectionSlots[uslot].sectionSlotId;
              try {
                const resPayLog = await this.$http.get(
                  `teaching/payment-list/search_by_slot/?section_slot_id=${urlQuery}`
                );
                if (resPayLog.status === 200) {
                  this.itemsTimeSlot[u].sectionSlots[uslot] = Object.assign({
                    ...this.itemsTimeSlot[u].sectionSlots[uslot],
                    Apayment:
                      resPayLog.data.length !== 0 ? resPayLog.data : "-",
                  });
                } else {
                  throw { resPayLog };
                }
              } catch (error) {}
            }
          }
        }
        // console.log(this.itemsTimeSlot);
      } else {
        for (let u = 0; u < this.itemsTimeSlot.length; u++) {
          for (
            let uslot = 0;
            uslot < this.itemsTimeSlot[u].sectionSlots.length;
            uslot++
          ) {
            this.itemsTimeSlot[u].sectionSlots[uslot] = Object.assign({
              ...this.itemsTimeSlot[u].sectionSlots[uslot],
              Apayment: "-",
            });
          }
        }
      }
      // sort
      this.sortSlot(this.itemsTimeSlot);
      this.loadingTableTimeSolt = false;
    },
    filterEdumail(dataLP, idStd) {
      if (dataLP.length != 0) {
        for (let ff = 0; ff < dataLP.length; ff++) {
          if (dataLP[ff].student === idStd) {
            return dataLP[ff];
          }
        }
      } else {
        return [];
      }
    },
    async getStudent(courseId) {
      this.loadingStd = true;
      // console.log(courseId)
      let ArrDataStd = [];
      this.itemStudent = [];
      this.listStdAllmaill = [];
      let arrDataResponse = [];
      try {
        ArrDataStd = this.itemsLoad
          .filter((slotTime) => slotTime.sectionId === courseId)
          .map((item) => ({
            ...item,
          }));
        this.itemStudent = ArrDataStd;
        // set api search
        const sendDataListStd = new FormData();
        for (let std = 0; std < ArrDataStd.length; std++) {
          for (let s = 0; s < ArrDataStd[std].studentList.length; s++) {
            this.listStdAllmaill.push(
              ArrDataStd[std].studentList[s].studentCode
            );
          }
        }
        // console.log(this.listStdAllmaill);
        sendDataListStd.append("list_std", this.listStdAllmaill);
        const resMail = await this.$http.post(
          `/keystone/section/std_edu_search/`,
          sendDataListStd,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (resMail.status === 200) {
          arrDataResponse = resMail.data.map((item) => ({
            ...item,
          }));
          // add obj
          let studentListA = {};
          let studentList = [];
          for (let mObj = 0; mObj < ArrDataStd[0].studentList.length; mObj++) {
            studentListA = Object.assign({
              ...ArrDataStd[0].studentList[mObj],
              eduMailMUIC: this.filterEdumail(
                arrDataResponse,
                ArrDataStd[0].studentList[mObj].studentCode
              ),
            });
            studentList.push(studentListA);
          }
          // console.log(studentList);
          this.itemStudent[0] = {
            ...this.itemStudent[0],
            studentList,
          };
          // console.log(this.itemStudent[0].studentList);
          let arrUp = [];
          arrUp.push(this.itemStudent[0]);
          this.itemStudent = arrUp;
          this.loadingStd = false;
        } else {
          throw { resMail };
        }
      } catch (error) {
        this.loadingStd = false;
      }
    },
    async updateSlotAll(...args) {
      let status = "p";
      let Apayment = [];
      try {
        const resPay = await this.$http.get(
          `teaching/payment-list/search_by_slot/?section_slot_id=${args[0].slotId}`
        );
        if (resPay.status === 200) {
          Apayment = resPay.data.length !== 0 ? resPay.data : "-";
          const index = this.itemsLoad.findIndex(
            (list) => list.sectionId == args[0].secId
          );
          const indexSlot = this.itemsLoad[index].sectionSlots.findIndex(
            (slot) => slot.sectionSlotId == args[0].slotId
          );
          this.itemsLoad[index].sectionSlots[indexSlot] = {
            ...this.itemsLoad[index].sectionSlots[indexSlot],
            Apayment,
            status,
          };
          let arrSlotUp = [];
          arrSlotUp.push(this.itemsLoad[index].sectionSlots[indexSlot]);
          this.itemsLoad[index].sectionSlots.splice(indexSlot, 1, arrSlotUp[0]);
        } else {
          throw { resPay };
        }
      } catch (error) {}
    },
    updateSlotRemark(...args) {
      // console.log(args);
      // console.log(args[0].detail);
      let remark = args[0].textadd;
      const index = this.itemsLoad.findIndex(
        (list) => list.sectionId == args[0].detail.sectionId
      );
      const indexSlot = this.itemsLoad[index].sectionSlots.findIndex(
        (slot) => slot.sectionSlotId == args[0].detail.sectionSlotId
      );
      // console.log(this.itemsLoad[index].sectionSlots[indexSlot])
      this.itemsLoad[index].sectionSlots[indexSlot] = {
        ...this.itemsLoad[index].sectionSlots[indexSlot],
        remark,
      };
      let arrSlotUp = [];
      arrSlotUp.push(this.itemsLoad[index].sectionSlots[indexSlot]);
      this.itemsLoad[index].sectionSlots.splice(indexSlot, 1, arrSlotUp[0]);
    },
    async updateSlot(...args) {
      // get payment solt
      let Apayment = [];
      let status = args[0].status;
      let queryId =
        args[0].teachingT === "Midterm" || args[0].teachingT === "Final"
          ? `${args[0].slotId}-${args[0].secId}`
          : args[0].slotId;
      try {
        const resPay = await this.$http.get(
          `teaching/payment-list/search_by_slot/?section_slot_id=${queryId}`
        );
        if (resPay.status === 200) {
          Apayment = resPay.data.length !== 0 ? resPay.data : "-";
          // findIndex main load
          const index = this.itemsLoad.findIndex(
            (list) => list.sectionId === args[0].secId
          );
          // findIndex slot
          const indexSlot = this.itemsLoad[index].sectionSlots.findIndex(
            (slot) =>
              args[0].teachingT === "Midterm" || args[0].teachingT === "Final"
                ? slot.examinationId === args[0].slotId
                : slot.sectionSlotId === args[0].slotId
          );
          // update
          this.itemsLoad[index].sectionSlots[indexSlot] = {
            ...this.itemsLoad[index].sectionSlots[indexSlot],
            Apayment,
            status,
          };
          let arrSlotUp = [];
          arrSlotUp.push(this.itemsLoad[index].sectionSlots[indexSlot]);
          this.itemsLoad[index].sectionSlots.splice(indexSlot, 1, arrSlotUp[0]);
        } else {
          throw { resPay };
        }
      } catch (error) {}
    },
    newSlot(...args) {
      const index = this.itemsLoad.findIndex(
        (list) => list.sectionId === args[0].sectionId
      );
      this.itemsLoad[index].sectionSlots.push(args[0]);
      // sort
      this.sortSlot(this.itemsLoad);
    },
    // make request update
    updateMakeRequest(...args) {
      // console.log(args);
      // findIndex main
      const index = this.itemsTimeSlot.findIndex(
        (list) => list.sectionId === args[0].sectionId
      );
      // check
      if (this.itemsTimeSlot[index].examinationReservation.length !== 0) {
        for (
          let a = 0;
          a < this.itemsTimeSlot[index].examinationReservation.length;
          a++
        ) {
          if (
            this.itemsTimeSlot[index].examinationReservation[a].examinationId ==
            args[0].examinationId
          ) {
            // delete old val
            this.itemsTimeSlot[index].examinationReservation.splice(a, 1);
          }
        }
      }
      // add new val
      this.itemsTimeSlot[index].examinationReservation.push(args[0]);
    },
    updateSlotLecture(...args){
      // console.log(args[0].responseRecive.data)
      let instructor = Object.assign({},{
        'instructorCode': `${args[0].responseRecive.data.instructor.instructorCode}`,
        'lastName':  `${args[0].responseRecive.data.instructor.lastName}`,
        'name':  `${args[0].responseRecive.data.instructor.name}`,
        'title':  `${args[0].responseRecive.data.instructor.title}`,
      })
      const index = this.itemsLoad.findIndex(
        (list) => list.sectionId == args[0].val.sectionId
      );
      const indexSlot = this.itemsLoad[index].sectionSlots.findIndex(
        (slot) => slot.sectionSlotId == args[0].val.sectionSlotId
      );
      this.itemsLoad[index].sectionSlots[indexSlot] = {
        ...this.itemsLoad[index].sectionSlots[indexSlot],
        instructor,
      };
      let arrSlotUp = [];
      arrSlotUp.push(this.itemsLoad[index].sectionSlots[indexSlot]);
      this.itemsLoad[index].sectionSlots.splice(indexSlot, 1, arrSlotUp[0]);
    },
    mapDaytoNum(val) {
      let arrDay = [
        { num: 7, text: "SUN" },
        { num: 6, text: "SAT" },
        { num: 1, text: "MON" },
        { num: 2, text: "TUE" },
        { num: 3, text: "WED" },
        { num: 4, text: "THU" },
        { num: 5, text: "FRI" },
      ];
      for (let a = 0; a < arrDay.length; a++) {
        if (arrDay[a].text === val) {
          return arrDay[a].num;
        }
      }
    },
    sortDayInList(listDetail) {
      let arrTmp = [];
      arrTmp = listDetail.map((item) => ({
        ...item,
        numberDay: this.mapDaytoNum(item.day),
      }));
      return arrTmp.sort((a, b) => {
        return a.numberDay - b.numberDay;
      });
    },
    // with draw request
    async getAlertInfoWithDraw(listData) {
      // console.log(listData);
      let fTrem = this.indexPresentTerm;
      this.listStdRequestWithDrawNotifi = [];
      for (let index = 0; index < listData.length; index++) {
        const callUsApi = listData[index];
        if (
          callUsApi.termText ===
            `${parseInt(fTrem + 1)}/${this.selectedYear}` &&
          callUsApi.sectionTypes === "Master" &&
          callUsApi.mainInstructor !== null &&
          callUsApi.mainInstructor.instructorCode ===
            this.$store.state.role.RulesUser.username
        ) {
          // console.log(callUsApi.sectionId)
          try {
            const reswithDrawNoti = await this.$http.get(
              `/uspark/withdrawal/${callUsApi.sectionId}/view/`
            );
            if (reswithDrawNoti.status === 200) {
              if (
                reswithDrawNoti.data.data.length !== 0 &&
                reswithDrawNoti.data.data !== null
              ) {
                let checkPending = () => {
                  return reswithDrawNoti.data.data
                    .filter((a) => a.status === "PENDING")
                    .map((item) => ({ ...item }));
                };
                if (checkPending().length !== 0) {
                  this.listStdRequestWithDrawNotifi.push({
                    idSection: callUsApi.sectionId,
                    Couse: `${callUsApi.course.courseCode} ${callUsApi.course.courseNameEn} (Section ${callUsApi.sectionNumber})`,
                    dataRequest: checkPending().length,
                  });
                }
              }
            } else {
              throw { reswithDrawNoti };
            }
          } catch (error) {}
        }
      }
      // console.log(this.listStdRequestWithDrawNotifi);
    },
    checkNotiWithdraw(dataWD) {
      if (dataWD.length != 0) {
        let arr = [];
        arr = dataWD
          .filter((a) => a.status === "PENDING")
          .map((item) => ({ ...item }));
        return arr.length != 0 ? arr.length : 0;
      } else {
        return 0;
      }
    },
    async getWithDrawRequest(id) {
      this.loadingWithDraw = true;
      this.listStdRequestWithDraw = [];
      this.WithDrawSectionId = id;
      try {
        const reswithDraw = await this.$http.get(
          `/uspark/withdrawal/${id}/view/`
        );
        if (reswithDraw.status === 200) {
          this.listStdRequestWithDraw = reswithDraw.data.data.map((item) => ({
            ...item,
            StudentName: `${item.firstNameEn} ${item.lastNameEn}`,
          }));
          this.loadingWithDraw = false;
        } else {
          throw { reswithDraw };
        }
      } catch (error) {
        this.listStdRequestWithDraw = [];
        this.errorMsg = `Caught problem process API : /uspark/withdrawal/${id}/view/ . Pleses refresh the page and try again.`;
        this.loadingWithDraw = false;
      }
    },
    reUpdateNoti(idSec) {
      let arrReUpdate = [];
      const reNoti = this.listStdRequestWithDrawNotifi.findIndex(
        (list) => list.idSection === idSec
      );
      let dataRequest =
        this.listStdRequestWithDrawNotifi[reNoti].dataRequest === 0
          ? 0
          : parseInt(this.listStdRequestWithDrawNotifi[reNoti].dataRequest - 1);
      this.listStdRequestWithDrawNotifi[reNoti] = {
        ...this.listStdRequestWithDrawNotifi[reNoti],
        dataRequest,
      };
      arrReUpdate.push(this.listStdRequestWithDrawNotifi[reNoti]);
      this.listStdRequestWithDrawNotifi.splice(reNoti, 1, arrReUpdate[0]);
      // renew arr notification
      let newArr = [];
      for (let l = 0; l < this.listStdRequestWithDrawNotifi.length; l++) {
        const renew = this.listStdRequestWithDrawNotifi[l];
        if (renew.dataRequest !== 0) {
          newArr.push({
            idSection: renew.idSection,
            Couse: renew.Couse,
            dataRequest: renew.dataRequest,
          });
        }
      }
      this.listStdRequestWithDrawNotifi = newArr;
    },
    updateRequestWithDraw(...args) {
      // console.log(args);
      let arrUpdate = [];
      const index = this.listStdRequestWithDraw.findIndex(
        (list) => list.id === args[0].id
      );
      let status = args[0].status;
      this.listStdRequestWithDraw[index] = {
        ...this.listStdRequestWithDraw[index],
        status,
      };
      arrUpdate.push(this.listStdRequestWithDraw[index]);
      this.listStdRequestWithDraw.splice(index, 1, arrUpdate[0]);
      this.checkNotiWithdraw(this.listStdRequestWithDraw);
      // reupdate noti
      this.reUpdateNoti(args[0].secId);
    },
    // teaching log
    async getOptionTeachingLogPeriod() {
      this.optionTeachingLogPeriods = [];
      // try {
      //   const resOptionLog = await this.$http.get(`${id}`);
      //   if (resOptionLog.status === 200) {
      //     this.optionTeachingLogPeriods = resOptionLog.data.map((item) => ({
      //       ...item,
      //     }));
      //   } else {
      //     throw { resOptionLog };
      //   }
      // } catch (error) {
      //   this.optionTeachingLogPeriods = [];
      //   this.errorMsg = `Caught problem process API :  . Pleses refresh the page and try again.`;
      // }
    },
    async getTeachingLog(id) {
      // console.log(id);
      this.errorMsg = "";
      this.loadingTeaching = true;
      this.listTeachingRequest = [];
      this.sectionLogId = id;
      // try {
      //   const resLog = await this.$http.get(`${id}`);
      //   if (resLog.status === 200) {
      //     this.listTeachingRequest = resLog.data.map((item) => ({
      //       ...item,
      //     }));
      //     this.loadingTeaching = false;
      //   } else {
      //     throw { resLog };
      //   }
      // } catch (error) {
      //   this.listTeachingRequest = [];
      //   this.errorMsg = `Caught problem process API :  . Pleses refresh the page and try again.`;
      this.loadingTeaching = false;
      // }
    },
    // hide reject section
    hideRejectsection(data) {
      let arrS = [];
      if (this.switchShow === "hide") {
        for (let a = 0; a < data.length; a++) {
          const sectionC = data[a];
          if (sectionC.status === "a") {
            // console.log(sectionC);
            arrS.push(sectionC);
          }
        }
        return arrS;
      } else {
        return data;
      }
    },
    // set teamInstructor
    openDrawwerTeamInstructor(data){
      if(this.dataModalTeam.drawer){
        this.dataModalTeam = {
          drawer: false,
          itemInView: []
        }
      }else{
        this.dataModalTeam = {
          drawer: true,
          itemInView: data
        }
      }
    },
    emitCloseDrawer(...args){
      this.openDrawwerTeamInstructor(args[0])
    },
    emitConfigAddToTeam(...args){
      // console.log(args[0])
      this.isLoadingSearchInstructor = false
      this.loadinSubmitTeam = false
      this.errorTeamSubmit = null
      this.caseConfig = null
      if(this.dialogConfig){
        this.dialogConfig = false
        this.modelInstructor = null
        this.searchInstructor = null
        this.itemsNameInstructor = []
      }else{
        if(args[0].lectureIndex.length != 0){
          this.itemsNameInstructor.push({ 
            text: args[0].lectureIndex[0].instructor_detail.length != 0 ? `${args[0].lectureIndex[0].instructor_detail[0].title.hasOwnProperty('short_eng') ? args[0].lectureIndex[0].instructor_detail[0].title.short_eng : ''} ${args[0].lectureIndex[0].instructor_detail[0].name} ${args[0].lectureIndex[0].instructor_detail[0].surname}` : '',
            value: args[0].lectureIndex[0].instructor_code 
          })
          this.modelInstructor = args[0].lectureIndex[0].instructor_code 
          this.caseConfig = `${args[0].lectureIndex[0].instructor_detail[0].title.hasOwnProperty('short_eng') ? args[0].lectureIndex[0].instructor_detail[0].title.short_eng : ''} ${args[0].lectureIndex[0].instructor_detail[0].name} ${args[0].lectureIndex[0].instructor_detail[0].surname}`
        }else{
          this.modelInstructor = null
          this.searchInstructor = null
          this.itemsNameInstructor = []
          this.caseConfig = null
        }
        this.dialogConfig = true
      }
    },
    async TypingInstructorSearch(val){
      this.errorTeamSubmit = null
      this.isLoadingSearchInstructor = true
      try {
        const res = await this.$http.get(`/teaching/contract/lecturer_list/?search=${val}`)
        if([200,201].includes(res.status)){
          this.itemsNameInstructor = res.data.map((item) => {
            return {
              text: `${item.title.hasOwnProperty('short_eng') ? item.title.short_eng : ''} ${item.name} ${item.surname}`,
              value: item.muic_account, 
              ...item,
            };
          });
          this.isLoadingSearchInstructor = false
          // console.log(this.entriesInstructor)
        }else{
          throw { res }
        }
      } catch (error) {
        // console.log(error)
        this.isLoadingSearchInstructor = false
        this.errorTeamSubmit = `Error :: ${error.response.data.msg}, /teaching/contract/lecturer_list/?search`
      }
    },
    // submit add Team
    async configApiTeamInstructor(actionConfig){
      this.loadinSubmitTeam = true
      this.errorTeamSubmit = null
      const sendSetTeam = new FormData();
      sendSetTeam.append("academic_year", this.dataModalTeam.itemInView.termText.split("/")[1]);
      sendSetTeam.append("academic_term", this.dataModalTeam.itemInView.termText.split("/")[0]);
      sendSetTeam.append("section_id", `${this.dataModalTeam.itemInView.sectionId}`);
      sendSetTeam.append("instructor_code", this.modelInstructor);

      let urlAction = actionConfig === 'add' ? `/keystone/section/add_team_instructor/` : `/keystone/section/delete_team_instructor/`

      try {
        const res = await this.$http.post(
          `${urlAction}`,
          sendSetTeam,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if ([200].includes(res.status)) {
          // console.log(res)
          // let teamInstructor = [];
          let team_instructor = [];
          let arrSlotUp = [];
          // findIndex main load
          const index = this.itemsAll.findIndex(
            (list) => parseInt(list.sectionId) === parseInt(this.dataModalTeam.itemInView.sectionId)
          );
          // update
          if(actionConfig === 'add'){
            this.itemsAll[index].team_instructor.push(res.data)
          }
          else{
            const indexDel = this.itemsAll[index].team_instructor.findIndex(
              (del) => `${del.instructor_code}` === `${this.modelInstructor}`
            );
            this.itemsAll[index].team_instructor.splice(indexDel,1);
          }
          this.itemsNameInstructor = []
          this.loadinSubmitTeam = false
          this.dialogConfig = false
        }else{
          throw { addTeam }
        }
      } catch (error) {
        this.loadinSubmitTeam = false
        this.errorTeamSubmit = `Error :: ${error.response.data.msg}, ${urlAction} instructor to team path: /keystone/section/add_team_instructor/`
      }
    },
    
  },
  computed: {
    changeData() {
      const { selectedYear } = this;
      return {
        selectedYear,
      };
    },
    searchInstructorFunction() {
      const { searchInstructor } = this;
      return {
        searchInstructor,
      };
    },
  },
  watch: {
    changeData: {
      handler: function (val) {
        // ks api
        if (this.$store.state.role.RulesUser.server_status === "on") {
          this.getCourseInTerm();
        }
      },
      deep: true,
    },
    selectedYear(val) {
      this.selectedTerm = val;
    },
    groupTerm(val) {
      this.terms = [];
      this.terms.push(
        {
          tab: 0,
          id: 1,
          text: "Term 1",
          disabled: val.includes(1),
        },
        {
          tab: 1,
          id: 2,
          text: "Term 2",
          disabled: val.includes(2),
        },
        {
          tab: 2,
          id: 3,
          text: "Term 3",
          disabled: val.includes(3),
        },
        {
          tab: 3,
          id: 4,
          text: "Term Summer",
          disabled: val.includes(4),
        }
      );
    },
    selectedTerm(val) {
      this.selectedSection = null;
      this.itemsLoad = this.groupByTerm(
        this.hideRejectsection(this.itemsAll),
        parseInt(val + 1)
      );
      // set detail in couser view
      this.sections = this.groupByTerm(
        this.hideRejectsection(this.itemsAll),
        parseInt(val + 1)
      );
    },
    errorMsgCallData() {
      this.snackbarError = true;
    },
    selectedTab(val) {
      if (val == 1) {
        this.getTimesolt(this.courseIdGetSolt, "exam");
        // console.log("call api Exam" + this.courseIdGetSolt);
      } else if (val == 2) {
        this.getTimesolt(this.courseIdGetSolt, "timesolt");
      } else if (val == 3) {
        this.getStudent(this.courseIdGetSolt);
      } else if (val == 4) {
        // this.getWithDrawRequest(this.courseIdGetSolt);
      } else if (val == 5) {
        this.getTeachingLog(this.courseIdGetSolt);
      }
    },
    courseIdGetSolt(val) {
      this.getTimesolt(val, "Announcement");
      this.getClassGroup(val);
      this.getWithDrawRequest(val);
    },
    switchShow() {
      this.selectedSection = null;
      this.itemsLoad = this.groupByTerm(
        this.hideRejectsection(this.itemsAll),
        parseInt(this.selectedTerm + 1)
      );
      this.sections = this.groupByTerm(
        this.hideRejectsection(this.itemsAll),
        parseInt(this.selectedTerm + 1)
      );
    },
    // Typeahead search
    searchInstructorFunction: {
      handler: async function(val) {
          // console.log("new", val);
          if(![null,'xxxxxxxxx','','null'].includes(val.searchInstructor) && val.searchInstructor.length >= 3){
            this.TypingInstructorSearch(val.searchInstructor)
          }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.f-16 {
  font-size: 16px;
}
.f-10 {
  font-size: 10px;
}
</style>
