<template>
  <div>
    <va-layout style="margin-top: 50px">
      <v-app-bar slot="app-bar" class="" fixed color="blue darken-4" dense dark>
        <v-app-bar-nav-icon>
          <v-icon
            @click="
              $vuetify.breakpoint.lgAndUp ? (mini = !mini) : (drawer = !drawer)
            "
            >mdi-menu</v-icon
          >
        </v-app-bar-nav-icon>

        <v-toolbar-title>SKY+ Instructor</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-title>
          <div v-if="$store.state.role.RulesUser.permissions !== ''">
            <v-chip
              class="ma-2 text-capitalize"
              color="pink"
              label
              text-color="white"
              small
            >
              <v-icon left> mdi-label </v-icon>
              {{
                $store.state.role.RulesUser.permissions !== "fulltime" &&
                $store.state.role.RulesUser.permissions !== "parttime"
                  ? "Please contact OAA Staff."
                  : `${$store.state.role.RulesUser.permissions}`
              }}
            </v-chip>
          </div>
        </v-toolbar-title>
        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar
                v-if="user.image === '_noImage'"
                color="grey lighten-2"
                size="35"
              >
                <span class="black--text text-h5">{{
                  $keycloak.fullName.substring(0, 2)
                }}</span>
              </v-avatar>
              <v-avatar v-else size="35">
                <img
                  :src="user.image"
                  alt="muicProfile"
                  @error="imageLoadError"
                />
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar v-if="user.image === '_noImage'" color="brown">
                  <span class="white--text text-h5">{{
                    $keycloak.fullName.substring(0, 2)
                  }}</span>
                </v-avatar>
                <v-avatar v-else size="45">
                  <img
                    :src="user.image"
                    alt="muicProfile"
                    @error="imageLoadError"
                  />
                </v-avatar>
                <h3 class="mt-2 mb-1">{{ $keycloak.fullName }}</h3>
                <p class="text-caption mt-1">
                  {{ $keycloak.tokenParsed.email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  class="red--text"
                  :href="$keycloak.createLogoutUrl()"
                >
                  Log out
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-app-bar>
      <!-- <pre>{{ $keycloak }}</pre> -->
      <!--  
        .userName
        .fullName
        .token
        .tokenParsed
        .idTokenParsed
        .refreshToken
      -->
      <!-- <pre>{{ sidebarMenu }}</pre> -->
      <v-navigation-drawer
        v-if="this.$store.state.role.RulesUser.server_status === 'on'"
        v-model="drawer"
        :menu="sidebarMenu"
        :mini-variant="mini"
        style="margin-top: 49px"
        slot="app-bar"
        app
      >
        <v-list nav dense>
          <v-subheader v-if="!mini">Menu For Instructor</v-subheader>
          <v-list-item
            v-for="item in sidebarMenu"
            :key="'M' + item.text"
            link
            :to="
              ![
                'Teaching Evaluation',
                'Grade Input',
                'Teaching record',
                'Open Section',
                'IC Service',
              ].includes(item.text)
                ? item.link
                : ''
            "
            :target="
              [
                'Teaching Evaluation',
                'Grade Input',
                'Teaching record',
                'Open Section',
                'IC Service',
              ].includes(item.text)
                ? '_blank'
                : ''
            "
            :href="
              [
                'Teaching Evaluation',
                'Grade Input',
                'Teaching record',
                'Open Section',
                'IC Service',
              ].includes(item.text)
                ? item.link
                : ''
            "
          >
            <v-list-item-icon class="mr-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-space-between">
                <div class="mr-2">{{ item.text }}</div>
                <div
                  v-if="
                    [
                      'Teaching Evaluation',
                      'Grade Input',
                      'Teaching record',
                      'Open Section',
                      'IC Service',
                    ].includes(item.text)
                  "
                >
                  <v-icon small class="">mdi-open-in-new</v-icon>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <!-- 
        <va-app-bar
          slot="app-bar"
          :header-menu="headerMenu"
          :profile-menu="profileMenu"
          disableReload
          dense
          dark
          color="blue darken-4"
          @toggle="
            $vuetify.breakpoint.lgAndUp ? (mini = !mini) : (drawer = !drawer)
          "
        >
        </va-app-bar> 
      -->
      <!-- 
        <va-sidebar
          style="margin-top: 50px"
          slot="sidebar"
          v-model="drawer"
          :menu="sidebarMenu"
          :mini-variant="mini"
        >
        </va-sidebar> 
      -->
      <!-- breadcrumbs -->
      <template slot="header">
        <!-- {{ this.$route.name }} -->
        <!-- {{ this.$route.params.stduentName }} -->
        <div v-if="this.$route.name === 'adviseesMore'">
          <v-breadcrumbs :items="items">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :to="item.href"
                class="text-subtitle-2 crumb-item"
                :disabled="item.disabled"
                exact
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
        <div v-else>
          <va-breadcrumbs
            v-if="this.$store.state.role.RulesUser.server_status === 'on'"
            class="mt-0 mb-2"
            slot="header"
          ></va-breadcrumbs>
        </div>
      </template>
      <!-- breadcrumbs -->
      <!-- <va-aside slot="aside"></va-aside> -->
      <va-footer slot="footer" :menu="footerMenu">
        <strong>
          &copy; 2021,
          <v-icon size="18"> mdi-xml </v-icon>
          with
          <v-icon size="18"> mdi-heart </v-icon>
          by
          <a href="https://muic.mahidol.ac.th/eng/"
            >Mahidol University International College</a
          >
          for a better web.
        </strong>
      </va-footer>
    </va-layout>
  </div>
</template>

<script>
import defaultImg from "@/assets/default-avatar.png";
import nav from "../_nav";
/* eslint-disable no-unused-vars */
export default {
  name: "App",
  data() {
    return {
      user: {
        image: null,
      },
      drawer: null,
      mini: false,
      headerMenu: [
        // {
        //   link: "/",
        //   text: this.$t("menu.dashboard"),
        // },
      ],
      footerMenu: [
        {
          href: "https://sky.muic.mahidol.ac.th/1.5/users/sign_in",
          text: "SKY 1.5",
        },
        {
          href: "https://os.muic.io/",
          text: "OPEN SECTION",
        },
        {
          href: "https://newmy.muic.io/dashboard",
          text: "MY MUIC",
        },
      ],
      profileMenu: [],
      sidebarMenu: nav(this.$i18n, this.$admin, this.$store.state.role.RulesUser.RolePdPCChair),
    };
  },
  computed: {
    items() {
      return [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "My Advisees",
          disabled: false,
          href: "/advisees",
        },
        {
          text: `( ${this.$route.params.codeid} ) ${this.$route.params.stduentName}`,
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ];
    },
  },
  mounted() {
    this.getUserProfile();
  },
  methods: {
    ImageProfile(val) {
      return val !== null && val !== "null" ? val : "_noImage";
    },
    imageLoadError(event) {
      event.target.src = defaultImg;
    },
    async getUserProfile() {
      try {
        const res = this.$http
          .get(`https://my.muic.io/api/portal/profile/get_img/`, {
            responseType: "blob",
          })
          .then((res) => {
            if (res.status === 200) {
              let blob = new Blob([res.data]);
              return (this.user.image = this.ImageProfile(
                window.URL.createObjectURL(blob)
              ));
            } else if (res.status === 204) {
              this.user.image = "_noImage";
            } else {
              throw { res };
            }
          });
      } catch (error) {
        this.user.image = "_noImage";
      }
    },
  },
};
</script>
