<template>
  <div>
    <!-- <pre>{{ itemDetailForStd[0].termText }}</pre> -->
    <!-- <pre>{{ itemDetailForStd[0].mainInstructor }}</pre> -->
    <!-- <pre>{{ itemDetailForStd[0].course.courseCode }}</pre> -->
    <v-row justify="space-between">
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          label="Filter studentID"
          small
          dense
          outlined
          prepend-inner-icon="mdi-account-search-outline"
          class="mt-2 mb-0"
          type="text"
          v-model="search"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="text-right">
        <v-btn
          @click="print"
          color="light-blue darken-4"
          class="ma-1 white--text font-weight-bold"
        >
          <v-icon class="mr-2"> mdi-printer </v-icon> .Pdf
        </v-btn>
        <v-btn
          @click="exportDataStudent"
          color="light-blue darken-4"
          class="ma-1 white--text font-weight-bold"
        >
          <v-icon class="mr-2"> mdi-printer </v-icon> Excel
        </v-btn>
        <br />
        <small class="text--disabled">report student list in course.</small>
      </v-col>
    </v-row>
    <!--  
      :sort-by="['studentCode', 'StudentName', 'paid']"
      :sort-desc="[false, true]"
    -->
    <!-- <pre>{{ itemListUrlImage }}</pre> -->
    <v-data-table
      v-if="!loadingStd"
      :headers="headers"
      :items="getStudentList"
      item-key="nameStudentSearch"
      no-data-text="No student list"
      class="elevation-1"
      disable-pagination
      hide-default-footer
      :custom-sort="customSort"
      :search="search"
      dense
    >
      <template #top>
        <v-toolbar elevation="0">
          <v-switch
            v-model="showPicture"
            label="Show Picture"
            class="mr-10"
          ></v-switch>
          <v-switch
            v-model="showEdu"
            label="Show @student.mahidol.edu"
            class="mr-10"
          ></v-switch>
        </v-toolbar>
      </template>
      <!-- header -->
      <template v-slot:header.studentCode="{ header }">
        <span style="font-size: 12px">{{ header.text }}</span>
      </template>
      <template #item.no="{ item, index }"
        ><p class="f-300 mt-0 mb-0">{{ index + 1 }}</p></template
      >
      <template #item.image="{ item }">
        <!-- {{ itemListUrlImage[0][item.studentCode] }} -->
        <!-- <img
          v-if="showPicture"
          class="mt-2 mb-0"
          style="height: 70px"
          :src="
            'https://icapp.muic.mahidol.ac.th/source/pic/' +
            item.studentCode +
            '.jpg'
          "
          @error="imageLoadError($event, item.studentCode)"
        /> -->
        <!-- 'https://ks.muic.io/uploaded/' + item.studentCode + '.jpg' -->
        <div v-if="itemListUrlImage.length != 0">
          <img
            v-if="showPicture"
            class="mt-2 mb-0"
            style="height: 70px"
            :src="itemListUrlImage[0][item.studentCode]"
            @error="imageLoadError($event, item.studentCode)"
          />
        </div>
      </template>
      <template #item.division="{ item, index }">
        <p class="f-300 mt-0 mb-0">{{ item.faculty.shortNameEn }}</p>
      </template>
      <template #item.CourseCode="{ item }"
        ><p class="f-300 mt-0 mb-0">
          {{ item.courseCode }}
        </p></template
      >
      <template #item.studentCode="{ item }"
        ><p class="f-300 mt-0 mb-0">
          {{ item.studentCode }}
        </p></template
      >
      <!-- <template #item.Title="{ item }"
        ><p class="f-300 mt-0 mb-0">{{ item.titleEn }}</p></template
      > -->
      <template #item.StudentName="{ item }"
        ><p class="f-300 mt-0 mb-0">
          {{ item.titleEn }} {{ item.nameEn }} {{ item.midNameEn }}
          {{ item.lastNameEn }}
        </p></template
      >
      <template #item.StudentMajor="{ item }"
        ><p class="f-300 mt-0 mb-0">
          {{ item.department.departmentCode }}
        </p></template
      >
      <template #item.email="{ item }">
        <v-list-item class="pa-0" two-line>
          <v-list-item-content>
            <v-list-item-subtitle>.ac.th</v-list-item-subtitle>
            <v-list-item-title class="f-300">{{
              item.email !== null
                ? item.email
                : `u${item.studentCode}@student.mahidol.ac.th`
            }}</v-list-item-title>
            <div
              v-if="
                item.eduMailMUIC.edu_mail !== null &&
                item.eduMailMUIC.edu_mail !== '' &&
                showEdu
              "
            >
              <!-- <pre>{{ item.eduMailMUIC }}</pre> -->
              <v-list-item-subtitle>.edu</v-list-item-subtitle>
              <v-list-item-title class="f-300">{{
                item.eduMailMUIC.edu_mail
              }}</v-list-item-title>
              <!--  
                <v-list-item-subtitle class="mt-2 mb-1"
                  >Phone</v-list-item-subtitle
                >
                <v-list-item-title class="f-300">{{
                  item.eduMailMUIC.tel
                }}</v-list-item-title>
              -->
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #item.paid="{ item }"
        ><v-chip
          small
          label
          dark
          :class="item.isPaid ? 'white--text' : 'black--text'"
          :color="item.isPaid ? 'green darken-1' : 'blue-grey lighten-5'"
        >
          {{ item.isPaid ? "Paid" : "Unpaid" }}
        </v-chip></template
      >
      <template #item.withdraw="{ item }">
        <v-chip
          v-if="item.withdraw === 'Yes'"
          class="ma-2"
          small
          label
          dark
          color="teal darken-3"
        >
          <span class="text-h6 text-capitalize font-weight-bold">
            WITHDRAWN
          </span>
        </v-chip>
      </template>
    </v-data-table>
    <!-- pdf genarator -->
    <div id="printMe" style="display: none">
      <v-row class="pa-3">
        <v-col md="8">
          <p class="mt-5 mb-0 font-weight-bold">
            International College, Mahidol University
          </p>
          <p class="mt-0 mb-0">
            Class List with Paid Status for Instructor :
            {{
              `${itemDetailForStd[0].mainInstructor.title} ${itemDetailForStd[0].mainInstructor.name} ${itemDetailForStd[0].mainInstructor.lastName}`
            }}
          </p>
          <p class="mt-0 mb-0">{{ itemDetailForStd[0].termText }}</p>
          <p class="mt-0 mb-0">
            {{
              `${itemDetailForStd[0].course.courseCode} ${itemDetailForStd[0].course.courseNameEn} Section ${itemDetailForStd[0].sectionNumber}`
            }}
          </p>
        </v-col>
        <v-col align="end" md="4">
          <p class="mt-5 mb-0">
            Generated: {{ moment().format("DD-MM-YYYY") }}
            {{ moment().format("LTS") }}
          </p>
          <p class="mt-0 mb-0">(ATTENDANCE FORM)</p>
          <p class="mt-0 mb-0">
            {{
              `Credit ${itemDetailForStd[0].course.courseCredit}(${itemDetailForStd[0].course.lecture}-${itemDetailForStd[0].course.lab}-${itemDetailForStd[0].course.other})`
            }}
          </p>
        </v-col>
      </v-row>
      <div class="mt-5">
        <table style="width: 100%">
          <thead>
            <tr>
              <th
                width="5%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                No.
              </th>
              <th style="border: 2px solid #000000" class="text-center">
                StudentID
              </th>
              <th style="border: 2px solid #000000" class="text-center">
                Major
              </th>
              <th style="border: 2px solid #000000" class="text-left">
                Student Name
              </th>
              <th style="border: 2px solid #000000" class="text-left">
                Status
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                1
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                2
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                3
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                4
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                5
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                6
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                7
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                8
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                9
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                10
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                11
              </th>
              <th
                width="3%"
                style="border: 2px solid #000000"
                class="text-center"
              >
                12
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="trprt"
              v-for="(item, index) in itemPrintSort.length !== 0
                ? itemPrintSort
                : itemStudent[0].studentList"
              :key="'record' + index"
            >
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-left: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="text-center tdprt"
              >
                {{ index + 1 }}
              </td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="text-center tdprt"
              >
                {{ item.studentCode }}
              </td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="text-center tdprt"
              >
                {{ item.department.departmentCode }} | {{ item.courseCode }}
              </td>
              <td
                style="
                  padding: 10px;
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              >
                <img
                  v-if="showPicture"
                  class="mt-2 mb-0"
                  style="height: 70px"
                  :src="itemListUrlImage[0][item.studentCode]"
                  @error="imageLoadError($event, item.studentCode)"
                />
                {{
                  `${item.titleEn} ${item.nameEn}${
                    item.midNameEn !== null && item.midNameEn !== "null"
                      ? ` ${item.midNameEn} `
                      : " "
                  }${item.lastNameEn}`
                }}
              </td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt text-center"
              >
                {{ item.isPaid ? "Paid" : "Unpaid" }}
              </td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
              <td
                style="
                  border-bottom: 2px solid #000000;
                  border-right: 2px solid #000000;
                "
                class="tdprt"
              ></td>
            </tr>
          </tbody>
        </table>
        <div class="text-h6 mt-2 mb-0 font-weight-bold">Status</div>
        <p class="mt-0 mb-0">Paid - Students have paid their tuition fees.</p>
        <p class="mt-0 mb-0">
          *Paid - Students with special conditions. Unlike paid, student must
          clear these conditions before taking exam.
        </p>
        <p class="mt-0 mb-0">
          Unpaid - Students have NOT paid their fees. They will not be allowed
          to sit in the classes and examinations.
        </p>
        <p class="mt-0 mb-0">
          Reserved - Students did not complete the registration process
          correctly on SKY, and therefore they cannot attend class.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from "@/assets/default-avatar.png";
export default {
  computed: {
    headers() {
      return [
        {
          sortable: false,
          text: "",
          value: "no",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "",
          value: "image",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Division",
          value: "division",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Course Code",
          value: "CourseCode",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Major",
          value: "StudentMajor",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: true,
          text: "StudentID",
          value: "studentCode",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
          // filter: (f) => {
          //   return (f + "")
          //     .toLowerCase()
          //     .includes(this["idStudentSearch"].toLowerCase());
          // },
        },
        // {
        //   sortable: false,
        //   text: "Title",
        //   value: "Title",
        //   align: "center",
        //   class:
        //     "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        // },
        {
          sortable: true,
          text: "Name",
          value: "StudentName",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Contact",
          value: "email",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Paid status",
          value: "paid",
          align: "center",
          class:
            "font-weight-bold black--text text-center text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "",
          value: "withdraw",
          align: "center",
          class:
            "font-weight-bold black--text text-center text-h5 blue-grey lighten-5",
        },
      ];
    },
    getStudentList() {
      if (this.itemStudent[0].studentList.length != 0) {
        // console.log(this.itemStudent[0].studentList)

        const send = new FormData();
        let arr = [];
        // var jsonBody = {};

        for (let l = 0; l < this.itemStudent[0].studentList.length; l++) {
          // arr.push(this.itemStudent[0].studentList[l].studentCode);
          send.append(
            "student",
            this.itemStudent[0].studentList[l].studentCode
          );
        }
        // console.log(arr)
        // jsonBody["student"] = arr;
        this.getImageUrl(send);
        // console.log(jsonBody);

        this.itemStudent[0].studentList.sort(function (a, b) {
          var nameA = a.studentCode.toUpperCase();
          var nameB = b.studentCode.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        return this.itemStudent[0].studentList.map((item) => ({
          ...item,
          StudentName: `${item.nameEn} ${item.midNameEn} ${item.lastNameEn}`,
          paid: item.isPaid ? "Paid" : "Unpaid",
          // ima: this.getImageUrl(item.studentCode),
        }));
      } else {
        return [];
      }
    },
  },
  props: {
    itemDetailForStd: {
      type: Array,
      default: () => [],
    },
    itemStudent: {
      type: Array,
      default: () => [],
    },
    loadingStd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingExport: false,
      showPicture: true,
      nameStudentSearch: "",
      idStudentSearch: "",
      header_data: [
        {
          A: "No.",
          B: "StudentID",
          C: "Major",
          D: "",
          E: "Title",
          F: "Name",
          G: "MiddleName",
          H: "LastName",
          I: "Email (.ac.th)",
          J: "Email (.edu)",
          K: "Paid Status",
          L: "Withdrawal Status",
          M: "1",
          N: "2",
          O: "3",
          P: "4",
          Q: "5",
          R: "6",
          S: "7",
          T: "8",
          U: "9",
          V: "10",
          W: "11",
          X: "12",
          // Y: "12",
        },
      ],
      dataExportExcel: [],
      arrSortA: [],
      isSort: "",
      search: "",
      itemPrintSort: [],
      showEdu: true,
      itemListUrlImage: [],
    };
  },
  mounted() {},
  methods: {
    async getImageUrl(idStd) {
      this.itemListUrlImage = [];
      try {
        const resPhoto = await this.$http.post("/group_presigned_url/", idStd, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (resPhoto.status === 200) {
          var jsonData = {};
          resPhoto.data.forEach(function (column) {
            var columnName = column.student;
            jsonData[columnName] = column.img_url;
          });
          this.itemListUrlImage.push(jsonData);
          // console.log(this.itemListUrlImage);
          // return resPhoto.data
        } else {
          throw resPhoto;
        }
      } catch (error) {}
    },
    customSort(items, index, isDescending) {
      this.arrSortA = [];
      this.itemPrintSort = [];
      this.isSort = isDescending;
      items.sort((a, b) => {
        if (isDescending != "false") {
          return a[index] < b[index] ? -1 : 1;
        } else {
          return b[index] < a[index] ? -1 : 1;
        }
      });
      this.arrSortA.push(index[0]);
      this.itemPrintSort = items;
      return items;
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },
    imageLoadError(event, id) {
      event.target.src = defaultImg;
    },
    exportDataStudent() {
      // export true เรียงจาก a-z น้อยไปมาก false : z-a มากไปน้อย
      // arrSortA: [],
      // isSort: "",
      let a = 1;
      this.loadingExport = true;
      this.dataExportExcel = [];
      if (this.itemPrintSort.length !== 0) {
        this.dataExportExcel = this.itemPrintSort.map((item) => {
          return {
            A: a++,
            B: item.studentCode,
            C: `${item.department.departmentCode}`,
            D: `${item.courseCode}`,
            E: item.titleEn !== null ? item.titleEn : "",
            F: item.nameEn !== null ? item.nameEn : "",
            G: item.midNameEn !== null ? item.midNameEn : "",
            H: item.lastNameEn !== null ? item.lastNameEn : "",
            I:
              item.email !== null
                ? item.email
                : `u${item.studentCode}@student.mahidol.ac.th`,
            J:
              item.eduMailMUIC.edu_mail !== null &&
              item.eduMailMUIC.edu_mail !== ""
                ? item.eduMailMUIC.edu_mail
                : "",
            // K:
            //   item.eduMailMUIC.edu_mail !== null &&
            //   item.eduMailMUIC.edu_mail !== ""
            //     ? item.eduMailMUIC.tel
            //     : "",
            K: item.isPaid ? "Paid" : "Unpaid",
            L: item.withdraw,
            M: "",
            N: "",
            O: "",
            P: "",
            Q: "",
            R: "",
            S: "",
            T: "",
            U: "",
            V: "",
            W: "",
            X: "",
            // Y: "",
          };
        });
      } else {
        this.dataExportExcel = this.itemStudent[0].studentList.map((item) => {
          return {
            A: a++,
            B: item.studentCode,
            C: `${item.department.departmentCode}`,
            D: `${item.courseCode}`,
            E: item.titleEn !== null ? item.titleEn : "",
            F: item.nameEn !== null ? item.nameEn : "",
            G: item.midNameEn !== null ? item.midNameEn : "",
            H: item.lastNameEn !== null ? item.lastNameEn : "",
            I:
              item.email !== null
                ? item.email
                : `u${item.studentCode}@student.mahidol.ac.th`,
            J:
              item.eduMailMUIC.edu_mail !== null &&
              item.eduMailMUIC.edu_mail !== ""
                ? item.eduMailMUIC.edu_mail
                : "",
            // K:
            //   item.eduMailMUIC.edu_mail !== null &&
            //   item.eduMailMUIC.edu_mail !== ""
            //     ? item.eduMailMUIC.tel
            //     : "",
            K: item.isPaid ? "Paid" : "Unpaid",
            L: item.withdraw,
            M: "",
            N: "",
            O: "",
            P: "",
            Q: "",
            R: "",
            S: "",
            T: "",
            U: "",
            V: "",
            W: "",
            X: "",
            // Y: "",
          };
        });
      }
      // console.log(this.dataExportExcel);
      const SummaryWS = this.XLSX.utils.json_to_sheet(
        this.header_data.concat(this.dataExportExcel),
        {
          header: [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
          ],
          skipHeader: true,
        }
      );
      const wb = this.XLSX.utils.book_new();
      let fileName = `students_${
        this.itemDetailForStd[0].course.courseCode
      }_${this.itemDetailForStd[0].termText.replace("/", "_")}.xlsx`;
      this.XLSX.utils.book_append_sheet(wb, SummaryWS, "StudentList");
      this.XLSX.writeFile(wb, fileName);
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>
.f-300 {
  font-weight: 500;
}
</style>