<template>
  <div>
    <v-row v-if="!loadingProfile" class="mb-5">
      <v-col cols="12" sm="12" md="4">
        <!-- <pre>{{ dataProfile[0].profile }}</pre> -->
        <profile
          v-if="dataProfile.length != 0"
          :dataProfile="dataProfile[0].profile"
          :contract="dataProfile[0].contract"
          :rank="dataProfile[0].rank"
        />
        <div v-else>
          <v-alert dense border="left" type="warning">
            The <strong>contract settings</strong> were not found. and teaching
            compensation
          </v-alert>
        </div>
      </v-col>
      <v-col v-if="dataProfile.length != 0" cols="12" sm="12" md="8">
        <!-- <pre>{{ dataProfile[0].contract }}</pre> -->
        <!-- <pre>{{ dataProfile[0].rank }}</pre> -->
        <!--  
          <v-card class="pa-0" outlined tile>
            <attributeprofile
              :contract="dataProfile[0].contract"
              :rank="dataProfile[0].rank"
            />
          </v-card>
        -->
      </v-col>
    </v-row>
    <div v-else>
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col
          class="mt-2 mb-0 font-weight-bold text-subtitle-1 text-center"
          cols="12"
        >
          Getting your <strong>Contract and Rank</strong> profile
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="light-blue darken-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <!-- error main page -->
    <v-snackbar
      absolute
      top
      color="red darken-4"
      v-model="snackbarProfile"
      v-if="errorMsgProfile !== null"
    >
      <strong>{{ errorMsgProfile }}</strong>
    </v-snackbar>
  </div>
</template>

<script>
import profile from "./dashboard/profileoverview.vue";
import attributeprofile from "./dashboard/attributeprofile.vue";
export default {
  components: {
    profile,
    attributeprofile,
  },
  data() {
    return {
      snackbarProfile: false,
      errorMsgProfile: null,
      dataProfile: [],
      loadingProfile: false,
    };
  },
  mounted() {
    this.getProfileContract();
  },
  methods: {
    async getProfileContract() {
      this.loadingProfile = true;
      this.dataProfile = [];
      try {
        const resProfile = await this.$http.get(`/teaching/contract/me/`);
        if (resProfile.status === 200) {
          this.dataProfile.push(resProfile.data);
          this.loadingProfile = false;
        } else {
          throw { resProfile };
        }
      } catch (error) {
        this.dataProfile = [];
        this.snackbarProfile = true;
        this.loadingProfile = false;
        this.errorMsgProfile = `Caught problem api /teaching/contract/me/ process. Pleses refresh the page and try again.`;
      }
    },
  },
};
</script>