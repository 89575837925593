import Vue from "vue";
import AdminLayout from "@/layouts/Admin";
import Dashboard from "@/views/Dashboard";
import Section from "@/views/Section";
import Advisees from "@/views/Advisees";
import AdviseesView from "@/views/StudentSearch";
import AdviseesMore from "@/views/advisees/TableDetail";
import Soon from "@/views/comming.vue";
// import Grade from "@/views/Grade";
import Payment from "@/views/Payment";
import Profile from "@/views/About";
import Error from "@/views/Error";
import i18n from "@/i18n";

/**
 * Error component
 */
Vue.component("Error", Error);

export default {
  path: "",
  component: AdminLayout,
  meta: {
    title: i18n.t("routes.home"),
    requiresAuth: true
  },
  children: [
    {
      path: "/section",
      name: "section",
      component: Section,
      meta: {
        title: "Section Management",
        requiresAuth: true
      },
    },
    {
      path: "/payment",
      name: "payment",
      component: Payment,
      meta: {
        title: "Payment summary",
        requiresAuth: true
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        title: "My profile",
        requiresAuth: true
      },
    },
    {
      path: "/advisees",
      name: "advisees",
      component: Advisees,
      meta: {
        title: "My Advisees",
        requiresAuth: true
      },
    },
    {
      path: "/searchstudent",
      name: "searchstudent",
      component: AdviseesView,
      meta: {
        title: "View Student",
        requiresAuth: true
      },
    },
    {
      path: "/advisees/moredetail/:stduentName",
      name: "adviseesMore",
      component: AdviseesMore,
      props: true,
      meta: {
        title: "My Advisees more detail",
        requiresAuth: true
      },
    },
    // {
    //   path: "/grade",
    //   name: "grade",
    //   component: Grade,
    //   meta: {
    //     title: "Grade",
    //     requiresAuth: true
    //   },
    // },
    // dashboard 
    {
      path: "/comingsoon",
      name: "comingsoon",
      component: Soon,
      meta: {
        title: "Coming Soon",
        requiresAuth: true
      },
      // i18n.t("routes.dashboard")
    },
    {
      path: "/overview",
      name: "dashboard",
      component: Dashboard,
      meta: {
        title: "Overview",
        requiresAuth: true
      },
      // i18n.t("routes.dashboard")
    },
    {
      path: "*",
      component: Error,
      meta: {
        title: i18n.t("routes.not_found"),
        requiresAuth: true
      },
    },
  ],
};
