var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loadingWithDraw)?_c('div',[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"mt-2 mb-0 font-weight-bold text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Getting your withdraw request ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"light-blue darken-4","indeterminate":"","rounded":"","height":"6"}})],1)],1)],1):_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listStdRequestWithDraw,"item-key":"withDrawRequest","no-data-text":"No request","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.no",fn:function({ item, index }){return [_c('p',{staticClass:"f-300 mt-0 mb-0"},[_vm._v(_vm._s(index + 1))])]}},{key:"item.image",fn:function({ item }){return [(_vm.showPictureWithDraw)?_c('img',{staticClass:"mt-2 mb-0",staticStyle:{"height":"70px"},attrs:{"src":'https://icapp.muic.mahidol.ac.th/source/pic/' +
            item.code +
            '.jpg'},on:{"error":function($event){return _vm.imageLoadError($event, item.studentCode)}}}):_vm._e()]}},{key:"item.facultyNameEn",fn:function({ item }){return [_c('p',{staticClass:"f-300 mt-0 mb-0"},[_vm._v(" "+_vm._s(item.facultyNameEn)+" ")])]}},{key:"item.code",fn:function({ item }){return [_c('p',{staticClass:"f-300 mt-0 mb-0"},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.StudentName",fn:function({ item }){return [_c('p',{staticClass:"f-300 mt-0 mb-0"},[_vm._v(" "+_vm._s(item.firstNameEn)+" "+_vm._s(item.lastNameEn)+" ")])]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":"","dark":"","color":item.status === 'REJECTED'
              ? 'red darken-3'
              : item.status === 'WITHDRAWN'
              ? 'teal darken-3'
              : item.status === 'PENDING'
              ? 'yellow darken-3'
              : 'grey darken-4'}},[_c('span',{staticClass:"text-h6 text-capitalize font-weight-bold"},[_vm._v(" "+_vm._s(item.status !== "" && item.status !== null ? item.status : "N/A")+" ")])])]}},{key:"item.remark",fn:function({ item }){return [(item.reason !== null && item.reason !== '')?_c('div',[_c('p',{staticClass:"text-justify mt-2 mb-2 text-r"},[_vm._v(" "+_vm._s(item.reason.split("\n\n").length !== 0 ? item.reason .split("\n\n")[0] .replace("Student Remark:\n", "") : "")+" ")])]):_vm._e()]}},{key:"item.action",fn:function({ item }){return [(
            item.status === null ||
            item.status === '' ||
            item.status === 'PENDING'
          )?_c('v-btn-toggle',{staticClass:"text-h6 font-weight-bold",attrs:{"dense":""},model:{value:(_vm.valueSettingWithDraw[item.id]),callback:function ($$v) {_vm.$set(_vm.valueSettingWithDraw, item.id, $$v)},expression:"valueSettingWithDraw[item.id]"}},[_c('v-btn',{staticClass:"font-weight-bold text-capitalize",attrs:{"elevation":"2","dark":"","small":"","color":"teal darken-3","value":"Approve"},on:{"click":function($event){return _vm.opendialogWithDraw({
                id: item.id,
                status: 'WITHDRAWN',
                code: item.code,
                stdName: `${item.firstNameEn} ${item.lastNameEn}`,
                Major: `${item.facultyNameEn}`,
                imageAV: item.cardImageUrl,
              })}}},[_vm._v(" Approve ")]),_c('v-btn',{staticClass:"font-weight-bold text-capitalize",attrs:{"elevation":"2","dark":"","small":"","color":"red","value":"Reject"},on:{"click":function($event){return _vm.opendialogWithDraw({
                id: item.id,
                status: 'REJECTED',
                code: item.code,
                stdName: `${item.firstNameEn} ${item.lastNameEn}`,
                Major: `${item.facultyNameEn}`,
                imageAV: item.cardImageUrl,
              })}}},[_vm._v(" Reject ")])],1):_vm._e()]}}])}),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","width":"500"},model:{value:(_vm.dialogCheck),callback:function ($$v) {_vm.dialogCheck=$$v},expression:"dialogCheck"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h3 mt-0 mb-2 font-weight-bold pa-2",class:{
            'teal darken-3': _vm.objDataCase.status === 'WITHDRAWN',
            'red darken-3': _vm.objDataCase.status === 'REJECTED',
          }},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.objDataCase.status === "WITHDRAWN" ? "Withdrawal" : "Reject")+" Request")])]),_c('v-card-text',[_c('v-list-item',{staticClass:"mb-2",attrs:{"three-line":""}},[_c('v-list-item-avatar',{staticStyle:{"height":"70px"},attrs:{"size":"50","tile":""}},[_c('v-img',{attrs:{"src":_vm.objDataCase.imageAV}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"f-300 text-h5"},[_c('span',{staticClass:"text--disabled mr-2"},[_vm._v("Code:")]),_vm._v(_vm._s(_vm.objDataCase.code))]),_c('v-list-item-title',{staticClass:"f-300 text-h5"},[_c('span',{staticClass:"text--disabled mr-2"},[_vm._v("Name:")]),_vm._v(_vm._s(_vm.objDataCase.stdName))]),_c('v-list-item-subtitle',{staticClass:"f-300 black--text"},[_c('span',{staticClass:"text--disabled mr-2"},[_vm._v("Division:")]),_vm._v(_vm._s(_vm.objDataCase.Major)+" ")])],1)],1),_c('v-textarea',{attrs:{"outlined":"","name":"inputRemark","dense":"","label":"Remark"},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold mb-1",attrs:{"color":"light-blue darken-4","dense":"","dark":!_vm.loadingSubmitWithDraw,"disabled":_vm.loadingSubmitWithDraw},on:{"click":_vm.submitWithDrawCheck}},[(_vm.loadingSubmitWithDraw)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"size":20,"color":"white","indeterminate":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.objDataCase.status === "WITHDRAWN" ? "Approve" : "Reject")+" ")],1),_c('v-btn',{staticClass:"font-weight-bold mb-1",attrs:{"color":"","dense":"","dark":""},on:{"click":_vm.closeDialogWithDraw}},[_vm._v(" Cancel ")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }