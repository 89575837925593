<template>
  <div>
    <div v-if="loadingWithDraw">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col
          class="mt-2 mb-0 font-weight-bold text-subtitle-1 text-center"
          cols="12"
        >
          Getting your withdraw request
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="light-blue darken-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <!--  
        <p class="mt-0 mb-2">
          Join sectionId :
          <span v-if="dataWithdrawl[0].jointSections.length != 0">{{
            dataWithdrawl[0].jointSections
          }}</span>
        </p>
      -->
      <!-- ID : {{ WithDrawSectionId }} -->
      <v-data-table
        :headers="headers"
        :items="listStdRequestWithDraw"
        item-key="withDrawRequest"
        no-data-text="No request"
        class="elevation-1"
        disable-pagination
        hide-default-footer
      >
        <template #item.no="{ item, index }"
          ><p class="f-300 mt-0 mb-0">{{ index + 1 }}</p></template
        >
        <template #item.image="{ item }">
          <img
            v-if="showPictureWithDraw"
            class="mt-2 mb-0"
            style="height: 70px"
            :src="
              'https://icapp.muic.mahidol.ac.th/source/pic/' +
              item.code +
              '.jpg'
            "
            @error="imageLoadError($event, item.studentCode)"
          />
        </template>
        <template #item.facultyNameEn="{ item }">
          <p class="f-300 mt-0 mb-0">
            {{ item.facultyNameEn }}
          </p>
        </template>
        <template #item.code="{ item }"
          ><p class="f-300 mt-0 mb-0">
            {{ item.code }}
          </p></template
        >
        <template #item.StudentName="{ item }"
          ><p class="f-300 mt-0 mb-0">
            {{ item.firstNameEn }} {{ item.lastNameEn }}
          </p></template
        >
        <template #item.status="{ item }">
          <v-chip
            class="ma-2"
            small
            label
            dark
            :color="
              item.status === 'REJECTED'
                ? 'red darken-3'
                : item.status === 'WITHDRAWN'
                ? 'teal darken-3'
                : item.status === 'PENDING'
                ? 'yellow darken-3'
                : 'grey darken-4'
            "
          >
            <span class="text-h6 text-capitalize font-weight-bold">
              {{
                item.status !== "" && item.status !== null ? item.status : "N/A"
              }}
            </span>
          </v-chip>
        </template>
        <template #item.remark="{ item }">
          <div v-if="item.reason !== null && item.reason !== ''">
            <p class="text-justify mt-2 mb-2 text-r">
              {{
                item.reason.split("\n\n").length !== 0
                  ? item.reason
                      .split("\n\n")[0]
                      .replace("Student Remark:\n", "")
                  : ""
              }}
            </p>
          </div>
        </template>
        <template #item.action="{ item }">
          <v-btn-toggle
            v-if="
              item.status === null ||
              item.status === '' ||
              item.status === 'PENDING'
            "
            v-model="valueSettingWithDraw[item.id]"
            class="text-h6 font-weight-bold"
            dense
          >
            <v-btn
              elevation="2"
              class="font-weight-bold text-capitalize"
              dark
              small
              color="teal darken-3"
              value="Approve"
              @click="
                opendialogWithDraw({
                  id: item.id,
                  status: 'WITHDRAWN',
                  code: item.code,
                  stdName: `${item.firstNameEn} ${item.lastNameEn}`,
                  Major: `${item.facultyNameEn}`,
                  imageAV: item.cardImageUrl,
                })
              "
            >
              Approve
            </v-btn>
            <v-btn
              elevation="2"
              class="font-weight-bold text-capitalize"
              dark
              small
              color="red"
              value="Reject"
              @click="
                opendialogWithDraw({
                  id: item.id,
                  status: 'REJECTED',
                  code: item.code,
                  stdName: `${item.firstNameEn} ${item.lastNameEn}`,
                  Major: `${item.facultyNameEn}`,
                  imageAV: item.cardImageUrl,
                })
              "
            >
              Reject
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-data-table>
      <!-- dialog -->
      <v-dialog
        :retain-focus="false"
        persistent
        v-model="dialogCheck"
        width="500"
      >
        <v-card>
          <v-card-title
            :class="{
              'teal darken-3': objDataCase.status === 'WITHDRAWN',
              'red darken-3': objDataCase.status === 'REJECTED',
            }"
            class="text-h3 mt-0 mb-2 font-weight-bold pa-2"
          >
            <span class="white--text"
              >{{
                objDataCase.status === "WITHDRAWN" ? "Withdrawal" : "Reject"
              }}
              Request</span
            >
          </v-card-title>

          <v-card-text>
            <v-list-item class="mb-2" three-line>
              <v-list-item-avatar style="height: 70px" size="50" tile>
                <v-img :src="objDataCase.imageAV"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="f-300 text-h5"
                  ><span class="text--disabled mr-2">Code:</span
                  >{{ objDataCase.code }}</v-list-item-title
                >
                <v-list-item-title class="f-300 text-h5"
                  ><span class="text--disabled mr-2">Name:</span
                  >{{ objDataCase.stdName }}</v-list-item-title
                >
                <v-list-item-subtitle class="f-300 black--text">
                  <span class="text--disabled mr-2">Division:</span
                  >{{ objDataCase.Major }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-textarea
              outlined
              name="inputRemark"
              dense
              label="Remark"
              v-model="remark"
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="font-weight-bold mb-1"
              color="light-blue darken-4"
              dense
              :dark="!loadingSubmitWithDraw"
              :disabled="loadingSubmitWithDraw"
              @click="submitWithDrawCheck"
            >
              <v-progress-circular
                v-if="loadingSubmitWithDraw"
                :size="20"
                color="white"
                indeterminate
                class="mr-2"
              ></v-progress-circular>
              {{ objDataCase.status === "WITHDRAWN" ? "Approve" : "Reject" }}
            </v-btn>
            <v-btn
              @click="closeDialogWithDraw"
              class="font-weight-bold mb-1"
              color=""
              dense
              dark
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import defaultImg from "@/assets/default-avatar.png";
export default {
  props: {
    dataWithdrawl: {
      type: Array,
      default: () => [],
    },
    WithDrawSectionId: {
      type: Number,
      default: 0,
    },
    loadingWithDraw: {
      type: Boolean,
      default: false,
    },
    listStdRequestWithDraw: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valueSettingWithDraw: [],
      objDataCase: {},
      remark: "",
      dialogCheck: false,
      loadingSubmitWithDraw: false,
      showPictureWithDraw: true,
    };
  },
  computed: {
    headers() {
      return [
        {
          sortable: false,
          text: "",
          value: "no",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "",
          value: "image",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: true,
          text: "Division",
          value: "facultyNameEn",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: true,
          text: "Code",
          value: "code",
          align: "center",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: true,
          text: "Name",
          value: "StudentName",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: true,
          text: "Status",
          value: "status",
          align: "center",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Student Remark",
          value: "remark",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          sortable: false,
          text: "Action",
          value: "action",
          align: "center",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
      ];
    },
  },
  methods: {
    imageLoadError(event, id) {
      event.target.src = defaultImg;
    },
    opendialogWithDraw(val) {
      this.objDataCase = Object.assign({}, val);
      this.remark = "";
      this.dialogCheck = true;
    },
    closeDialogWithDraw() {
      this.dialogCheck = false;
      this.objDataCase = Object.assign({}, {});
      this.remark = "";
    },
    async submitWithDrawCheck() {
      this.loadingSubmitWithDraw = true;
      let urlCase =
        this.objDataCase.status === "WITHDRAWN"
          ? `/uspark/withdrawal/${this.WithDrawSectionId}/approve/`
          : `/uspark/withdrawal/${this.WithDrawSectionId}/reject/`;
      try {
        const sendWithDrawStatus = new FormData();
        sendWithDrawStatus.append("studentCode", this.objDataCase.code);
        sendWithDrawStatus.append("reason", this.remark);
        const updateRequest = await this.$http.post(
          `${urlCase}`,
          sendWithDrawStatus,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (updateRequest.status === 200) {
          this.$emit("updateRequestWithDraw", {
            id: this.objDataCase.id,
            status: this.objDataCase.status,
            remark: this.remark,
          });
          this.loadingSubmitWithDraw = false;
          this.closeDialogWithDraw();
        } else {
          throw { updateRequest };
        }
      } catch (error) {
        this.loadingSubmitWithDraw = false;
      }
    },
  },
};
</script>

<style scoped>
.f-300 {
  font-weight: 500;
}
.text-r {
  white-space: pre-wrap;
}
</style>