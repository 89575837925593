var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"mt-3 mb-5",attrs:{"disable-pagination":"","hide-default-footer":"","no-data-text":"No information","loading-text":"loading data student","loading":_vm.loading,"headers":_vm.headersMain,"items":_vm.studentInAdvisees,"search":_vm.search},scopedSlots:_vm._u([{key:"item.no",fn:function({ index, item }){return [_c('p',{staticClass:"mt-0 mb-0 font-weight-bold"},[_vm._v(_vm._s(index + 1))])]}},{key:"item.image",fn:function({ item }){return [(_vm.itemListUrlImage.length !== 0)?_c('div',[_c('img',{staticClass:"mt-2 mb-0",staticStyle:{"height":"70px"},attrs:{"src":_vm.itemListUrlImage[0][item.code]},on:{"error":function($event){return _vm.imageLoadError($event, item.code)}}})]):_vm._e()]}},{key:"item.code",fn:function({ index, item }){return [_c('a',{on:{"click":function($event){return _vm.getMoreDetail(
            index,
            item.code,
            `${item.firstNameEn} ${item.lastNameEn}`
          )}}},[_c('p',{staticClass:"mt-0 mb-0 font-weight-bold"},[_vm._v(_vm._s(item.code))])])]}},{key:"item.firstNameEn",fn:function({ index, item }){return [_c('a',{on:{"click":function($event){return _vm.getMoreDetail(
            index,
            item.code,
            `${item.firstNameEn} ${item.lastNameEn}`
          )}}},[_c('p',{staticClass:"mt-0 mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(item.firstNameEn)+" "+_vm._s(item.lastNameEn)+" ")])])]}},{key:"item.emailAc",fn:function({ index, item }){return [_c('p',{staticClass:"mt-0 mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(!["", null, "null"].includes(item.email) ? item.email : `u${item.code}@student.mahidol.ac.th`)+" ")])]}},{key:"item.emailEdu",fn:function({ index, item }){return [(_vm.listStudentEmail.length != 0)?_c('p',{staticClass:"mt-0 mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(!["", null, "null"].includes(_vm.listStudentEmail[0][item.code].edu) ? _vm.listStudentEmail[0][item.code].edu : ``)+" ")]):_vm._e()]}},{key:"item.credit",fn:function({ index, item }){return [_c('p',{staticClass:"mt-0 mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(item.credit)+" ")])]}},{key:"item.gpa",fn:function({ index, item }){return [_c('p',{staticClass:"mt-0 mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(item.gpa !== null && item.gpa !== "" ? item.gpa.toFixed(2) : "N/A")+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }