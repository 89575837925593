var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"deep-purple darken-3"},[_c('th',{staticClass:"white--text text-left text-h5",attrs:{"width":"20%"}},[_vm._v(" Contract Type ")]),_c('th',{staticClass:"white--text text-left text-h5",attrs:{"width":"20%"}},[_vm._v("Track")]),_c('th',{staticClass:"white--text text-center text-h5",attrs:{"width":"15%"}},[_vm._v("Start")]),_c('th',{staticClass:"white--text text-center text-h5",attrs:{"width":"15%"}},[_vm._v("End")]),_c('th',{staticClass:"white--text text-center text-h5",attrs:{"width":"10%"}},[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.contract),function(contractLine,index){return _c('tr',{key:'contract' + index},[_c('td',[_c('p',{staticClass:"mt-0 mb-0 font-weight-bold text-capitalize",class:{ 'text--disabled': contractLine.status !== 'active' }},[_vm._v(" "+_vm._s(contractLine.contract_type)+" ")])]),_c('td',[_c('p',{staticClass:"mt-0 mb-0 font-weight-bold",class:{ 'text--disabled': contractLine.status !== 'active' }},[_vm._v(" "+_vm._s(contractLine.track_detail.track_name)+" ")])]),_c('td',{staticClass:"text-center"},[_c('p',{staticClass:"mt-0 mb-0 font-weight-bold",class:{ 'text--disabled': contractLine.status !== 'active' }},[_vm._v(" "+_vm._s(_vm.moment(contractLine.hr_start_date).format("DD/MM/YYYY"))+" ")])]),_c('td',{staticClass:"text-center"},[(
                _vm.moment(contractLine.hr_end_date)
                  .format('DD/MM/YYYY')
                  .split('/')[2] === '3000' ||
                _vm.moment(contractLine.hr_end_date)
                  .format('DD/MM/YYYY')
                  .split('/')[2] === '9999'
              )?_c('p',{staticClass:"mt-0 mb-0 font-weight-bold",class:{ 'text--disabled': contractLine.status !== 'active' }},[_vm._v(" Present ")]):_c('p',{staticClass:"mt-0 mb-0 font-weight-bold",class:{ 'text--disabled': contractLine.status !== 'active' }},[_vm._v(" "+_vm._s(_vm.moment(contractLine.hr_end_date).format("DD/MM/YYYY"))+" ")])]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticClass:"text-center ma-2",attrs:{"label":"","small":"","color":contractLine.status === 'active' ? 'green' : '',"text-color":contractLine.status === 'active' ? 'white' : ''}},[_vm._v(" "+_vm._s(contractLine.status)+" ")])],1)])}),0)]},proxy:true}])}),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"deep-purple darken-1"},[_c('th',{staticClass:"white--text text-left text-h5",attrs:{"width":"50%"}},[_vm._v("Academic Rank")]),_c('th',{staticClass:"white--text text-center text-h5",attrs:{"width":"15%"}},[_vm._v("Start")]),_c('th',{staticClass:"white--text text-center text-h5",attrs:{"width":"15%"}},[_vm._v("End")]),_c('th',{staticClass:"white--text text-center text-h5",attrs:{"width":"10%"}},[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.rank),function(rankLine,index){return _c('tr',{key:'rank' + index},[_c('td',[_c('p',{staticClass:"mt-2 mb-0 font-weight-bold text-capitalize",class:{ 'text--disabled': rankLine.status !== 'active' }},[_vm._v(" "+_vm._s(rankLine.rank_detail.rank_name)+" ")])]),_c('td',{staticClass:"text-center"},[_c('p',{staticClass:"mt-0 mb-0 font-weight-bold",class:{ 'text--disabled': rankLine.status !== 'active' }},[_vm._v(" "+_vm._s(_vm.moment(rankLine.hr_start_date).format("DD/MM/YYYY"))+" ")])]),_c('td',{staticClass:"text-center"},[(
                _vm.moment(rankLine.hr_end_date)
                  .format('DD/MM/YYYY')
                  .split('/')[2] === '3000' ||
                _vm.moment(rankLine.hr_end_date)
                  .format('DD/MM/YYYY')
                  .split('/')[2] === '9999'
              )?_c('p',{staticClass:"mt-0 mb-0 font-weight-bold",class:{ 'text--disabled': rankLine.status !== 'active' }},[_vm._v(" Present ")]):_c('p',{staticClass:"mt-0 mb-0 font-weight-bold",class:{ 'text--disabled': rankLine.status !== 'active' }},[_vm._v(" "+_vm._s(_vm.moment(rankLine.hr_end_date).format("DD/MM/YYYY"))+" ")])]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticClass:"text-center ma-2",attrs:{"label":"","small":"","color":rankLine.status === 'active' ? 'green' : '',"text-color":rankLine.status === 'active' ? 'white' : ''}},[_vm._v(" "+_vm._s(rankLine.status)+" ")])],1)])}),0)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }