<template>
  <div v-if="$store.state.role.RulesUser">
    <h5 class="mt-0 mb-2 font-weight-bold">Find student information</h5>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Enter a student ID number"
          outlined
          dense
          hide-details
          @keydown.enter="getMoreDetail"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <!-- <h5 class="mt-0 mb-2 font-weight-bold">&nbsp;</h5> -->
        <v-btn class="mt-0 mb-5" color="primary" dark @click="getMoreDetail">
          Search
        </v-btn>
      </v-col>
    </v-row>
    <!-- content -->
    <div v-if="Object.keys(profileData).length > 0">
      <div v-if="loadingProfile">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col
            class="mt-2 mb-0 font-weight-bold text-subtitle-1 text-center"
            cols="12"
          >
            Getting your student
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="light-blue darken-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <!-- v-if="Object.keys(profileData).length" -->
        <!-- <pre>{{ profileData }}</pre> -->
        <v-row>
          <v-col cols="12" sm="12" md="5">
            <v-card class="mb-5 mx-auto">
              <v-card-text v-if="!loadingProfile">
                <div class="mt-1 mb-2">
                  <v-icon class="mr-2">mdi-folder-open</v-icon>Student
                  Information
                </div>
                <v-list-item class="pa-0" two-line>
                  <div class="mr-3">
                    <!-- <img
                      class=""
                      style="height: 120px"
                      :src="`https://icapp.muic.mahidol.ac.th/source/pic/${profileData.code}.jpg`"
                    /> -->
                    <img
                      class="mt-2 mb-0"
                      style="height: 120px"
                      :src="photoPath"
                    />
                  </div>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-2">
                      <div class="w-100 d-flex justify-space-between">
                        <p class="text--disabled mt-0 mb-0">Name :</p>
                        <p class="mt-0 mb-0 font-weight-bold">
                          {{ profileData.firstNameEn }}
                          {{ profileData.lastNameEn }}
                        </p>
                      </div>
                    </v-list-item-title>
                    <v-list-item-title class="black--text mt-2">
                      <div class="w-100 d-flex justify-space-between">
                        <p class="text--disabled mt-0 mb-0">GPA :</p>
                        <p class="font-weight-bold mt-0 mb-0">
                          {{
                            profileData.gpa !== null && profileData.gpa !== ""
                              ? parseFloat(profileData.gpa).toFixed(2)
                              : "N/A"
                          }}
                        </p>
                      </div>
                    </v-list-item-title>
                    <v-list-item-subtitle class="black--text mt-2">
                      <div class="w-100 d-flex justify-space-between">
                        <p class="text--disabled mt-0 mb-0">Major :</p>
                        <p class="font-weight-bold mt-0 mb-0">
                          {{ profileData.facultyNameEn }}
                        </p>
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="black--text mt-2">
                      <div class="w-100 d-flex justify-space-between">
                        <p class="text--disabled mt-0 mb-0">Email :</p>
                        <div class="text-right">
                          <p class="font-weight-bold mt-0 mb-1">
                            u{{ profileData.code }}@student.mahidol.ac.th
                          </p>
                          <p class="font-weight-bold mt-0 mb-1">
                            {{
                              dataMuicMail !== null && dataMuicMail !== ""
                                ? dataMuicMail
                                : "-"
                            }}
                          </p>
                        </div>
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="black--text mt-1">
                      <div class="w-100 d-flex justify-space-between">
                        <p class="text--disabled mt-0 mb-0">Status :</p>
                        <v-chip
                          class="ma-0"
                          label
                          small
                          :dark="
                            ['graduated', 'dismiss'].includes(
                              profileData.status
                            )
                          "
                          :color="
                            profileData.status === 'graduated'
                              ? 'teal'
                              : profileData.status === 'studying'
                              ? 'primary'
                              : 'red'
                          "
                        >
                          <span
                            class="text-capitalize"
                            style="font-size: 16px"
                            >{{
                              profileData.status === "dismiss"
                                ? "Dismissed"
                                : profileData.status
                            }}</span
                          >
                        </v-chip>
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="7">
            <v-expansion-panels v-model="panelOpen" focusable accordion>
              <v-expansion-panel
                v-for="(item, i) in profileData.grades"
                :key="'list' + i"
              >
                <v-expansion-panel-header>
                  <div>
                    <p class="font-weight-bold mt-0 mb-2">
                      AcademicYear : {{ item.academicYear }} / {{ item.term }}
                    </p>
                    <small class="text--disabled font-weight-bold mt-0 mb-0">
                      Registered : {{ item.courses.length }} Course
                    </small>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    disable-pagination
                    hide-default-footer
                    no-data-text="No information"
                    :headers="headers"
                    :items="sortGradeInList(item.courses)"
                    class="ma-0 mt-3 mb-2"
                  >
                    <template #item.code="{ index, item }">
                      <p class="mt-0 mb-0 font-weight-bold">{{ item.code }}</p>
                    </template>
                    <template #item.nameEn="{ index, item }">
                      <p class="mt-0 mb-0 font-weight-bold">
                        {{ item.nameEn }}
                      </p>
                    </template>
                    <template #item.grade="{ index, item }">
                      <p class="mt-0 mb-0 font-weight-bold">{{ item.grade }}</p>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <div v-if="loadingProfile">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col
            class="mt-2 mb-0 font-weight-bold text-subtitle-1 text-center"
            cols="12"
          >
            Getting your student
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="light-blue darken-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <v-alert
        v-else
        text
        prominent
        type="warning"
        icon="mdi-account-search-outline"
      >
        <div class="text-left">
          <h4 class="mt-0 mb-0">Enter a student ID to search.</h4>
        </div>
      </v-alert>
    </div>
    <!-- content -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      profileData: {},
      loadingProfile: false,
      headers: [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          text: "Registered Courses",
          align: "start",
          sortable: false,
          value: "nameEn",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
        {
          text: "Grade",
          value: "grade",
          align: "center",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
        },
      ],
      gruopData: [],
      dataMuicMail: "",
      panelOpen: 0,
      photoPath: "",
    };
  },
  created() {
    if (!this.$store.state.role.RulesUser.RolePdPCChair) {
      this.$router
        .push({
          name: "section",
        })
        .catch((err) => {});
    }
  },
  mounted() {
    // this.getMoreDetail()
  },
  methods: {
    sortGradeInList(listDetail) {
      let arrTmp = [];
      arrTmp = listDetail.map((item) => ({
        ...item,
        grade: item.grade,
      }));

      return arrTmp.sort(function (a, b) {
        var nameA = a.grade.toUpperCase();
        var nameB = b.grade.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    async getMoreDetail() {
      this.loadingProfile = true;
      this.dataMuicMail = "";
      this.photoPath = "";
      try {
        const resData = await this.$http.get(
          `/uspark/api/${this.search}/grades/`
        );
        if (resData.status === 200) {
          // get image
          const send = new FormData();
          send.append("student", this.search);
          try {
            const resPhoto = await this.$http.post(
              "/group_presigned_url/",
              send,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
            if (resPhoto.status === 200) {
              this.photoPath = resPhoto.data[0].img_url;
            } else {
              throw { resPhoto };
            }
          } catch (error) {}
          // get email
          try {
            const sendDataListStd = new FormData();
            sendDataListStd.append("list_std", this.search);
            const resMail = await this.$http.post(
              `/keystone/section/std_edu_search/`,
              sendDataListStd,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
            if (resMail.status === 200) {
              this.dataMuicMail = resMail.data[0].edu_mail;
            } else {
              throw { resMail };
            }
          } catch (error) {}
          this.profileData =
            resData.data.code === "200GEN001"
              ? Object.assign({}, resData.data.data)
              : {};
          this.loadingProfile = false;
        } else {
          throw { resData };
        }
      } catch (error) {
        this.loadingProfile = false;
      }
    },
  },
};
</script>