<template>
  <div>
    <a @click="gotoLink(cardData.url)">
      <v-hover v-slot="{ hover }" open-delay="200">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="mx-auto h-card"
        >
          <v-card-text>
            <div class="font-weight-bold">{{ cardData.toppic }}</div>

            <div class="mt-5 mb-5 text-center">
              <v-avatar size="45" color="blue darken-2">
                <v-icon dark large> {{ cardData.icon }} </v-icon>
              </v-avatar>
            </div>

            <p
              :class="{
                'f-10': cardData.toppic === 'Teaching record',
                'f-14': cardData.toppic !== 'Teaching record',
              }"
              class="mt-0 mb-0 text--primary"
            >
              {{ cardData.detail }}
            </p>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-hover>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    gotoLink(UrlLink) {
      // console.log(UrlLink)
      if (UrlLink === "https://ks.muic.io/ScoreByInstructor" || UrlLink === "https://newmy.muic.io/teaching" || UrlLink === "https://newmy.muic.io/service" || UrlLink === "https://ks.muic.io/QuestionnaireByInstructor") {
        window.open(UrlLink, "_blank");
      } else {
        this.$router.push(UrlLink).catch((err) => {});
      }
    },
  },
};
</script>

<style scoped>
.f-14 {
  font-size: 14px;
}
.f-10 {
  font-size: 12px;
}
.h-card {
  height: 200px;
}
</style>

<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
>.v-card__text
  color: #000
</style>