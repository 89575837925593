<template>
    <div v-if="dataModalDrawer.itemInView.length != 0">

        <v-navigation-drawer v-model="dataModalDrawer.drawer" absolute permanent right style="width: 1000px;">

            <v-list-item>
                <v-list-item-avatar>
                    <v-icon aria-hidden="false">
                        mdi-folder-open
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>
                        <strong>{{ dataModalDrawer.itemInView.course.courseCode }} {{
                            dataModalDrawer.itemInView.course.courseNameEn }} Sec.{{
                                dataModalDrawer.itemInView.sectionNumber }}</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ dataModalDrawer.itemInView.course.courseCredit }}
                        ({{ dataModalDrawer.itemInView.course.lecture }}-{{ dataModalDrawer.itemInView.course.lab }}-{{
                            dataModalDrawer.itemInView.course.other }})
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn icon @click="emitCloseDrawer">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

            <!-- <pre>{{ dataModalDrawer.itemInView }}</pre> -->

            <div class="mt-2 mb-3 pa-5">
                <h4 class="mt-3 mb-2 ml-5">Instrunctor Team In Course</h4>
                
                <v-row align="start" style="height: 50px;" no-gutters>
                    <v-col cols="12" style="cursor: pointer" v-for="(n, index) in 5" :key="n">
                        <v-sheet class="pa-1 ma-1">
                            <!-- {{ index }} {{ dataModalDrawer.itemInView.team_instructor[index] }} -->
                            <!-- v-if='typeof dataModalDrawer.itemInView.team_instructor[index] !== "undefined"' -->
                            <div v-if="checkUndefined(dataModalDrawer.itemInView.team_instructor[index])">
                                <v-list-item dense
                                    v-if="dataModalDrawer.itemInView.team_instructor[index].instructor_detail.length != 0 && ![null, 'null'].includes(dataModalDrawer.itemInView.team_instructor[index].instructor_detail)">
                                    <v-list-item-avatar>
                                        <v-avatar class="mt-2">
                                            <img :src="'https://icapp.muic.mahidol.ac.th/source/pic/' + dataModalDrawer.itemInView.team_instructor[index].instructor_detail[0].code + '.jpg'"
                                                @error="imageLoadError($event, dataModalDrawer.itemInView.team_instructor[index].instructor_detail[0].code)"
                                                style="height: 50px; width: 50px;" />
                                        </v-avatar>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title style="font-size: 14px;"
                                            class="font-weight-bold dark--text mt-1 mb-0"
                                            v-if="Object.keys(dataModalDrawer.itemInView.team_instructor[index].instructor_detail[0].title).length != 0">
                                            {{
                                                dataModalDrawer.itemInView.team_instructor[index].instructor_detail[0].title.short_eng
                                            }} {{
                                                dataModalDrawer.itemInView.team_instructor[index].instructor_detail[0].name
                                            }} {{
                                                dataModalDrawer.itemInView.team_instructor[index].instructor_detail[0].surname
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mt-0 mb-0">
                                            <v-chip
                                                v-if="!['', null, 'null'].includes(dataModalDrawer.itemInView.mainInstructor) && dataModalDrawer.itemInView.mainInstructor.instructorCode === dataModalDrawer.itemInView.team_instructor[index].instructor_code"
                                                label class="mr-3 white--text font-weight-bold" color="yellow darken-3"
                                                dense small>Main</v-chip>
                                            <v-chip label
                                                :color="dataModalDrawer.itemInView.team_instructor[index].source === 'ks' ? 'primary' : 'teal lighten-4'"
                                                dense small>{{ dataModalDrawer.itemInView.team_instructor[index].source
                                                    === 'ks' ? 'Defalut' :
                                                    dataModalDrawer.itemInView.team_instructor[index].source }}</v-chip>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action 
                                        class="mb-2 mt-2"
                                        v-if="!['', null, 'null'].includes(dataModalDrawer.itemInView.mainInstructor) && dataModalDrawer.itemInView.mainInstructor.instructorCode !== dataModalDrawer.itemInView.team_instructor[index].instructor_code"
                                    >
                                        <v-btn small variants="text" @click="emitConfig(index)"><v-icon color="dark">mdi-pencil</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>

                            </div>
                            <div v-else>
                                <v-list-item dense>
                                    <v-list-item-avatar>
                                    <!-- <v-img blank></v-img> -->
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="disabled--text font-weight-bold">N/A</v-list-item-title>
                                        <v-list-item-subtitle class="disabled--text font-weight-bold">N/A</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action >
                                        <div class="mt-5 mb-5 text-center">
                                            <v-btn dense class="white--text font-weight-bold" small color="primary" @click="emitConfig(index)"><v-icon color="dark" class="mr-3">mdi-plus</v-icon>Add</v-btn>
                                        </div>
                                    </v-list-item-action>
                                </v-list-item>
                            </div>
                        </v-sheet>
                    </v-col>
                </v-row>
            </div>
            <br>
            <!--  
                <div class="pa-5" style="max-height: 55dvh; overflow-y: scroll">
                    <v-list dense>
                        <v-list-item v-for="(item, index) in dataModalDrawer.itemInView.sectionSlots" :key="'slot-' + index"
                            link>
                            <v-list-item-icon class="mb-2 mt-2">
                                <v-chip class="ml-2 mr-3" small label :color="item.status === 'w'
                                    ? 'yellow darken-3'
                                    : item.status === 'p'
                                        ? 'teal lighten-4'
                                        : ['r', 'c', null, 'n/a', 'N/A'].includes(item.status)
                                            ? 'grey lighten-4'
                                            : 'grey lighten-4'
                                    ">
                                    <span class="text-h6 font-weight-bold">
                                        <span v-if="item.teachingType === 'Midterm' ||
                                            item.teachingType === 'Final'
                                        ">
                                            <span v-if="item.status === 'w'">Waiting</span>
                                            <span v-if="item.status === 'p'">
                                                <span v-if="item.absentInstructor !== null">{{
                                                    item.absentInstructor ? "Absent / joint with other section" :
                                                        "Present"
                                                }}</span>
                                            </span>
                                            <span v-if="item.status === 'r'">Disable exam</span>
                                            <span v-if="item.status === 'c'">Absent</span>
                                            <span v-if="item.status === null || item.status === ''">N/A</span>
                                        </span>
                                        <span v-else>
                                            {{
                                                item.status === "w"
                                                    ? "Waiting"
                                                    : item.status === "p"
                                                        ? "Taught"
                                                        : item.status === "c"
                                                            ? "Cancelled"
                                                            : "N/A"
                                            }}
                                        </span>
                                    </span>
                                </v-chip>
                            </v-list-item-icon>

                            <v-list-item-content class="mb-2 mt-2">
                                <v-list-item-title>
                                    {{ item.day }} , <strong>{{ moment(item.date).format("DD MMM YYYY") }}</strong>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{
                                        `${item.startTime.split(":")[0]}:${item.startTime.split(":")[1]}`
                                    }}
                                    -
                                    {{
                                        `${item.endTime.split(":")[0]}:${item.endTime.split(":")[1]}`
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action style="width: 20%;" class="mb-2 mt-2">
                                <v-select dense :items="dataModalDrawer.itemInView.teamInstructor"></v-select>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </div>
            -->
            <!--  
                <div class="pa-3">
                    <pre>{{ dataModalDrawer.itemInView.sectionSlots }}</pre>
                </div>
            -->
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        // : {
        //     type: Array,
        //     default: () => [],
        // },
        dataModalDrawer: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {}
    },
    computed: {
        // changeData() {
        //     const {  } = this;
        //     return { };
        // },
    },
    watch: {
        // changeData: {
        //     handler: function (val) {
        //         console.log('xxxxxx')
        //     },
        //     deep: true,
        // },
    },
    mounted() { },
    methods: {
        emitCloseDrawer() {
            this.$emit('emitCloseDrawer', [])
        },
        emitConfig(indexLecture) {
            if (typeof this.dataModalDrawer.itemInView.team_instructor[indexLecture] !== 'undefined') {
                this.$emit('emitConfig', { mainData: this.dataModalDrawer.itemInView, lectureIndex: [this.dataModalDrawer.itemInView.team_instructor[indexLecture]] })
            } else {
                this.$emit('emitConfig', { mainData: this.dataModalDrawer.itemInView, lectureIndex: [] })
            }
        },
        checkUndefined(item) {
            return typeof item !== 'undefined' ? item : false
        },
        // other
        sortData(item) {
            return item.length != 0 ? item.sort((a, b) => a.id - b.id) : [];
        },
    }
}
</script>