<template>
  <div>
    <!-- post content -->
    <v-row v-if="dataDetail.length != 0">
      <v-col cols="12" sm="12" md="6">
        <v-card-title class="mb-3"> Write announcement </v-card-title>
        <v-card-subtitle
          ><small class="text--disabled"> </small
        ></v-card-subtitle>
        <v-card-text>
          <v-textarea
            class="mt-0 mb-0 text-r"
            outlined
            dense
            label="Announcement content"
            prepend-icon="mdi-comment"
            rows="10"
            v-model="FormSetAnountment.textPost"
          ></v-textarea>
          <v-file-input
            class="mt-0 mb-0"
            color="deep-purple accent-4"
            label="File Upload"
            placeholder="Select your files"
            prepend-icon="mdi-camera"
            counter
            outlined
            dense
            :show-size="1000"
            :rules="fileUpload.length !== 0 ? rules : []"
            v-model="fileUpload"
            ref="inputFile"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 2"
                color="deep-purple accent-4"
                dark
                label
                small
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-btn
            dense
            class="mt-2 mb-2 font-weight-bold white--text"
            color="blue darken-3"
            block
            :disabled="FormSetAnountment.textPost === ''"
            @click="
              openConfirm({
                textCase: 'addAnnou',
                MsgAction: `<span class='font-weight-bold'>The message to be announced is :</span>`,
              })
            "
            >Post Announcement</v-btn
          >
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <div v-if="loadingAnounment">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col
              class="mt-2 mb-0 font-weight-bold text-subtitle-1 text-center"
              cols="12"
            >
              Getting your Announcement list
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="light-blue darken-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
        <!-- Post -->
        <div v-else>
          <div v-if="dataAnounment.length != 0">
            <!-- <pre>{{ dataAnounment }}</pre> -->
            <v-card
              v-for="(itemPost, index) in dataAnounment"
              :key="'post' + index"
              class="mt-2 mb-2"
              outlined
            >
              <v-list-item class="mt-3">
                <v-list-item-avatar>
                  <v-avatar color="indigo lighten-2" size="48">
                    <span class="white--text text-h5">
                      {{ itemPost.author.firstNameEn.substring(0, 1) }}
                      {{ itemPost.author.lastNameEn.substring(0, 1) }}
                    </span>
                  </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      `${itemPost.author.positionEn} ${itemPost.author.firstNameEn} ${itemPost.author.lastNameEn}`
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    moment(itemPost.createdAt).format('DD-MM-YYYY')
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link>
                        <v-list-item-title
                          @click="
                            openConfirm({
                              textCase: 'delAnnou',
                              postId: itemPost.id,
                              MsgAction: `<span class='font-weight-bold'>Do your want to delete this announcment ?</span>`,
                              MsgDel: itemPost.message,
                            })
                          "
                          ><v-icon class="mr-2">mdi-delete-outline</v-icon
                          >Delete post</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-card-text class="mb-0">
                <div class="mb-2">
                  <p class="text-justify mt-0 mb-5 text-r">
                    <span
                      style="font-size: 14px"
                      v-html="itemPost.message"
                    ></span>
                  </p>
                </div>
                <v-img
                  v-if="itemPost.attachments !== '[]'"
                  :src="JSON.parse(itemPost.attachments)[0].Url"
                  style="height: 20%"
                ></v-img>
                <!-- <p>{{ JSON.parse(itemPost.attachments)[0].Url }}</p> -->
              </v-card-text>
            </v-card>
          </div>
          <div v-else>
            <v-alert color="info" dense text icon="mdi-post-outline" prominent>
              <strong class="black--text">
                This course
                <span class="red--text">
                  {{ dataDetail[0].course.courseCode }}
                  {{ dataDetail[0].course.courseNameEn }}
                  {{ dataDetail[0].course.courseCredit }}({{
                    dataDetail[0].course.lecture
                  }}-{{ dataDetail[0].course.lab }}-{{
                    dataDetail[0].course.other
                  }}) sec. {{ dataDetail[0].sectionNumber }}
                </span>
                has no announcements.
              </strong>
            </v-alert>
          </div>
        </div>
        <!-- use api send -->
        <!-- {{ dataDetail[0].mainInstructor.instructorCode }} -->
        <!-- {{ dataDetail[0].sectionId }} -->
      </v-col>
    </v-row>
    <!-- dialog confirm -->
    <v-dialog v-model="dialogConfirm" width="500">
      <v-card>
        <v-card-title class="text-h3 pa-2 font-weight-bold blue-grey lighten-5">
          {{ dataDialog.textCase === "delAnnou" ? "Delete" : "Confirm" }}
          Announcement
        </v-card-title>

        <v-card-text>
          <strong><span v-html="dataDialog.MsgAction"></span></strong>
          <div v-if="dataDialog.textCase === 'addAnnou'">
            <v-alert text color="info" class="mt-2 mb-2">
              <div class="black--text">
                <p class="mt-0 mb-0 text-justify text-r">
                  {{ FormSetAnountment.textPost }}
                </p>
              </div>
            </v-alert>
          </div>
          <div v-if="dataDialog.textCase === 'editAnnou'">
            <v-textarea
              class="mt-3 mb-0"
              outlined
              dense
              rows="10"
              label="Announcement content"
              v-model="dataDialog.MsgEdit"
            ></v-textarea>
            <v-file-input
              class="mt-0 mb-0"
              color="deep-purple accent-4"
              label="File Upload ( If you don't change the file, don't upload it again. )"
              placeholder="Select your files"
              prepend-icon="mdi-camera"
              counter
              outlined
              dense
              :show-size="1000"
              :rules="fileUploadEdit.length !== 0 ? rules : []"
              v-model="fileUploadEdit"
              ref="inputFile"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="loadingAction"
            dense
            class="mt-0 mb-2 mr-2 font-weight-bold white--text"
            :color="dataDialog.textCase === 'addAnnou' ? 'success' : 'red'"
            disabled
            ><v-progress-circular
              indeterminate
              color="white"
              class="mr-2"
              :size="20"
            ></v-progress-circular
            >Submit</v-btn
          >
          <v-btn
            v-else
            dense
            class="mt-0 mb-2 mr-2 font-weight-bold white--text"
            :color="dataDialog.textCase === 'addAnnou' ? 'success' : 'red'"
            @click="
              dataDialog.textCase === 'addAnnou'
                ? setPostAnnouncement()
                : dataDialog.textCase === 'editAnnou'
                ? editPost(dataDialog.postId)
                : deletePost(dataDialog.postId)
            "
            >{{ dataDialog.textCase === "addAnnou" ? "Submit" : "Yes" }}</v-btn
          >
          <v-btn
            dense
            class="mt-0 mb-2 font-weight-bold"
            :class="{
              'black--text':
                dataDialog.textCase === 'addAnnou' ||
                dataDialog.textCase === 'editAnnou',
            }"
            :color="
              dataDialog.textCase === 'addAnnou' ||
              dataDialog.textCase === 'editAnnou'
                ? ''
                : ''
            "
            @click="closeConfirm"
            >{{ dataDialog.textCase === "addAnnou" ? "Cancel" : "No" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- msg error -->
    <v-snackbar
      absolute
      top
      color="red darken-4"
      v-model="snackbarErrorPost"
      v-if="msgErrorPost !== null"
    >
      <strong>{{ msgErrorPost }}</strong>
    </v-snackbar>
  </div>
</template>

<script>
const formAnounment = {
  textPost: "",
};
import comment from "./Comments.vue";
export default {
  components: {
    comment,
  },
  props: {
    dataDetail: {
      type: Array,
      default: () => [],
    },
    loadingAnounment: {
      type: Boolean,
      default: false,
    },
    dataAnounment: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data() {
    return {
      rules: [
        (value) =>
          !value ||
          value.size < 10000000 ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          ) ||
          `File size should be less than 10 MB! or accept [ .pdf , .jpeg ,jpg , .png ] Only`,
      ],
      FormSetAnountment: Object.assign({}, formAnounment),
      fileUpload: [],
      fileUploadEdit: [],
      btnChk: false,
      loadingAction: false,
      snackbarErrorPost: false,
      msgErrorPost: "",
      ViewComment: "",
      loadingComment: false,
      listComment: [],
      inputComment: [],
      // dialog
      dataDialog: {},
      dialogConfirm: false,
    };
  },
  mounted() {},
  methods: {
    openConfirm(dataConfirm) {
      this.dataDialog = Object.assign({}, dataConfirm);
      this.dialogConfirm = true;
    },
    closeConfirm() {
      this.dialogConfirm = false;
      this.dataDialog = Object.assign({}, {});
    },
    async getComment(tokenCourseId) {
      this.ViewComment = tokenCourseId;
      this.loadingComment = true;
      this.listComment = [];
      try {
        const resComment = await this.$http.get(
          `/uspark/announce/${tokenCourseId}/comments/`
        );
        if (resComment.status === 200) {
          this.listComment = resComment.data.data.map((item) => ({
            ...item,
          }));
          this.loadingComment = false;
        } else {
          throw { resComment };
        }
      } catch (error) {
        this.loadingComment = false;
        this.msgErrorPost = `Caught api connect API GET : /uspark/announce/${tokenCourseId}/comments/`;
        this.snackbarErrorPost = true;
        this.ViewComment = "";
      }
    },
    async setPostAnnouncement() {
      this.loadingAction = true;
      this.snackbarErrorPost = false;
      const sendDataPost = new FormData();
      sendDataPost.append("message", this.FormSetAnountment.textPost);
      sendDataPost.append("attachments", this.fileUpload);
      sendDataPost.append("isActive", true);
      sendDataPost.append("ksSectionId", this.dataDetail[0].sectionId);
      sendDataPost.append(
        "instructorCode",
        this.dataDetail[0].mainInstructor.instructorCode
      );
      try {
        const resPostAnnouncment = await this.$http.post(
          `/uspark/announce/posts/`,
          sendDataPost,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (resPostAnnouncment.status === 200) {
          this.$emit("newAnnouncment", this.dataDetail[0].sectionId);
          this.loadingAction = false;
          this.FormSetAnountment = Object.assign({}, formAnounment);
          this.fileUpload = [];
          this.closeConfirm();
        } else {
          throw { resPostAnnouncment };
        }
      } catch (error) {
        this.loadingAction = false;
        this.msgErrorPost = `Caught api connect API POST : /uspark/announce/posts/`;
        this.snackbarErrorPost = true;
      }
    },
    async deletePost(id) {
      this.loadingAction = true;
      try {
        const resComment = await this.$http.get(
          `/uspark/announce/del_posts/?token_id=${id}`
        );
        if (resComment.status === 200) {
          this.$emit("newAnnouncment", this.dataDetail[0].sectionId);
          this.closeConfirm();
          this.loadingAction = false;
        } else {
          throw { resComment };
        }
      } catch (error) {
        this.loadingAction = false;
        this.msgErrorPost = `Caught api connect API DELETE : /uspark/announce/posts/?tokenid=${id}`;
        this.snackbarErrorPost = true;
      }
    },
    async editPost(id) {
      this.loadingAction = true;
      const sendDataEditPost = new FormData();
      sendDataEditPost.append("message", this.dataDialog.MsgEdit);
      if (this.fileUploadEdit.length != 0) {
        sendDataEditPost.append("attachments", this.fileUploadEdit);
      } else {
        sendDataEditPost.append("attachments", this.dataDialog.jsonImage);
      }
      sendDataEditPost.append("isActive", true);
      sendDataEditPost.append("postId", id);
      try {
        const resEditAnnouncment = await this.$http.post(
          `/uspark/announce/put_posts/`,
          sendDataEditPost,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (resEditAnnouncment.status === 200) {
          this.$emit("newAnnouncment", this.dataDetail[0].sectionId);
          this.fileUploadEdit = [];
          this.closeConfirm();
          this.loadingAction = false;
        } else {
          throw { resEditAnnouncment };
        }
      } catch (error) {
        this.msgErrorPost = `Caught api connect API PATCH : /uspark/announce/put_posts/`;
        this.loadingAction = false;
        this.snackbarErrorPost = true;
      }
    },
    async sendComment(id) {
      this.msgErrorPost = "";
      if (typeof this.inputComment[id] !== "undefined") {
        const sendDataComment = new FormData();
        sendDataComment.append("message", this.inputComment[id]);
        try {
          const resAddComment = await this.$http.post(
            `/uspark/announce/put_posts/`,
            sendDataComment,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          if (resAddComment.status === 200) {
            this.inputComment[id] = "";
          } else {
            throw { resAddComment };
          }
        } catch (error) {
          this.msgErrorPost = `Caught api connect API POST : `;
          this.snackbarErrorPost = true;
        }
      } else {
        this.msgErrorPost = `Caught input : check value comment in textfeild`;
        this.snackbarErrorPost = true;
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.font-logTime {
  font-weight: 500;
  font-size: 12px;
}
.text-r {
  white-space: pre-wrap;
}
</style>
