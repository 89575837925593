<template>
  <div>
    <!-- <pre>{{ dataExam[0].examinationReservation }}</pre> -->
    <!-- <pre>{{ serverTime }}</pre> -->
    <!-- <pre>{{ arrCurrentTime }}</pre> -->
    <!-- Y : {{ selYear }} T : {{ parseInt(switchShowTerm + 1) }} -->
    <v-row v-if="dataExam.length != 0" justify="center">
      <v-col cols="12" md="6">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="background-color: #eeeeee"
                  class="
                    boder-left boder-right boder-top
                    text-center
                    font-weight-black
                    text-h4
                    black--text
                  "
                >
                  Midterm For Term :
                  {{ `${parseInt(switchShowTerm + 1)}/${selYear}` }}
                </th>
                <th class="text-right">
                  <v-btn
                    @click="
                      openDialogConfirm(
                        'Midterm',
                        'disable',
                        dataExam[0].sectionId
                      )
                    "
                    small
                    class="mb-1"
                    color="error"
                    :dark="
                      !checkBtnDisableExam(
                        dataExam[0].examinationReservation,
                        'Midterm'
                      )
                    "
                    :disabled="
                      checkBtnDisableExam(
                        dataExam[0].examinationReservation,
                        'Midterm'
                      )
                    "
                  >
                    Disable Exam
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in midtermData()"
                :key="'Midterm' + index"
              >
                <td
                  :class="{ 'boder-bottom': item.label === 'Proctor Remarks' }"
                  class="boder-left boder-right font-weight-bold"
                >
                  <!-- <pre>{{ item }}</pre> -->
                  {{ item.label }}
                </td>
                <td
                  v-if="item.label === 'Status'"
                  class="boder-right font-weight-bold"
                >
                  <v-chip
                    small
                    :color="
                      item.value === 'Approved'
                        ? 'success'
                        : item.value === 'Waiting'
                        ? 'warning'
                        : ''
                    "
                    class="pa-2"
                    label
                    >{{ item.value }}</v-chip
                  >
                </td>
                <td
                  v-else-if="item.label === 'Proctor #'"
                  class="boder-right font-weight-bold"
                >
                  {{ item.value != 0 ? item.value : "-" }}
                </td>
                <td
                  v-else-if="item.label === 'Requests'"
                  class="boder-right font-weight-bold"
                >
                  <p
                    v-if="item.value !== 'N/A' && !item.value.absentInstructor"
                    class="mt-0 mb-0"
                  >
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Lecturer Present
                  </p>
                  <p v-if="item.value.allowBooklet" class="mt-0 mb-0">
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Exam Booklet
                  </p>
                  <p v-if="item.value.allowCalculator" class="mt-0 mb-0">
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Exam Calculator
                  </p>
                  <p v-if="item.value.allowOpenbook" class="mt-0 mb-0">
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Exam Open Book
                  </p>
                  <p v-if="item.value.allowAppendix" class="mt-0 mb-0">
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Exam Appendix
                  </p>
                </td>
                <td
                  v-else
                  :class="{ 'boder-bottom': item.label === 'Proctor Remarks' }"
                  class="boder-right font-weight-bold"
                >
                  {{
                    !["", null, "null"].includes(item.value) ? item.value : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- {{ midtermCheck }} -->
        <!-- {{ checkAfterBtn(2021, 3, 2022, 1) }} -->
        <div
          v-if="
            checkAfterBtn(
              arrCurrentTime.year,
              arrCurrentTime.term,
              selYear,
              parseInt(switchShowTerm + 1)
            )
          "
        >
          <v-btn
            :disabled="midtermCheck"
            @click="
              openDialogMakeRequest('Midterm', dataExam[0].sectionId, 'm')
            "
            block
            class="mb-2 mt-3"
            dark
            color="primary"
          >
            Confirm / Make Requests
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="background-color: #eeeeee"
                  class="
                    boder-left boder-right boder-top
                    text-center
                    font-weight-black
                    text-h4
                    black--text
                  "
                >
                  <span class="danger--text">Final</span> For Term :
                  {{ `${parseInt(switchShowTerm + 1)}/${selYear}` }}
                </th>
                <th class="text-right">
                  <v-btn
                    @click="
                      openDialogConfirm(
                        'Final',
                        'disable',
                        dataExam[0].sectionId
                      )
                    "
                    small
                    class="mb-1"
                    color="error"
                    :dark="
                      !checkBtnDisableExam(
                        dataExam[0].examinationReservation,
                        'Final'
                      )
                    "
                    :disabled="
                      checkBtnDisableExam(
                        dataExam[0].examinationReservation,
                        'Final'
                      )
                    "
                  >
                    Disable Exam
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in finalData()" :key="'Final' + index">
                <td
                  :class="{ 'boder-bottom': item.label === 'Proctor Remarks' }"
                  class="boder-left boder-right font-weight-bold"
                >
                  {{ item.label }}
                </td>
                <td
                  v-if="item.label === 'Status'"
                  class="boder-right font-weight-bold"
                >
                  <v-chip
                    small
                    :color="
                      item.value === 'Approved'
                        ? 'success'
                        : item.value === 'Waiting'
                        ? 'warning'
                        : ''
                    "
                    class="pa-2"
                    label
                    >{{ item.value }}</v-chip
                  >
                </td>
                <td
                  v-else-if="item.label === 'Proctor #'"
                  class="boder-right font-weight-bold"
                >
                  {{ item.value != 0 ? item.value : "-" }}
                </td>
                <td
                  v-else-if="item.label === 'Requests'"
                  class="boder-right font-weight-bold"
                >
                  <p
                    v-if="item.value !== 'N/A' && !item.value.absentInstructor"
                    class="mt-0 mb-0"
                  >
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Lecturer Present
                  </p>
                  <p v-if="item.value.allowBooklet" class="mt-0 mb-0">
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Exam Booklet
                  </p>
                  <p v-if="item.value.allowCalculator" class="mt-0 mb-0">
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Exam Calculator
                  </p>
                  <p v-if="item.value.allowOpenbook" class="mt-0 mb-0">
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Exam Open Book
                  </p>
                  <p v-if="item.value.allowAppendix" class="mt-0 mb-0">
                    <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon
                    >Exam Appendix
                  </p>
                </td>
                <td
                  v-else
                  :class="{ 'boder-bottom': item.label === 'Proctor Remarks' }"
                  class="boder-right font-weight-bold"
                >
                  {{
                    !["", null, "null"].includes(item.value) ? item.value : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- <pre>{{ finalCheck }}</pre> -->
        <div
          v-if="
            checkAfterBtn(
              arrCurrentTime.year,
              arrCurrentTime.term,
              selYear,
              parseInt(switchShowTerm + 1)
            )
          "
        >
          <v-btn
            block
            class="mb-2 mt-3"
            dark
            color="primary"
            @click="openDialogMakeRequest('Final', dataExam[0].sectionId, 'f')"
            :disabled="finalCheck"
          >
            Confirm / Make Requests
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- dialog -->
    <v-dialog
      v-model="dialogMakeRequest"
      width="500"
      :retain-focus="false"
      persistent
    >
      <v-card>
        <v-card-text>
          <p class="mt-0 mb-0 font-weight-bold">
            Set <span class="red--text">{{ titleDialog }}</span> Exam Requests
          </p>
          <p class="mt-0 mb-3 font-weight-bold">
            Course : {{ dataExam[0].course.courseCode }}
            {{ dataExam[0].course.courseNameEn }}
          </p>

          <v-alert dense class="font-weight-bold" color="blue" text
            >NOTE: Students can see your exam requests.</v-alert
          >

          <!-- <pre>{{ formMakeRequest }}</pre> -->

          <!-- data exam -->
          <div v-if="formMakeRequest.typeRequest === 'f'">
            <!-- <pre>{{ formMakeRequest }}</pre> -->
            <p class="mt-0 mb-2 font-weight-bold">
              Date : {{ moment(infodateFinal).format("DD/MM/YYYY") }} Time :
              {{ formMakeRequest.examHour_start }}:{{
                formMakeRequest.examMinute_start
              }}
              - {{ formMakeRequest.examHour_end }}:{{
                formMakeRequest.examMinute_end
              }}
            </p>
          </div>
          <div v-else>
            <label class="mt-0 mb-0 font-weight-bold">Date</label>
            <v-menu
              v-model="menuExam"
              :close-on-content-click="false"
              max-width="290"
              class="mb-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  :value="computedDateFormattedMomentjsExam"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="formMakeRequest.dateExam = null"
                ></v-text-field>
              </template>
              <!-- :min="moment().format('YYYY-MM-DD')" -->
              <v-date-picker
                v-model="formMakeRequest.dateExam"
                @change="menuExam = false"
              ></v-date-picker>
            </v-menu>
            <!-- time star / end -->
            <label class="mt-0 mb-0 font-weight-bold">Time Start</label>
            <v-row>
              <v-col cols="5">
                <v-autocomplete
                  :items="arrHour"
                  v-model="formMakeRequest.examHour_start"
                  dense
                  outlined
                  persistent-hint
                  hint="Hour"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="text-center"><strong>:</strong></v-col>
              <v-col cols="5">
                <v-autocomplete
                  :items="arrMinuteStart"
                  v-model="formMakeRequest.examMinute_start"
                  dense
                  outlined
                  persistent-hint
                  hint="Minute"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <label class="mt-0 mb-0 font-weight-bold">Time End</label>
            <v-row>
              <v-col cols="5">
                <v-autocomplete
                  :items="arrHour"
                  v-model="formMakeRequest.examHour_end"
                  dense
                  outlined
                  persistent-hint
                  hint="Hour"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="text-center"><strong>:</strong></v-col>
              <v-col cols="5">
                <v-autocomplete
                  :items="arrMinuteEnd"
                  v-model="formMakeRequest.examMinute_end"
                  dense
                  outlined
                  persistent-hint
                  hint="Minute"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>

          <label class="mt-5 mb-0 font-weight-bold"
            >Lecturer<span class="ml-2 red--text">*</span></label
          >
          <v-radio-group class="mt-0 mb-0" v-model="formMakeRequest.choice_11">
            <v-radio
              v-for="(n, index) in optionAA"
              :key="'r' + index"
              :label="`${n.text}`"
              :value="n.value"
            ></v-radio>
          </v-radio-group>

          <!-- checkbok-1 -->
          <label class="mt-2 mb-0 font-weight-bold">Requesting for</label>
          <!-- {{ formMakeRequest.choice_1 }} -->
          <div
            class="mt-0 mb-0"
            v-for="(chkA, index) in optionA"
            :key="'chkA' + index"
          >
            <v-checkbox
              class="mt-0 mb-0"
              :label="chkA.text"
              color="primary"
              :value="chkA.value"
              v-model="formMakeRequest.choice_1"
              hide-details
            ></v-checkbox>
            <div v-if="chkA.value === 'Proctors'">
              <!-- protor total -->
              <div v-if="formMakeRequest.choice_1.includes('Proctors')">
                <v-text-field
                  label="total Proctors"
                  placeholder=""
                  outlined
                  dense
                  class="mt-2 mb-0"
                  type="number"
                  v-model="formMakeRequest.totalPrt"
                ></v-text-field>
              </div>
            </div>
          </div>

          <v-textarea
            rows="1"
            row-height="15"
            class="mt-2"
            outlined
            label="Remark For Proctors"
            v-model="formMakeRequest.remarkForPrt"
          ></v-textarea>

          <!-- checkbok-2 -->
          <label class="mt-2 mb-0 font-weight-bold">Allow students to</label>
          <div
            class="mt-0 mb-0"
            v-for="(chkB, index) in optionB"
            :key="'chkB' + index"
          >
            <v-checkbox
              class="mt-0 mb-0"
              :label="chkB.text"
              color="primary"
              :value="chkB.value"
              v-model="formMakeRequest.choice_2"
              hide-details
            ></v-checkbox>
          </div>

          <!-- text -->
          <v-textarea
            rows="1"
            row-height="15"
            class="mt-3"
            outlined
            label="Remark For Students"
            v-model="formMakeRequest.remarkForStd"
          ></v-textarea>
        </v-card-text>

        <!-- msg case Error -->
        <div class="mt-2 mb-2" v-if="objMsgCase.length != 0">
          <v-alert dense class="font-weight-bold" color="red" text>
            <div class="text--h4 font-weight-bold mb-1">
              {{ objMsgCase[0].message }}
            </div>

            <v-list-item
              two-line
              v-for="(item, index) in objMsgCase[0].data"
              :key="'l' + index"
              dense
              class="mt-0 mb-2"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold mb-1">
                  Timeslot : {{ moment(item.date).format("DD MMM YYYY") }}
                  {{ item.startTime.split(":")[0] }}:{{
                    item.startTime.split(":")[1]
                  }}
                  - {{ item.endTime.split(":")[0] }}:{{
                    item.endTime.split(":")[1]
                  }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="mt-1"
                  v-if="item.instructor !== null"
                  >Lecture:
                  {{
                    `${item.instructor.title}${item.instructor.name} ${item.instructor.lastName}`
                  }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  class="text-capitalize mt-1"
                  v-if="item.instructor !== null"
                  >Class: {{ `${item.teachingType}` }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-alert>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="submitMakeRequest"
            class="font-weight-bold mt-0 mr-2 mb-2"
            color="green"
            dense
            :dark="!loadingRequestExam"
            :disabled="loadingRequestExam"
          >
            <v-progress-circular
              v-if="loadingRequestExam"
              :size="20"
              color="white"
              indeterminate
              class="mr-2"
            ></v-progress-circular>
            Submit
          </v-btn>
          <v-btn
            @click="closeDialogMakeRequest"
            color="dark"
            dark
            class="mt-0 mb-2 font-weight-bold"
            dense
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog confirm exam -->
    <v-dialog v-model="dialogExam" width="500" :retain-focus="false" persistent>
      <v-card>
        <v-card-text>
          <p class="mt-0 mb-1 font-weight-bold">
            <span
              :class="
                formConfirmExam.val === 'confirm'
                  ? 'text--success'
                  : 'deep-orange--text text--darken-1'
              "
              class="text-h3 text-capitalize font-weight-bold mr-2"
              >{{ formConfirmExam.val }}</span
            >
            : Exam Requests for
            {{ formConfirmExam.typeFor }}
          </p>
          <!--  
            <pre>{{ dataExam[0] }}</pre>
          -->
          <p class="mt-0 mb-3 font-weight-bold">
            Course : {{ dataExam[0].course.courseCode }}
            {{ dataExam[0].course.courseNameEn }}
          </p>
          
          <div v-if="!['','null',null].includes(`${msgErrorMakeRequest}`)" >
            <p
              class="red--text mt-0 mb-2 font-weight-bold text-lg-h5"
            >
              {{ msgErrorMakeRequest }}
            </p>
          </div>

          <div v-if="!['','null',null].includes(`${msgErrorDisableExamRequest}`)" >
            <p
              class="red--text mt-3 mb-0 font-weight-bold text-lg-h5"
            >
              Unable to disable this examination.
            </p>
            <p
              class="red--text mt-2 mb-2 font-weight-bold text-lg-h5"
            >
              {{ msgErrorMakeRequest }}
            </p>
            <p
              class="red--text mt-0 mb-0 font-weight-bold text-lg-h5"
            >
              Possible causes: 
            </p>
            <p
              class="red--text ml-2 mt-0 mb-0 font-weight-bold text-lg-h5"
            >
              1. The section has no previously scheduled examination. If this is the case, no further actions are required.
            </p>
            <p
              class="red--text ml-2 mt-0 mb-0 font-weight-bold text-lg-h5"
            >
              2. The back-office system is currently unavailable; please try again later.
            </p>
          </div>
          

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- {{ formConfirmExam.typeFor }} -->
          <v-btn
            @click="submitCancelExam(formConfirmExam.typeFor, dataExam[0])"
            class="font-weight-bold mt-0 mr-2 mb-2"
            color="green"
            dense
            :dark="!loadingCancelExam"
            :disabled="loadingCancelExam"
          >
            <v-progress-circular
              v-if="loadingCancelExam"
              :size="20"
              color="white"
              indeterminate
              class="mr-2"
            ></v-progress-circular>
            Submit
          </v-btn>
          <v-btn
            @click="closeDialogConfirm"
            color="dark"
            dark
            class="mt-0 mb-2 font-weight-bold"
            dense
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const objConfirm = {
  typeFor: "",
  val: "",
  sectionId: "",
};
const objMakeRequest = {
  choice_11: "",
  choice_1: [],
  choice_2: [],
  totalPrt: 1,
  remarkForPrt: "",
  remarkForStd: "",
  sectionId: "",
  typeRequest: "",
  dateExam: "",
  examHour_start: "",
  examHour_end: "",
  examMinute_start: "",
  examMinute_end: "",
};
export default {
  props: {
    dataExam: {
      type: Array,
      default: () => [],
    },
    serverTime: {
      type: String,
      default: () => "",
    },
    arrCurrentTime: {
      type: Object,
      default: () => {},
    },
    selYear: {
      type: Number,
      default: () => "",
    },
    switchShowTerm: {
      type: Number,
      default: () => "",
    },
  },
  computed: {
    computedDateFormattedMomentjsExam() {
      return this.formMakeRequest.dateExam
        ? this.moment(this.formMakeRequest.dateExam).format("DD/MM/YYYY")
        : "";
    },
  },
  data() {
    return {
      loadingRequestExam: false,
      loadingCancelExam: false,
      formConfirmExam: Object.assign({}, objConfirm),
      formMakeRequest: Object.assign({}, objMakeRequest),
      dialogExam: false,
      dialogMakeRequest: false,
      titleDialog: "",
      optionAA: [
        { value: "Lecturer Present", text: "Lecturer Present" },
        {
          value: "Lecturer Not Present",
          text: "Absent or present at another section",
        },
      ],
      optionA: [
        { value: "Proctors", text: "Proctors" },
        { value: "Exam Booklet", text: "Exam Booklet" },
      ],
      optionB: [
        { value: "Exam Open Book", text: "Exam Open Book" },
        { value: "Exam Calculator", text: "Calculator" },
        { value: "Exam Appendix", text: "Appendix" },
      ],
      blankData: [
        // { label: "Exam", value: null },
        { label: "Date Time", value: null },
        { label: "Exam Room", value: null },
        { label: "Proctor #", value: null },
        { label: "Requests", value: null },
        { label: "Remarks", value: null },
      ],
      arrHour: [
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      arrMinuteStart: ["00", "30"],
      arrMinuteEnd: ["00", "20", "30", "50"],
      menuExam: false,
      examTimeStart: false,
      examTimeEnd: false,
      // check create
      midtermCheck: false,
      finalCheck: false,
      // error case msg exam
      objMsgCase: [],
      msgErrorMakeRequest: "",
      msgErrorDisableExamRequest: "",
      // info
      infodateFinal: "",
    };
  },
  methods: {
    checkAfterBtn(curY, curT, selY, selT) {
      if (parseInt(selY) > parseInt(curY)) {
        return true;
      } else if (parseInt(selY) == parseInt(curY)) {
        if (parseInt(selT) >= parseInt(curT)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    openDialogConfirm(typeSend, val, sectionId) {
      this.dialogExam = true;
      this.formConfirmExam.typeFor = typeSend;
      this.formConfirmExam.val = val;
      this.formConfirmExam.sectionId = sectionId;
    },
    closeDialogConfirm() {
      this.dialogExam = false;
      this.loadingCancelExam = false;
      this.formConfirmExam = Object.assign({}, objConfirm);
    },
    midtermData() {
      let itemMidterm = [];
      this.midtermCheck = this.dataExam[0].isDisabledMidterm ? true : false;
      // console.log()
      for (let g = 0; g < this.dataExam[0].examinationReservation.length; g++) {
        if (
          this.dataExam[0].examinationReservation[g].examinationType ===
          "Midterm"
        ) {
          this.midtermCheck = this.dataExam[0].isDisabledMidterm
            ? true
            : this.dataExam[0].examinationReservation[g].statusText ===
                "Reject" ||
              this.dataExam[0].examinationReservation[g].statusText ===
                "Approved"
            ? true
            : false;
          itemMidterm.push(this.dataExam[0].examinationReservation[g]);
        }
      }
      return [
        // {
        //   label: "Exam",
        //   value:
        //     this.dataExam[0].midtermDate !== null &&
        //     this.dataExam[0].midtermDate !== ""
        //       ? this.moment(this.dataExam[0].midtermDate).format("DD/MM/YYYY")
        //       : "N/A",
        // },
        {
          label: "Date Time",
          value:
            itemMidterm.length != 0
              ? `${this.moment(itemMidterm[0].date).format(
                  "DD/MM/YYYY"
                )} Time : ${itemMidterm[0].startTime.split(":")[0]}:${
                  itemMidterm[0].startTime.split(":")[1]
                } - ${itemMidterm[0].endTime.split(":")[0]}:${
                  itemMidterm[0].endTime.split(":")[1]
                }`
              : "N/A",
        },
        {
          label: "Status",
          value: itemMidterm.length != 0 ? itemMidterm[0].statusText : "N/A",
        },
        {
          label: "Exam Room",
          value:
            itemMidterm.length != 0
              ? itemMidterm[0].room !== null
                ? itemMidterm[0].room
                : "N/A"
              : "N/A",
        },
        {
          label: "Proctor #",
          value: itemMidterm.length != 0 ? itemMidterm[0].totalProctor : "N/A",
        },
        {
          label: "Requests",
          value: itemMidterm.length != 0 ? itemMidterm[0] : "N/A",
        },
        {
          label: "Student Remarks",
          value: itemMidterm.length != 0 ? itemMidterm[0].studentRemark : "N/A",
        },
        {
          label: "Proctor Remarks",
          value: itemMidterm.length != 0 ? itemMidterm[0].proctorRemark : "N/A",
        },
      ];
    },
    finalData() {
      let itemFinal = [];
      this.finalCheck = this.dataExam[0].isDisabledFinal ? true : false;
      for (let f = 0; f < this.dataExam[0].examinationReservation.length; f++) {
        if (
          this.dataExam[0].examinationReservation[f].examinationType === "Final"
        ) {
          this.finalCheck = this.dataExam[0].isDisabledFinal
            ? true
            : this.dataExam[0].examinationReservation[f].statusText ===
                "Reject" ||
              this.dataExam[0].examinationReservation[f].statusText ===
                "Approved"
            ? true
            : false;
          // this.dataExam[0].examinationReservation[f].statusText === "Reject"
          //   ? true
          //   : false;
          itemFinal.push(this.dataExam[0].examinationReservation[f]);
        }
      }
      return [
        // {
        //   label: "Exam",
        //   text: this.dataExam[0].finalDate,
        //   value:
        //     this.dataExam[0].finalDate !== null &&
        //     this.dataExam[0].finalDate !== ""
        //       ? this.moment(this.dataExam[0].finalDate).format("DD/MM/YYYY")
        //       : "N/A",
        // },
        {
          label: "Date Time",
          text: this.dataExam[0].finalDate,
          timeSA:
            this.dataExam[0].finalDate !== null &&
            this.dataExam[0].finalDate !== ""
              ? this.dataExam[0].finalStart.split(":")[0]
              : "00",
          timeSB:
            this.dataExam[0].finalDate !== null &&
            this.dataExam[0].finalDate !== ""
              ? this.dataExam[0].finalStart.split(":")[1]
              : "00",
          timeEA:
            this.dataExam[0].finalDate !== null &&
            this.dataExam[0].finalDate !== ""
              ? this.dataExam[0].finalEnd.split(":")[0]
              : "00",
          timeEB:
            this.dataExam[0].finalDate !== null &&
            this.dataExam[0].finalDate !== ""
              ? this.dataExam[0].finalEnd.split(":")[1]
              : "00",
          value:
            this.dataExam[0].finalDate !== null &&
            this.dataExam[0].finalDate !== ""
              ? `${this.moment(this.dataExam[0].finalDate).format(
                  "DD/MM/YYYY"
                )} Time : ${this.dataExam[0].finalStart.split(":")[0]}:${
                  this.dataExam[0].finalStart.split(":")[1]
                } - ${this.dataExam[0].finalEnd.split(":")[0]}:${
                  this.dataExam[0].finalEnd.split(":")[1]
                }`
              : "N/A",
        },
        {
          label: "Status",
          value: itemFinal.length != 0 ? itemFinal[0].statusText : "N/A",
        },
        {
          label: "Exam Room",
          value:
            itemFinal.length != 0
              ? itemFinal[0].room !== null
                ? itemFinal[0].room
                : "N/A"
              : "N/A",
        },
        {
          label: "Proctor #",
          value: itemFinal.length != 0 ? itemFinal[0].totalProctor : "N/A",
        },
        {
          label: "Requests",
          value: itemFinal.length != 0 ? itemFinal[0] : "N/A",
        },
        {
          label: "Student Remarks",
          value: itemFinal.length != 0 ? itemFinal[0].studentRemark : "N/A",
        },
        {
          label: "Proctor Remarks",
          value: itemFinal.length != 0 ? itemFinal[0].proctorRemark : "N/A",
        },
      ];
    },
    openDialogMakeRequest(val, secId, type) {
      if (type === "f") {
        for (let ff = 0; ff < this.finalData().length; ff++) {
          const getData = this.finalData()[ff];
          if (getData.label === "Date Time") {
            this.infodateFinal = getData.text;
            this.formMakeRequest.dateExam = new Date(
              new Date(getData.text) - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10);
            // set - time
            this.formMakeRequest.examHour_start = getData.timeSA;
            this.formMakeRequest.examMinute_start = getData.timeSB;
            this.formMakeRequest.examHour_end = getData.timeEA;
            this.formMakeRequest.examMinute_end = getData.timeEB;
          }
        }
      }
      this.objMsgCase = [];
      this.titleDialog = val;
      this.formMakeRequest.sectionId = secId;
      this.formMakeRequest.typeRequest = type;
      this.dialogMakeRequest = true;
    },
    closeDialogMakeRequest() {
      this.dialogMakeRequest = false;
      this.loadingRequestExam = false;
      this.titleDialog = "";
      this.infodateFinal = "";
      this.formMakeRequest = Object.assign({}, objMakeRequest);
    },
    async submitMakeRequest() {
      this.objMsgCase = [];
      this.loadingRequestExam = true;
      const sendFormMakeRequest = new FormData();
      sendFormMakeRequest.append("Date", this.formMakeRequest.dateExam);
      sendFormMakeRequest.append(
        "StartTime",
        `${this.formMakeRequest.examHour_start}:${this.formMakeRequest.examMinute_start}:00`
      );
      sendFormMakeRequest.append(
        "EndTime",
        `${this.formMakeRequest.examHour_end}:${this.formMakeRequest.examMinute_end}:00`
      );
      sendFormMakeRequest.append(
        "TotalProctor",
        this.formMakeRequest.choice_1.includes("Proctors")
          ? this.formMakeRequest.totalPrt
          : 0
      );
      sendFormMakeRequest.append(
        "AllowBooklet",
        this.formMakeRequest.choice_1.includes("Exam Booklet")
      );
      sendFormMakeRequest.append(
        "AbsentInstructor",
        this.formMakeRequest.choice_11 !== "Lecturer Present"
      );
      sendFormMakeRequest.append(
        "AllowOpenbook",
        this.formMakeRequest.choice_2.includes("Exam Open Book")
      );
      sendFormMakeRequest.append(
        "AllowCalculator",
        this.formMakeRequest.choice_2.includes("Exam Calculator")
      );
      sendFormMakeRequest.append(
        "AllowAppendix",
        this.formMakeRequest.choice_2.includes("Exam Appendix")
      );
      sendFormMakeRequest.append(
        "StudentRemark",
        this.formMakeRequest.remarkForStd
      );
      sendFormMakeRequest.append(
        "proctorRemark",
        this.formMakeRequest.remarkForPrt
      );
      sendFormMakeRequest.append("SectionId", this.formMakeRequest.sectionId);
      sendFormMakeRequest.append("ExamType", this.formMakeRequest.typeRequest);

      // for (var pair of sendFormMakeRequest.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      try {
        const postMakeRequest = await this.$http.post(
          `/keystone/section/request_exam/`,
          sendFormMakeRequest,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (postMakeRequest.status === 200) {
          // console.log(postMakeRequest.data.data);
          if (postMakeRequest.data.code === "200") {
            this.$emit("updateMakeRequest", postMakeRequest.data.data);
            this.closeDialogMakeRequest();
          } else {
            this.objMsgCase.push(postMakeRequest.data);
          }
          this.loadingRequestExam = false;
        } else {
          throw { postMakeRequest };
        }
      } catch (error) {
        this.loadingRequestExam = false;
        this.msgErrorMakeRequest = `Error caught : /keystone/section/request_exam/`;
      }
    },
    checkBtnDisableExam(dataE, tExam) {
      for (let c = 0; c < dataE.length; c++) {
        const ldataE = dataE[c];
        if (ldataE.examinationType === tExam) {
          return ["Reject", "Approved"].includes(ldataE.statusText);
          // return (
          //   ldataE.statusText === "N/A" ||
          //   ldataE.statusText === "Reject" ||
          //   ldataE.statusText === "Approved"
          // );
        } else {
          return true;
        }
      }
    },
    filterTypeE(dataArr, ty, dataType) {
      for (let s = 0; s < dataArr.length; s++) {
        const listExam = dataArr[s];
        // console.log(listExam);
        // console.log(listExam)
        if (listExam.examinationType === ty) {
          switch (dataType) {
            case "StudentRemark":
              return listExam.studentRemark !== "undefined"
                ? listExam.studentRemark
                : "";
            case "AbsentInstructor":
              return listExam.absentInstructor !== "undefined"
                ? listExam.absentInstructor
                : "";
            case "AllowBooklet":
              return listExam.allowBooklet !== "undefined"
                ? listExam.allowBooklet
                : "";
            case "AllowCalculator":
              return listExam.allowCalculator !== "undefined"
                ? listExam.allowCalculator
                : "";
            case "AllowOpenbook":
              return listExam.allowOpenbook !== "undefined"
                ? listExam.allowOpenbook
                : "";
            case "AllowAppendix":
              return listExam.allowAppendix !== "undefined"
                ? listExam.allowAppendix
                : "";
            case "TotalProctor":
              return listExam.totalProctor !== "undefined"
                ? listExam.totalProctor
                : "";
            case "proctorRemark":
              return listExam.proctorRemark !== "undefined"
                ? listExam.proctorRemark
                : "";
            case "date":
              return listExam.date !== null && listExam.date !== "undefined"
                ? this.moment(listExam.date).format("YYYY-MM-DD")
                : "";
            case "startTime":
              return listExam.startTime !== "undefined"
                ? listExam.startTime
                : "";
            case "endTime":
              return listExam.endTime !== "undefined" ? listExam.endTime : "";
            default:
              break;
          }
        } else {
          switch (dataType) {
            case "StudentRemark":
              return false;
            case "AbsentInstructor":
              return false;
            case "AllowBooklet":
              return false;
            case "AllowCalculator":
              return false;
            case "AllowOpenbook":
              return false;
            case "AllowAppendix":
              return false;
            case "TotalProctor":
              return 0;
            case "proctorRemark":
              return "";
            case "date":
              return this.moment().format("YYYY-MM-DD");
            case "startTime":
              return this.moment().format("YYYY-MM-DD");
            case "endTime":
              return this.moment().format("YYYY-MM-DD");
            default:
              break;
          }
        }
      }
    },
    async submitCancelExam(type, objCancel) {
      // console.log(type);
      // console.log(objCancel);
      this.msgErrorDisableExamRequest = "";
      this.loadingCancelExam = true;
      const sendCancelExam = new FormData();
      sendCancelExam.append("SectionId", objCancel.sectionId);
      sendCancelExam.append("ExamType", type === "Final" ? "f" : "m");
      sendCancelExam.append(
        "StartTime",
        // type === "Final" ? objCancel.finalStart : objCancel.midtermStart
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(
              objCancel.examinationReservation,
              type,
              "startTime"
            )
          : ""
      );
      sendCancelExam.append(
        "EndTime",
        // type === "Final" ? objCancel.finalEnd : objCancel.midtermEnd
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(objCancel.examinationReservation, type, "endTime")
          : ""
      );
      sendCancelExam.append(
        "Date",
        // type === "Final"
        //   ? objCancel.finalDate !== null
        //     ? this.moment(objCancel.finalDate).format("YYYY-MM-DD")
        //     : ""
        //   : objCancel.midtermDate !== null
        //   ? this.moment(objCancel.midtermDate).format("YYYY-MM-DD")
        //   : ""
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(objCancel.examinationReservation, type, "date")
          : ""
      );
      sendCancelExam.append(
        "StudentRemark",
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(
              objCancel.examinationReservation,
              type,
              "StudentRemark"
            )
          : ""
      );
      sendCancelExam.append(
        "AbsentInstructor",
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(
              objCancel.examinationReservation,
              type,
              "AbsentInstructor"
            )
          : ""
      );
      sendCancelExam.append(
        "AllowBooklet",
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(
              objCancel.examinationReservation,
              type,
              "AllowBooklet"
            )
          : ""
      );
      sendCancelExam.append(
        "AllowCalculator",
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(
              objCancel.examinationReservation,
              type,
              "AllowCalculator"
            )
          : ""
      );
      sendCancelExam.append(
        "AllowOpenbook",
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(
              objCancel.examinationReservation,
              type,
              "AllowOpenbook"
            )
          : ""
      );
      sendCancelExam.append(
        "AllowAppendix",
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(
              objCancel.examinationReservation,
              type,
              "AllowAppendix"
            )
          : ""
      );
      sendCancelExam.append(
        "TotalProctor",
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(
              objCancel.examinationReservation,
              type,
              "TotalProctor"
            )
          : ""
      );
      sendCancelExam.append(
        "proctorRemark",
        objCancel.examinationReservation.length != 0
          ? this.filterTypeE(
              objCancel.examinationReservation,
              type,
              "proctorRemark"
            )
          : ""
      );

      // for (var pair of sendCancelExam.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      try {
        const postCancelExam = await this.$http.post(
          `/keystone/section/cancel_exam/`,
          sendCancelExam,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (postCancelExam.status === 200) {
          // console.log(postCancelExam.data)
          if (postCancelExam.data.code === "200") {
            this.$emit("updateMakeRequest", postCancelExam.data.data);
          } else {
            this.msgErrorDisableExamRequest = `Error caught : /keystone/section/cancel_exam/ : ${postCancelExam.data.message}`;
          }
          this.loadingCancelExam = false;
          this.closeDialogConfirm();
        } else {
          throw { postCancelExam };
        }
      } catch (error) {
        this.loadingCancelExam = false;
        this.msgErrorDisableExamRequest = `Error caught : /keystone/section/cancel_exam/`;
      }
    },
  },
};
</script>

<style scoped>
.boder-left {
  border-left: 1px solid #0000001f;
}
.boder-right {
  border-right: 1px solid #0000001f;
}
.boder-top {
  border-top: 1px solid #0000001f;
}
.boder-bottom {
  border-bottom: 1px solid #0000001f;
}
</style>