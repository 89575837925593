<template>
  <div>
    <!-- <v-list-item dense three-line>
      <v-list-item-avatar>
        <v-icon color="blue-grey lighten-4" size="45"> mdi-account-circle </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <div
            class="
              blue-grey--text
              text--lighten-2
              d-flex
              justify-lg-space-between
            "
          >
            <span>Kitsana Chunchom</span>
            <small class="text--disabled">{{ moment().fromNow() }}</small>
          </div></v-list-item-title
        >
        <v-list-item-subtitle class="black--text">
          Something Announcement ...
        </v-list-item-subtitle>
        <v-list-item-subtitle> </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item> -->
  </div>
</template>

<script>
export default {
  props: {
    loadingComment: {
      type: Boolean,
      default: false,
    },
    listComment: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>