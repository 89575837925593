import Vue from "vue";
import VueRouter from "vue-router";
import vueXstore from '@/store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
});

const whoami = async () => {
  try {
    const res = await Vue.axios.get('/whoami/')
    if (res.status === 200) {
      return res.data;
    }
    return false;
  }
  catch (error) {
    return false;
  }
}

function getRole(RoleVal) {
  // console.log(RoleVal)
  let checkRole = 0;
  for (let c = 0; c < RoleVal.length; c++) {
    const element = RoleVal[c];
    let valSp = element.permission.split(',');
    // console.log(valSp)
    valSp.forEach(element => {
      if (['pc', 'pd', 'chair'].includes(element)) {
        checkRole++;
      }
    });
  }
  return checkRole > 0 ? true : false
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const res = await whoami()
    if (router.app.$keycloak.authenticated) {
      // console.log(res.roles)
      vueXstore.dispatch('setRoleUser',
        {
          username: res.username,
          role: res.roles,
          permissions: res.permissions,
          server_status: res.server_status,
          RolePdPCChair: getRole(res.roles),
        }
      )
      next();
    } else {
      const loginUrl = router.app.$keycloak.createLoginUrl();
      window.location.replace(loginUrl);
    }
  } else {
    next();
  }
});

export default router;

// export default new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });
